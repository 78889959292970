//importando o client pra ser utilizado
import { clientRestAPI } from "../clientRestAPI/clientRestAPI";
import { toast } from "react-toastify";
//definindo a rota a ser acrescentada na rota base definida no client
const url = "/banco";

const bancoModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaBanco: [],
    listaBancoOptionValue: [],
    banco: {},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //atualiza a lista de bancos  da aplicação caso os bancos tenham sido obtidos da API com sucesso
    fetchListaBancoFulfiled: (state, payload) => {
      return {
        ...state,
        listaBanco: payload.data || payload,
        redirect: false
      };
    },

    fetchListaBancoOptionValueFulfiled: (state, payload) => {
      return {
        ...state,
        listaBancoOptionValue: payload.data
          ? payload.data.map(function(item) {
              let label = item.codBanco + "-" + item.nomeReduzido;
              return { value: item, label: label };
            })
          : payload.map(function(item) {
              let label = item.codBanco + "-" + item.nomeReduzido;
              return { value: item, label: label };
            }),
        redirect: false
      };
    },

    fetchListaBancoOptionValueRejected: (state, payload) => {
      //const { nome, email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao buscar lista banco! " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    fetchListaBancoRejected: (state, payload) => {
      //const { nome, email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao buscar lista banco! " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os bancos
    fetchListaBanco(id) {
      //faz a chamada com o client para buscar todos os bancos

      return clientRestAPI
        .get(`${url}/all/`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.bancoModel.fetchListaBancoFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteModel.fetchListaBancoRejected(err);
        });
    },

    fetchListaBancoOptionValue() {
      //faz a chamada com o client para buscar todos os bancos
      return clientRestAPI
        .get(`${url}/all/`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.bancoModel.fetchListaBancoOptionValueFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteModel.fetchListaBancoOptionValueRejected(err);
        });
    }
  })
};

export default bancoModel;
