//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/consultoria";

const consultoriasModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaConsultorias: [],
    idConsultoriaSelecionada:'',
    consultorias: { idConsultoria :"",nome: "", planoInicial: "N" },
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },



    //atualiza a lista de consultoriass  da aplicação caso os consultoriass tenham sido obtidos da API com sucesso
    fetchListaConsultoriasFulfiled: (state, payload) => {
      return {
        ...state,
        listaConsultorias: payload.data || payload,
        redirect: false,
        loading: false
      };
    },


    //Deixa a aplicação pronta para receber um novo consultorias no formulário
    newConsultoria: state => {
      return {
        ...state,
        consultoria: { idConsultoria :"",nome: "" },
      };
    },

    //atualiza a lista de consultoriass  da aplicação caso o consultorias tenha sido salvo com sucesso
    saveConsultoriaFulfilled: (state, payload) => {
      const listaConsultoriasAtualizada = [...state.listaConsultorias, payload];
      toast.success("Consultoria criado com sucesso!");
      return {
        ...state,
        listaConsultorias: listaConsultoriasAtualizada,
        errors: {},
        loading: false,
        redirect: true
      };
    },

    listConsultoriaPending: state => {
      return {
        ...state,
        listaConsultorias: [],
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveConsultoriaPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    ativarInativarConsultoriaFulfiled: (state, id, ativo) => {
      let msg = ativo === 'S' ? "Ativada" : "Inativada";

      toast.success(`Consultoria ${msg} com sucesso!`);
      const listatemporaria = state.listaConsultorias.map((item) => {
        if(item.idConsultoria === id) {
          item.ativo = ativo;
        }
        return item;
      })

      return {
        ...state,
        listaConsultorias: listatemporaria,
        loading: false
      };
    },

    deleteConsultoriaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload.message };
      if (errors.global.indexOf('Constraint')>0){
        toast.error("Erro ao excluir consultoria. Devem ser excluidos todos os relacionamentos  " );
      }else{
        toast.error("Erro ao excluir consultoria. "+errors.global );
      }

      return {
        ...state,
        errors: errors,
        loading: false
      };
    },


    //mostra os erros encontrados no caso de a operação de salvar consultoria apresente algum problema
    saveConsultoriaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload.message };

      toast.error("Erro ao incluir consultoria " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    fetchConsultoriaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao consultar consultoria " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um consultoria tenha dado certo
    fetchConsultoriaFulfiled: (state, payload) => {
      return {
        ...state,
        consultoria: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateConsultoriaFulfiled: (state, payload) => {
      const consultoria = payload;
      toast.success("Consultoria atualizado com sucesso!");

      return {
        ...state,
        listaConsultorias: state.listaConsultorias.map(item =>
          item.id === consultoria.id ? consultoria : item
        ),
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar consultorias apresente algum problema
    updateConsultoriaRejected: (state, payload) => {
      //      const { nome  , email } = payload.errors;
      const errors = { global: payload.message };

      toast.error("Erro ao atualizar consultoria " + payload.message);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateConsultoriaPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchConsultoriaPending: state => {
      return {
        ...state,
        loading: true,
        consultoria: { nome: "" }
      };
    },

    //atualiza a lista de consultoria da aplicação caso o consultoria tenha sido deletado da API com sucesso
    deleteConsultoriaFulfiled: (state, payload,idF) => {
      toast.success("Consultoria deletada com sucesso!");
    const listatemporaria = state.listaConsultorias.filter(
      (item) => item.idConsultoria !== idF
    );
      return {
        ...state,
        listaConsultorias:listatemporaria,
        loading: false


      };
    }
  },

  //definindo os effects
  effects: dispatch => ({


    //aciona a ação de buscar os consultorias
    fetchListaConsultorias() {
        dispatch.consultoriasModel.listConsultoriaPending();

        const ambiente = isBackendProd();
        //faz a chamada com o client para buscar todos os consultoriass
        return clientRestAPI.get(`${url}?ambiente=${ambiente}`).then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.fetchListaConsultoriasFulfiled(res.data);
        });

    },



    //faz a chamada com o clientRestAPI para buscar um consultoria, por meio do id
    fetchConsultoria(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.consultoriasModel.fetchConsultoriaPending();
      return clientRestAPI
        .get(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.fetchConsultoriaFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.fetchConsultoriaRejected(
            err.response
          );
        });
    },

    //faz a chamada com o client para atualizar os dados de um consultoria
    updateConsultoria(consultoria) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.consultoriasModel.updateConsultoriaPending();

      consultoria.ambiente = isBackendProd();
      return clientRestAPI
        .put(`${url}/${consultoria.id}`, consultoria)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.updateConsultoriaFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.updateConsultoriaRejected(

            err.response.data
          );
        });
    },

    //faz a chamada com o client para salar os dados de um consultoria
    saveConsultoria(consultoria) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.consultoriasModel.saveConsultoriaPending();

      consultoria.ambiente = isBackendProd();
      return clientRestAPI
        .post(url, consultoria)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.saveConsultoriaFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.consultoriasModel.saveConsultoriaRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para apagar um consultoria
    deleteConsultoria(id) {
      dispatch.consultoriasModel.saveConsultoriaPending();
      return clientRestAPI.delete(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.consultoriasModel.deleteConsultoriaFulfiled(res.data,id);
      }).catch(err => {
        //caso ocorra algum problema, o reducer abaixo vai ser acionado
        dispatch.consultoriasModel.deleteConsultoriaRejected(
          err.response.data);
      });
    },

    ativarInativarConsultoria(dado) {

      let ativo = dado.ativo === 'S' ? 'N' : 'S';
      dispatch.consultoriasModel.saveConsultoriaPending();
      return clientRestAPI.put(`${url}/${dado.id}/ativo/${ativo}`).then(res => {
        dispatch.consultoriasModel.ativarInativarConsultoriaFulfiled(dado.id, ativo);
      }).catch(err => {
        dispatch.consultoriasModel.deleteConsultoriaRejected(err.response.data);
      });
    },
  })
};

export default consultoriasModel;
