import {clientRestAPI} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";
import {getContribuinteHomeAutenticado, getUsuarioAutenticado} from "../routes/auth";


const url = "/nfe";

function getTipoLancamentoAlteracao(nfes) {
  let tipoLancamentoAlteracao = {};

  nfes.forEach(function(nfe) {
    ConsoleLog("getTipoLancamentoAlteracao => ", nfe);
    let idTipoLancamento = nfe.idTipoLancamento;
    tipoLancamentoAlteracao[nfe.id] = {idTipoLancamento, alterado: false} ;
  });

  return tipoLancamentoAlteracao;
}

function getNonSelectableRows(nfes) {
  let notSelectableRows = [];

  ConsoleLog("selectable => getNonSelectableRows nfes", nfes);

  notSelectableRows = nfes
    .filter((n) => n.status === "E")
    .reduce((acc, r) => {
      acc.push(r.id);
      return acc;
    }, []);


  ConsoleLog("selectable => getNonSelectableRows => ", notSelectableRows);
  return notSelectableRows;
}

function tratarValues(values) {

  ConsoleLog("nfeModel => tratarValues => values", values);

  const consolidar = {};

  if (values.tipoPagamento && values.tipoPagamento.value) {
    consolidar.idTipoPagamento = values.tipoPagamento.value;
  }

  const contaBanc = values.contaBancariaLabelValue;
  if (contaBanc && contaBanc.value && contaBanc.value.id) {
    consolidar.idContaBancaria = contaBanc.value.id;
  }

  let isTipoLancamento = false;
  consolidar.idTipoLancamento = '';
  if (values.tipoLancamento && values.tipoLancamento.value && values.tipoLancamento.value !== "") {
    consolidar.idTipoLancamento = values.tipoLancamento.value;
    isTipoLancamento = true;
  }

  if (values.conta && values.conta.id) {
    consolidar.idConta = values.conta.id;
  }

  if (values.codigoConta && values.codigoConta !== "") {
    consolidar.codigoConta = values.codigoConta;
  }

  let ids = values.ids ? values.ids : [];
  if (!Array.isArray(ids)) {
    ids = [ids];
  }
  consolidar.ids = [...ids];

  if (values.action && values.action !== "") {
    consolidar.action = values.action;
  }

  consolidar.datasLancamentos = [];
  if(values.datasLancamentos) {
    Object.keys(values.datasLancamentos).forEach(function(key) {
      const datasLancamentos = {id: key, dataLancamento: `${values.datasLancamentos[key]}T10:00:00Z` };
      consolidar.datasLancamentos.push(datasLancamentos);
    });
  }

  

  consolidar.historicos = [];
  if(values.historicoAlteracao) {
    Object.keys(values.historicoAlteracao).forEach(function(key) {
      const historicoAlteracao = {id: key, historico: values.historicoAlteracao[key]};
      consolidar.historicos.push(historicoAlteracao);
    });
  }


  consolidar.tiposLancamentos = [];
  if(!isTipoLancamento) {
    if(values.tipoLancamentoAlteracao) {
      Object.keys(values.tipoLancamentoAlteracao).forEach(function(key) {
        const tipoLancamentoAlteracao = {id: key, idTipoLancamento: values.tipoLancamentoAlteracao[key].idTipoLancamento};
        consolidar.tiposLancamentos.push(tipoLancamentoAlteracao);
      });
    }
  }

  return consolidar;
}

const nfeModel = {

  state: {
    loading: false,
    errors: {},
    redirect: false,
    selectedFiles: undefined,
    currentFile: undefined,
    progress: 0,
    messages: [],
    messageAlert: "alert alert-light",
    nfes: [],
    notSelectableRows: [],
    notSelectableRowsFixed: [],

    /* Utilizado nos campos par consolidar o upload */
    tipoPagamento: "",
    contaBancariaLabelValue: "",
    tipoLancamento: "",
    conta: "",
    codigoConta: "",
    ids: [],
    datasLancamentos: {},
    qtdData: 0,
    historicoAlteracao: {},
    tipoLancamentoAlteracao: {},
  },

  reducers: {

    setTipoLancamentoAlteracao: (state, id, idTipoLancamento) => {
      
      ConsoleLog("Chamou setTipoLancamentoAlteracao", id, idTipoLancamento);

      let tipoLancamentoAlteracao = state.tipoLancamentoAlteracao;
      if(tipoLancamentoAlteracao) {
        tipoLancamentoAlteracao[id] = {idTipoLancamento, alterado: true};
      }

      ConsoleLog("Chamou setTipoLancamentoAlteracao tipoLancamentoAlteracao", tipoLancamentoAlteracao);

      return {
        ...state,
        tipoLancamentoAlteracao,
      }

    },

    setHistoricoAlteracao: (state, id, historico) => {

      ConsoleLog("Chamou setHistoricoAlteracao", id, historico);

      let historicoAlteracao = state.historicoAlteracao;
      if(historicoAlteracao) {
        historicoAlteracao[id] = historico;
      }

      ConsoleLog("Chamou setHistoricoAlteracao historicoAlteracao", historicoAlteracao);

      return {
        ...state,
        historicoAlteracao,
      }

    },

    setDataLancamento: (state, id, dataLancamento) => {

      ConsoleLog("Chamou setDataLancamento", id, dataLancamento);

      let datasLancamentos = state.datasLancamentos;
      if(dataLancamento) {
        datasLancamentos[id] = dataLancamento;
      }

      ConsoleLog("Chamou setDataLancamento datasLancamentos", datasLancamentos);

      return {
        ...state,
        qtdData: Object.keys(datasLancamentos).length,
        datasLancamentos,
      }

    },

    selectRow: (state, ids) => {
      ConsoleLog("nfeModel => selectRow => ids", ids);

      return {
        ...state,
        ids: [...ids],
      }
    },

    fetchSelectFile: (state, selectedFiles) => {
      ConsoleLog("nfeModel => upload => fetchSelectFile => selectedFiles", selectedFiles);

      return {
        ...state,
        selectedFiles: {...selectedFiles},
        currentFile: undefined,
        messages: [],
        messageAlert: "alert alert-light",
      }

    },

    pending: (state) => {

      ConsoleLog("nfeModel => pending");

      return {
        ...state,
        loading: true,
      };
    },

    pendingFalse: (state) => {

      ConsoleLog("nfeModel => pendingFalse");

      return {
        ...state,
        loading: false,
      };
    },

    fetchClear: (state) => {
      return {
        ...state,
        loading: false,
        errors: {},
        redirect: false,
        selectedFiles: undefined,
        currentFile: undefined,
        progress: 0,
        messages: [],
        messageAlert: "alert alert-light",
        nfes: [],
        notSelectableRows: [],
        notSelectableRowsFixed: [],
      };
    },

    fetchInicia: (state, currentFile) => {
      return {
        ...state,
        loading: true,
        progress: 0,
        currentFile: currentFile,
        nfes: [],
        notSelectableRows: [],
        notSelectableRowsFixed: [],
      };
    },

    fetchSelectedErro: (state, messages) => {

      ConsoleLog("fetchSelectedErro => messages", messages);

      // const msgErro = [];
      // message.forEach(erro => {
      //   msgErro.push(erro);
      // });

      return {
        ...state,
        loading: false,
        messageAlert: "alert alert-danger",
        messages: [...messages],
        selectedFiles: undefined,
      };
    },


    fetchUploadErro: (state, error, nomeArquivo) => {

      let msgErro = "";
      const data = (error && error.response && error.response.data) ? error.response.data : undefined;
      if (data && data.message && !!data.message.trim()) {
        msgErro = `[${data.message}]`;
      }

      return {
        ...state,
        loading: false,
        progress: 0,
        messageAlert: "alert alert-danger",
        messages: [`${msgErro} Não foi possível fazer upload do arquivo "${nomeArquivo}".`],
        currentFile: undefined,
        selectedFiles: undefined,
      };
    },


    fetchProgress: (state, progress) => {
      return {
        ...state,
        progress
      };
    },

    fetchUploadSucesso: (state, nomeArquivo) => {
      return {
        ...state,
        messageAlert: "alert alert-info",
        messages: [`Arquivo(s) importado(s) com sucesso! Por favor conferir as informações abaixo.`],
        progress: 100,
        selectedFiles: undefined,

      };
    },

    fetchListaUploadVazio: (state, payload) => {

      if (payload) {
        const errors = {global: payload};
        toast.error(errors.global);
      }

      return {
        ...state,
        loading: false,
        nfes: [],
        notSelectableRows: [],
        notSelectableRowsFixed: [],
        selectedFiles: undefined,
        currentFile: undefined,
        progress: 0,
        messages: [],
        messageAlert: "alert alert-light",
        datasLancamentos: {},
        qtdData: 0,
        historicoAlteracao: {},
        tipoLancamentoAlteracao: {},
        
      }
    },

    fetchListaUploadSucesso: (state, payload) => {

      const notSelectableRows = getNonSelectableRows(payload.data);
      const tipoLancamentoAlteracao = getTipoLancamentoAlteracao(payload.data);
      //const comboTipoLancamento = getComboTipoLancamento(payload.data);

      if (payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          nfes: payload.data,
          ids: [],
          notSelectableRows: [...notSelectableRows],
          notSelectableRowsFixed: [...notSelectableRows],
          //comboTipoLancamento: comboTipoLancamento,
          selectedFiles: undefined,
          datasLancamentos: {},
          qtdData: 0,
          historicoAlteracao: {},
          tipoLancamentoAlteracao: tipoLancamentoAlteracao,
        }
      } else {
        return {
          ...state,
          loading: false,
          nfes: payload.data,
          ids: [],
          notSelectableRows: notSelectableRows,
          notSelectableRowsFixed: [...notSelectableRows],
          //comboTipoLancamento: comboTipoLancamento,
          selectedFiles: undefined,
          currentFile: undefined,
          progress: 0,
          messages: [],
          messageAlert: "alert alert-light",
          datasLancamentos: {},
          qtdData: 0,
          historicoAlteracao: {},
          tipoLancamentoAlteracao: tipoLancamentoAlteracao,
        }
      }


    },

    deleteNFesFulfiled: (state) => {
      toast.success("NFe deletado com sucesso!");
      return {
        ...state,
        loading: false,
        ids: [],
      };
    },

    deleteErroFulfiled: (state) => {
      toast.success("NFe com erro deletado com sucesso!");
      return {
        ...state,
        loading: false,
        ids: [],
      };
    },

    consolidarRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

    consolidadoFulfiled: (state) => {
      toast.success("NFe consolidado com sucesso!");
      return {
        ...state,
        loading: false,
      };
    },

  },


  effects: dispatch => ({

    fetchUpload(files) {

      ConsoleLog("nfeModel upload => fetchUpload => ", files);

      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      const usuarioLogado = getUsuarioAutenticado();
      const formData = new FormData();

      Object.entries(files).forEach(([key, value]) => {
        //ConsoleLog(key + ' ' + value); // "a 5", "b 7", "c 9"
        formData.append('files', value);
      });

      // formData.append("files", [Object.values(files)]);
      formData.append("idUsuario", usuarioLogado.id);
      formData.append("idContribuinte", contribuinteSelecionado.id);

      dispatch.nfeModel.fetchInicia(files);

      return clientRestAPI.post(`${url}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (event) {
          ConsoleLog("nfeModel => onUploadProgress => ", event);
          let progress = Math.round((99 * event.loaded) / event.total);
          dispatch.nfeModel.fetchProgress(progress);
        },
      })
        .then((response) => {
          ConsoleLog("nfeModel => response", response);
          //dispatch.nfeModel.fetchUploadSucesso(currentFile.name);
          dispatch.nfeModel.fetchUploadSucesso("");
          dispatch.nfeModel.fetchListaUpload();
        })
        .catch((error) => {
          ConsoleLog("nfeModel => fetchUpload => error", error);
          ConsoleLog("nfeModel => fetchUpload => error.response", error.response);
          //dispatch.nfeModel.fetchUploadErro(error, currentFile.name);
          dispatch.nfeModel.fetchUploadErro(error, "");
        });
    },

    fetchListaUpload() {

      dispatch.nfeModel.pending();

      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      if (contribuinteSelecionado) {

        clientRestAPI.get(`${url}/listaUpload?&idContribuinte=${contribuinteSelecionado.id}`)
          .then((files) => {
            ConsoleLog("nfeModel => fetchListaUpload", files);
            dispatch.nfeModel.fetchListaUploadSucesso(files);
          })
          .catch((error) => {
            ConsoleLog("nfeModel => fetchUpload => error", error);
            ConsoleLog("nfeModel => fetchUpload => error.response", error.response);
            dispatch.nfeModel.fetchListaUploadVazio(error);
          });
      } else {
        dispatch.nfeModel.fetchListaUploadVazio();
      }
    },

    // deleteNFes(idsNFes) {
    consolidarUpload(values) {

      ConsoleLog("nfeModel => consolidarUpload => values", values);

      dispatch.nfeModel.pending();

      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      const usuarioLogado = getUsuarioAutenticado();

      const importacaoNFe = tratarValues(values);
      importacaoNFe.idContribuinte = contribuinteSelecionado.id;
      importacaoNFe.idUsuario = usuarioLogado.id;

      ConsoleLog("nfeModel => consolidarUpload", importacaoNFe);

      return clientRestAPI
        .put(`${url}/consolidarNFes`, importacaoNFe)
        .then(_ => {
          if (importacaoNFe.action === 'apagarSelecionados') {
            dispatch.nfeModel.deleteNFesFulfiled();
          } else if (importacaoNFe.action === 'apagarErro') {
            dispatch.nfeModel.deleteErroFulfiled();
          } else {
            dispatch.nfeModel.consolidadoFulfiled();
          }

          dispatch.nfeModel.fetchListaUpload();
        })
        .catch((err) => {
          ConsoleLog("nfeModel => deleteNFes => err", err);
          ConsoleLog("nfeModel => deleteNFes => err.response", err.response);

          if (err.response && err.response.status === 400) {
            if (importacaoNFe.action === 'consolidar') {
              dispatch.nfeModel.consolidarRejected("Não foi possível consolidar o NFe.");
            } else {
              dispatch.nfeModel.consolidarRejected("Não foi possível realizar a exclusão do NFe.");
            }
          } else {
            dispatch.nfeModel.consolidarRejected(err.response.data);
          }
        });
    },
  }),

};

export default nfeModel;
