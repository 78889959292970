import React, { Component } from "react";
import {connect} from "react-redux";
import {Auth} from "aws-amplify";
import logo from "../../assets/img/brand/01_LOGO.png";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import ReCAPTCHA from "react-google-recaptcha"
import ConsoleLog from "./ConsoleLog";


import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

class Register extends Component {
  state = {
    signedUp: false,
    confirmed: false,
    nome: "",
    password: "",
    email: "",
    phoneNumber: "",
    phoneNumberFixo: "",
    confirmationCode: "",
    submittingSignUp: false,
    submittingConfirmation: false,
    mensagemErro: "",
    errosForm: [],
    captcha: false
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitSignUp = this.handleSubmitSignUp.bind(this);
    this.handleSubmitConfirmationSignUp =
      this.handleSubmitConfirmationSignUp.bind(this);
  }

  handleChange(e) {
    if (e.target) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
      this.setState({
        captcha: true
      })
    }
  }

  handleSubmitSignUp(e) {
    this.setState({
      errosForm: "",
      mensagemErro: "",
    });

    e.preventDefault(e);

    
    const isCaptchaEnabled = true
    if (isCaptchaEnabled && !this.state.captcha) {
      this.setState({ mensagemErro: "Favor verificar se não é um robô!" });
      return;
    }

    let {
      confirmed,
      signedUp,
      nome,
      password,
      email,
      phoneNumber,
      phoneNumberFixo,
      passwordConfirmation,
    } = this.state;
    phoneNumber = phoneNumber.replace(/[^\d]/g, "");
    phoneNumber = "+55" + phoneNumber;
    phoneNumberFixo = phoneNumberFixo.replace(/[^\d]/g, "");
    phoneNumberFixo = "+55" + phoneNumberFixo;
    if (phoneNumberFixo.length !== 13 && phoneNumberFixo.length > 4) {
      this.setState({ mensagemErro: "Numero de telefone Fixo invalido" });
      return;
    } else if (phoneNumberFixo === "+55") {
      phoneNumberFixo = ""
    }

    if (phoneNumber.length !== 14 && phoneNumber.length > 4) {
      this.setState({ mensagemErro: "Numero de telefone invalido" });
      return;
    }

    if (!confirmed && !signedUp) {
      this.setState({ submittingSignUp: true });

      const dadosRegistro = {
        email: email,
        nome: nome,
        username: email,
        phoneNumber: phoneNumber,
        phoneNumberFixo: phoneNumberFixo,
        password: password,
        passwordConfirmation: passwordConfirmation,
      };
      //debugger;
      if (phoneNumber.length !== 14) {
        phoneNumber = phoneNumberFixo;
      }
      this.validationSchema
        .validate(dadosRegistro)
        .then((valid) => {
          if (valid) {
            let attributes = {
              email,
              phone_number: phoneNumber,
              name: nome
            }
            if (phoneNumberFixo) {
              attributes["custom:fixo_phone_number"] = phoneNumberFixo
            }
          }
          Auth.signUp({
            username: email,
            password,
            attributes: {
              email,
              phone_number: phoneNumber,
              name: nome,
              "custom:fixo_phone_number": phoneNumberFixo
            },
          })
            .then((result) => {
              this.setState({ signedUp: true, submittingSignUp: false });

              ConsoleLog("REACT_APP_DEV dentro do Register => ", process.env.REACT_APP_DEV);

              if(process.env.REACT_APP_DEV) {

                ConsoleLog("Entrou no cadastro");

                let usuario = {
                  celular: phoneNumber,
                  telefoneFixo: phoneNumberFixo,
                  email: email,
                  nome: nome,
                  username: email,
                };

                ConsoleLog(usuario);

                this.props.saveUsuarioLogin(usuario);
              } else {
                ConsoleLog("Nao entrou no cadastro via banco");
              }
            })
            .catch((err) => {
              this.setState({
                submittingSignUp: false,
                mensagemErro: err.message,
              });

              if (err.message === "Invalid phone number format.") {
                this.setState({
                  mensagemErro: "Numero de telefone invalido",
                });
              } else {
                if (err.message === "User already exists") {
                  this.setState({ mensagemErro: "Usuario já cadastrado" });
                } else if (err.message.match(/1 validation error detected/)) {
                  this.setState({
                    mensagemErro: "Não pode conter espaços no usuario",
                  });
                } else if (err.code === "InvalidPasswordException") {
                  this.setState({
                    mensagemErro:
                      "A senha deve possuir caracteres maiusculos, numeros e simbolos e mais de 6 caracteres",
                  });
                } else if (err.code === "InvalidParameterException") {
                  this.setState({
                    mensagemErro:
                      "A senha deve possuir caracteres maiusculos, numeros e simbolos e mais de 6 caracteres",
                  });
                }
              }
            });
      })
        .catch((err) => {
          this.setState({
            submittingSignUp: false,
            errosForm: err.errors,
          });
        });
    }
  }

  validationSchema = Yup.object().shape({
    //aqui validamos se o email está no formato correto nao está em branco
    //
    //verificando se o campo não está em branco
    // phone: Yup.string().required("Please provide a phone"),
    passwordConfirmation: Yup.string()
      .required("Confirmação de senha obrigatório")
      .oneOf([Yup.ref("password"), null], "Confirmação da senha inválida"),
    password: Yup.string().required("Senha obrigatório"),
    phoneNumber: Yup.string().required("Fone obrigatório"),

    email: Yup.string().email().required("E-mail obrigatório!"),
    nome: Yup.string().required("Nome obrigatorio"),
    //username: Yup.string().required("Informe o login"),
  });

  handleSubmitConfirmationSignUp(e) {
    e.preventDefault(e);
    // const { confirmed, signedUp, username, confirmationCode } = this.state;
    const { confirmed, signedUp, email, confirmationCode } = this.state;

    if (!confirmed && signedUp) {
      this.setState({ submittingConfirmation: true });

      //Auth.confirmSignUp(username, confirmationCode)
      Auth.confirmSignUp(email, confirmationCode)
        .then(() =>
          this.setState({
            submittingConfirmation: false,
            confirmed: true,
            mensagemErro: "",
          })
        )
        .catch((err) => {
          this.setState({
            submittingConfirmation: false,
            mensagemErro: err.message,
          });
        });
    }
  }

  render() {
    const phoneNumberMask = [
      "(",
      /[1-9]/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    const phoneNumberMaskFixo = [
      "(",
      /[1-8]/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    if (this.state.confirmed) {
      return <div>CONFIRMADO</div>;
    }

    if (this.state.signedUp) {
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <span className="clearfix">
                  <img alt="" src={logo} />
                  <h4 className="pt-3">
                    Parabéns! Seu cadastro foi efetuado com sucesso.
                  </h4>
                  <h3 className="float-left">
                    Acesse seu email e confirme seu cadastro.
                  </h3>
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <Card className="mx-4">
                <Form onSubmit={this.handleSubmitSignUp}>
                  <CardBody className="p-4">
                      <Col className="text-center">
                        <img src={logo} alt=""></img>
                      </Col>
                      <h2 className="text-center">
                        Registre-se para criar sua conta
                      </h2>
                      <p className="text-muted"></p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Nome Completo"
                          autoComplete="nome"
                          name="nome"
                          id="nomeignUpInput"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          autoComplete="email"
                          type="email"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                        />
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-screen-smartphone"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <MaskedInput
                          className="form-control"
                          mask={phoneNumberMask}
                          placeholder="Celular"
                          id="phoneNumber"
                          name="phoneNumber"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-phone"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <MaskedInput
                          className="form-control"
                          mask={phoneNumberMaskFixo}
                          placeholder="Telefone Fixo"
                          id="phoneNumberFixo"
                          name="phoneNumberFixo"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Senha"
                          autoComplete="new-password"
                          name="password"
                          id="password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Repita sua senha"
                          autoComplete="new-password"
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                        <ReCAPTCHA
                          sitekey="6LdgGDknAAAAAHWi8-m6lqQ8YpcjpPg9SL4Wq8mw"
                          onChange={this.handleChange}
                        />
                      <Button
                        className="btn btn-primary-cup"
                        block
                        disabled={this.state.submittingSignUp}
                        type="submit"
                      >
                        Cadastrar
                      </Button>
                    <div>{this.state.errosForm}</div>
                    <div>{this.state.mensagemErro}</div>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.usuarioModel.usuario,
  errors: state.usuarioModel.errors,
});

const mapDispatchToProps = (dispatch) => ({
  saveUsuarioLogin: dispatch.usuarioModel.saveUsuarioLogin,
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
