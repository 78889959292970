import {clientRestAPI} from "../clientRestAPI/clientRestAPI";
import {getUsuarioAutenticado,} from "../routes/auth";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";
import moment from "moment";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/escrituracao";

function tratarDataLancamento(dataLancamento) {
  ConsoleLog("tratarDataLancamento entrada", dataLancamento);

  if (dataLancamento) {
    let day = moment(dataLancamento).date();
    let month = moment(dataLancamento).month();
    const year = moment(dataLancamento).year();
    day = day.toString().padStart(2, '0');
    month = (month + 1).toString().padStart(2, '0');
    ConsoleLog("tratarDataLancamento day=", day, "month=", month, "year=", year);
    dataLancamento = `${year}-${month}-${day}T12:00:00.000+0000`;
  }
  ConsoleLog("tratarDataLancamento saida", dataLancamento);

  return dataLancamento;
}

const escrituracaoLoteModel = {
  //definindo o estado inicial da aplicação
  state: {
    resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
    escrituracao: {dataLancamento: ""},
    loading: false,
    errors: {},
    redirect: false,
  },

  //definindo todos os reducers
  reducers: {

    dummy: (state) => {

      ConsoleLog("dummy");

      return {
        ...state,
      };
    },

    verificaCheckedLote: (state, itens) => {
      ConsoleLog("verificaCheckedLote:", itens);

      if (itens && itens.length <= 0) {
        toast.warn("Teste Rodrigo Guimaraes");
      }

      return {
        ...state,
      };
    },

    //Deixa a aplicação pronta para receber um novo escrituracao no formulário
    editEscrituracaoLote: (state) => {
      return {
        ...state,
        resumoEscrituracao: {},
        escrituracao: {
          dataLancamento: "",
          historico: "",
          numeroDocumento: "",
          tipoDocumento: "",
          tipoLancamento: "",
          inscricoesEstaduaisMunicipai: "",
          conta: {codigo: "", nome: ""},
          valor: "",
        },
      };
    },

    cancelForm: (state) => {
      return {
        ...state,
        redirect: true,
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchArquivoAnualFulfiled: (state, payload) => {
      return {
        ...state,
        loading: false,
        redirect: false,
      };
    },

    fetchListaEscrituracaoPending: (state) => {
      return {
        ...state,
        loading: true,
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoFulfiled: (state, payload) => {
      const resumo = payload.data || payload;

      return {
        ...state,
        resumoEscrituracao: resumo,
        loading: false,
        redirect: false,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchEscrituracaoPending: (state) => {
      return {
        ...state,
        loading: true,
        escrituracao: {dataLancamento: ""},
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0},
      };
    },

    deleteEscrituracaoPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    deleteEscrituracaoRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },


    deleteLoteRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

    //atualiza a lista de escrituracao da aplicação caso o escrituracao tenha sido deletado da API com sucesso
    deleteLoteFulfiled: (state) => {
      toast.success("Lote de escrituração deletada com sucesso!");
      return {
        ...state,
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
        loading: false,
      };
    },

    saveLotePending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    saveLoteRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

    //atualiza a lista de escrituracao da aplicação caso o escrituracao tenha sido deletado da API com sucesso
    saveLoteFulfiled: (state, mesSelecionado) => {
      toast.success("Lote de escrituração atualizada com sucesso!");
      return {
        ...state,
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
        loading: false,
        redirect: true,
      };
    },

    desfazerFulfiled: (state) => {
      toast.success("Alteração escrituração desfeita com sucesso!");
      return {
        ...state,
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
        loading: false,
      };
    },

    desfazerRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

  },

  //definindo os effects
  effects: (dispatch) => ({
    fetchArquivoAnual(filtroEscrituracao) {
      //faz a chamada com o client para buscar todos os escrituracaos
      dispatch.escrituracaoLoteModel.fetchEscrituracaoPending();
      return clientRestAPI
        .post(`${url}/getFileEscrituracao`, filtroEscrituracao)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.escrituracaoLoteModel.fetchArquivoAnualFulfiled(res.data);
        });
    },
    //aciona a ação de buscar os escrituracaos
    fetchListaEscrituracao(filtroEscrituracao) {
      ConsoleLog("Chamou: fetchListaEscrituracao com filtro", filtroEscrituracao);

      dispatch.escrituracaoLoteModel.fetchListaEscrituracaoPending();

      return clientRestAPI
        .post(`${url}/escricutacaoMes`, filtroEscrituracao)
        .then((res) => {
          ConsoleLog("retorno: fetchListaEscrituracao", res);
          dispatch.escrituracaoLoteModel.fetchListaEscrituracaoFulfiled(res.data);
        });
    },

    //faz a chamada com o client para apagar um escrituracao
    deleteLote(parametrosDelete) {

      const filtroEscrituracao = parametrosDelete.filtroEscrituracao;
      const idsEscrituracao = parametrosDelete.ids;


      dispatch.escrituracaoLoteModel.deleteEscrituracaoPending();

      const escrituracao = {};
      escrituracao.usuario = getUsuarioAutenticado();
      const escrituracaoLote = {escrituracao, idsEscrituracao};
      ConsoleLog("deleteLote escrituracaoLote", escrituracaoLote);

      return clientRestAPI
        .put(`${url}/deleteLote`, escrituracaoLote)
        .then(_ => {
          dispatch.escrituracaoLoteModel.deleteLoteFulfiled();
          dispatch.escrituracaoLoteModel.fetchListaEscrituracao(filtroEscrituracao);
        })
        .catch((err) => {
          ConsoleLog("deleteLote err", err.response);

          if (err.response.status === 400) {
            dispatch.escrituracaoLoteModel.deleteEscrituracaoRejected("Não foi possível realizar a exclusão em lote.");
          } else {
            dispatch.escrituracaoLoteModel.deleteEscrituracaoRejected(err.response.data);
          }
        });
    },

    //faz a chamada com o client para apagar um escrituracao
    saveLote(escrituracao, _, ids) {
      dispatch.escrituracaoLoteModel.saveLotePending();

      ConsoleLog("saveLote ids", ids);

      let idsEscrituracao = ids;
      escrituracao.usuario = getUsuarioAutenticado();
      escrituracao.dataLancamento = tratarDataLancamento(escrituracao.dataLancamento);

      const escrituracaoLote = {escrituracao, idsEscrituracao};
      ConsoleLog("saveLote escrituracaoLote", escrituracaoLote);

      return clientRestAPI
        .put(`${url}/saveLote`, escrituracaoLote)
        .then(_ => {
          dispatch.escrituracaoLoteModel.saveLoteFulfiled(escrituracao.mesSelecionado);
        })
        .catch((err) => {
          ConsoleLog("saveLote err", err.response);
          if (err.response.status === 400) {
            dispatch.escrituracaoLoteModel.saveLoteRejected("Não foi possível realizar a edição em lote.");
          } else {
            dispatch.escrituracaoLoteModel.saveLoteRejected(err.response.data);
          }
        });
    },

    //faz a chamada com o client para apagar um escrituracao
    desfazer(parametroDesfazer) {
      dispatch.escrituracaoLoteModel.deleteEscrituracaoPending();

      const id = parametroDesfazer.idEscrituracao;
      const filtroEscrituracao = parametroDesfazer.filtroEscrituracao;

      ConsoleLog("desfazer filtroEscrituracao", filtroEscrituracao);

      return clientRestAPI
        .put(`${url}/desfazer/${id}`, getUsuarioAutenticado())
        .then(_ => {
          dispatch.escrituracaoLoteModel.desfazerFulfiled();
          dispatch.escrituracaoLoteModel.fetchListaEscrituracao(filtroEscrituracao);
        })
        .catch((err) => {
          ConsoleLog("desfazer err", err.response);
          dispatch.escrituracaoLoteModel.desfazerRejected(err.response.data);
        });
    },
  }),
};

export default escrituracaoLoteModel;
