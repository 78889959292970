import moment from "moment";
import {toast} from "react-toastify";
import {formataDataMoment} from "../components/utils/validacao-formatacao";
import ConsoleLog from "../pages/commons/ConsoleLog";
import posthog from 'posthog-js'

const TOKEN_KEY = "jwt";
const ACCESS_TOKEN = "accesToken";
const USUARIO = "usuario";
const CONTRIBUINTE_SELECIONADO = "contribuinteHomeSelecionado";

export const isAuth = () => {
  return !!sessionStorage.getItem(TOKEN_KEY);
};

export const registraLogin = (user) => {
  sessionStorage.setItem(TOKEN_KEY, user);
  sessionStorage.setItem(ACCESS_TOKEN, user.signInUserSession.idToken.jwtToken);
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST})
  posthog.identify(user.username);
};

export const registraUsuario = (usuarioCliente) => {
  ConsoleLog("Auth => registraUsuario => setItem => USUARIO", usuarioCliente);
  sessionStorage.setItem(USUARIO, JSON.stringify(usuarioCliente));
};

export const registraUsuarioLimpaContribuinte = (usuarioCliente) => {
  registraUsuario(usuarioCliente);
  sessionStorage.removeItem(CONTRIBUINTE_SELECIONADO);
};

export const isPlanoInicial = () => {
  const usuario = getUsuarioAutenticado();
  return usuario && usuario.assinaturaAtual && usuario.assinaturaAtual.planoInicial && usuario.assinaturaAtual.planoInicial === "S"
}


export const validarPlanoInicial = () => {
  const usuario = getUsuarioAutenticado();
  const assinatura = usuario.assinaturaAtual;

  if (usuario && assinatura && assinatura.planoInicial === "S") {
    let dataInicio = formataDataMoment(assinatura.dataInicio);
    const dataPeriodicidade = moment(dataInicio).add(
      assinatura.periodicidade,
      "days"
    );
    const isBefore = dataPeriodicidade.isBefore(moment());

    /* Valida o Local junto com a validacao do backend */
    if (isBefore || assinatura.planoVencido) {
      if (!toast.isActive("planoVencido")) {
        toast.error(
          "Sua assinatura (" +
            assinatura.nome +
            ") expirou, favor mudar o plano!",
          {
            toastId: "planoVencido",
          }
        );
      }
      return true;
    }
  }

  return false;
};

export const setClienteSelecionadoListContribuinteHome = (payload) => {
  let usuario = JSON.parse(sessionStorage.getItem(USUARIO));
  usuario.cliente.idClienteAtuacao = payload.cliente.idCliente;
  usuario.clienteSelecionado = payload.cliente;
  usuario.listaContribuinte = payload.listaContribuinteHome;
  ConsoleLog("setClienteSelecionadoListContribuinteHome", payload);
  registraUsuario(usuario);
};

export const getUsuarioAutenticado = () => {
  return JSON.parse(sessionStorage.getItem(USUARIO));
};

export const getMenu = () => {
  const usuarioAutenticado = getUsuarioAutenticado();
  return usuarioAutenticado && usuarioAutenticado.menu ? usuarioAutenticado.menu : [];
};

export const atualizarQtdUsuariosCadastrados = (qtd) =>  {
  const valor = Math.abs(qtd);
  const usuarioAutenticado = getUsuarioAutenticado();
  const qtdUsuariosCadastrados = usuarioAutenticado.assinaturaAtual.qtdUsuariosCadastrados;
  let novaQuantidade = qtd >= 0 ? (qtdUsuariosCadastrados + valor) : (qtdUsuariosCadastrados - valor)
  usuarioAutenticado.assinaturaAtual.qtdUsuariosCadastrados = novaQuantidade;
  registraUsuario(usuarioAutenticado);
}

export const atualizarQtdUsuarios = (valor) =>  {
  const usuarioAutenticado = getUsuarioAutenticado();
  usuarioAutenticado.assinaturaAtual.quantidadeUsuarios = valor;
  registraUsuario(usuarioAutenticado);
}

export const getQtdePropriedadesUsuarioAutenticado = () => {
  let usuarioAutenticado = JSON.parse(sessionStorage.getItem(USUARIO));

  if (usuarioAutenticado && usuarioAutenticado.assinaturaAtual) {
    if (usuarioAutenticado.assinaturaAtual.quantidadePropriedades) {
      return usuarioAutenticado.assinaturaAtual.quantidadePropriedades;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const logout = () => {
  ConsoleLog("Auth => logout");
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(USUARIO);
  ConsoleLog("Auth => logout => removeItem => CONTRIBUINTE_SELECIONADO");
  sessionStorage.removeItem(CONTRIBUINTE_SELECIONADO);
};

export const isAdministrador = () => {
  let usuario = JSON.parse(sessionStorage.getItem(USUARIO));
  let adm = false;
  if (usuario) {
    adm = "S" === usuario.administrador;
  }
  return adm;
};

export const isUsuarioPai = () => {
  const usuario = JSON.parse(sessionStorage.getItem(USUARIO));
  return (usuario && usuario.pai);
};

export const existeCliente = () => {
  const usuario = JSON.parse(sessionStorage.getItem(USUARIO));
  return (usuario && usuario.cliente);
}

export const getClienteAtuacao = () => {
  let usuario = getUsuarioAutenticado();
  if (usuario && usuario.clienteSelecionado) {
    return usuario.clienteSelecionado;
  }

  return usuario;
}

export const representaClienteAtuacao = () => {
  /* TODO rever essa logica -- antes retorno era fixo como true */

  /*
  let usuario = JSON.parse(sessionStorage.getItem(USUARIO));
  let retorno = false;
  if (usuario && usuario.cliente && usuario.clienteSelecionado) {
    retorno =
      usuario.cliente.idCliente === usuario.clienteSelecionado.idCliente;
  }
  return retorno;
  */

  return true;
};

export const setarAnoSelecionado = (ano) => {
  const contribuinteSelecionado = getContribuinteHomeAutenticado();
  contribuinteSelecionado.anoSelecionado = ano;
  registraContribuinteSelecionado(contribuinteSelecionado);
}

export const registraContribuinteSelecionado = (contribuinteHomeSelecionado) => {
  sessionStorage.setItem(CONTRIBUINTE_SELECIONADO, JSON.stringify(contribuinteHomeSelecionado));
};

export const getContribuinteHomeAutenticado = () => {
  return JSON.parse(sessionStorage.getItem(CONTRIBUINTE_SELECIONADO));
};
