//importando o client pra ser utilizado
import { clientRestAPI } from "../clientRestAPI/clientRestAPI";
import {
  getContribuinteHomeAutenticado,
  getUsuarioAutenticado
} from "../routes/auth";
import { toast } from "react-toastify";
//definindo a rota a ser acrescentada na rota base definida no client
const url = "/xml";

const xmlModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaEscrituracao: [],
    escrituracao: {
      dataLancamento: "",
      historico: "",
      numeroDocumento: "",
      tipoDocumento: "",
      tipoLancamento: "",
      inscricoesEstaduaisMunicipai: "",
      conta: { codigo: "xxxxaa", nome: "yyaa" },
      valor: ""
    },

    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        escrituracao: {
          dataLancamento: "",
          historico: "",
          numeroDocumento: "",
          tipoDocumento: "",
          tipoLancamento: "",
          inscricoesEstaduaisMunicipai: "",
          conta: { codigo: "xxxxaa", nome: "yyaa" },
          valor: ""
        },
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchArquivoAnualFulfiled: (state, payload) => {
      return {
        ...state,

        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoJaneiroFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoJaneiro: payload.data || payload,
        loading: false,
        redirect: false
      };
    },
    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoFevereiroFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoFevereiro: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoMarcoFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoMarco: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoAbrilFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoAbril: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoMaioFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoMaio: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoJunhoFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoJunho: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoJulhoFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoJulho: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoAgostoFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoAgosto: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoSetembroFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoSetembro: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoOutubroFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoOutubro: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoNovembroFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoNovembro: payload.data || payload,
        loading: false,
        redirect: false
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso os escrituracaos tenham sido obtidos da API com sucesso
    fetchListaEscrituracaoDezembroFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracaoDezembro: payload.data || payload,
        loading: false,
        redirect: false
      };
    },
    //Deixa a aplicação pronta para receber um novo escrituracao no formulário
    newEscrituracao: state => {
      return {
        ...state,
        listaEscrituracao: [],
        escrituracao: {
          dataLancamento: "",
          historico: "",
          numeroDocumento: "",
          tipoDocumento: "",
          tipoLancamento: "",
          inscricoesEstaduaisMunicipai: "",
          conta: { codigo: "xx", nome: "yy" },
          valor: ""
        }
      };
    },

    //atualiza a lista de escrituracaos  da aplicação caso o escrituracao tenha sido salvo com sucesso
    saveEscrituracaoFulfilled: (state, payload) => {
      toast.success("Escrituração criada com sucesso!");
      return {
        ...state,
        listaEscrituracao: [...state.listaEscrituracao, payload],
        escrituracao: {
          id: "",
          dataLancamento: "",
          contaBancariaLabelValue: {},
          inscricaoEstadualMunicipalLabelValue: "",
          historico: "",
          valor: "",
          valorEntrada: "0",
          valorSaida: "0",
          valorEntradaFormatado: "",
          valorSaidaFormatado: "",
          saldo: "0",
          tipoDocumento: "1",
          tipoPagamento: "1",
          tipoLancamento: "1",
          numeroDocumento: "",
          participanteLabelValue: "",
          conta: { id: "", nome: "", codigo: "" }
        },
        errors: undefined,
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveEscrituracaoPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar escrituracao apresente algum problema
    saveEscrituracaoRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //  const { nome, email } = payload.errors;
      toast.error('Erro ao gravar Escrituração! ' + payload.message);
      const errors = { global: payload.message };
      return {
        ...state,
        containsError: true,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um escrituracao tenha dado certo
    fetchEscrituracaoFulfiled: (state, payload) => {
      return {
        ...state,
        escrituracao: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateEscrituracaoFulfiled: (state, payload) => {
      //  const escrituracao = payload;
      toast.success("Escrituração atualizada com sucesso!");
      return {
        ...state,
        listaEscrituracao: [...state.listaEscrituracao, payload],
        escrituracao: {
          id: "",
          dataLancamento: "",
          contaBancariaLabelValue: {},
          inscricaoEstadualMunicipalLabelValue: "",
          historico: "",
          valor: "",
          valorEntrada: "0",
          valorSaida: "0",
          valorEntradaFormatado: "",
          valorSaidaFormatado: "",
          saldo: "",
          tipoDocumento: "1",
          tipoPagamento: "1",
          tipoLancamento: "1",
          numeroDocumento: "",
          participanteLabelValue: "",
          conta: { id: "", nome: "", codigo: "" }
        },
        errors: {},
        loading: false,
        redirect: true,
        tabMesSelecionado : "8"
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar escrituracao apresente algum problema
    updateEscrituracaoRejected: (state, payload) => {
      // const { nome, email } = payload.errors;
      toast.error('Erro ao atualizar Escricuração! ' + payload.message);
      const errors = { global: payload.message };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateEscrituracaoPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchEscrituracaoPending: state => {
      return {
        ...state,
        loading: true,
        escrituracao: { nome: "" }
      };
    },

    //atualiza a lista de escrituracao da aplicação caso o escrituracao tenha sido deletado da API com sucesso
    deleteEscrituracaoFulfiled: (state, payload) => {
      const id = payload.id;
      toast.success("Escrituração deletada com sucesso!");
      return {
        ...state,
        listaEscrituracao: state.listaEscrituracao.filter(
          item => item.id !== id 
        )
      };
      
    }
  },

  //definindo os effects
  effects: dispatch => ({

    fetchArquivoAnual(filtroEscrituracao) {
      //faz a chamada com o client para buscar todos os escrituracaos
      dispatch.xmlModel.fetchEscrituracaoPending();
      return clientRestAPI
        .post(`${url}/getFileEscrituracao`, filtroEscrituracao)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.xmlModel.fetchArquivoAnualFulfiled(
            res.data
          );

        });
    },
    //aciona a ação de buscar os escrituracaos
    fetchListaEscrituracao(filtroEscrituracao) {
      //faz a chamada com o client para buscar todos os escrituracaos
      dispatch.xmlModel.fetchEscrituracaoPending();
      return clientRestAPI
        .post(`${url}/escricutacaoMes`, filtroEscrituracao)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          if (filtroEscrituracao.mes === "01") {
            dispatch.xmlModel.fetchListaEscrituracaoJaneiroFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "02") {
            dispatch.xmlModel.fetchListaEscrituracaoFevereiroFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "03") {
            dispatch.xmlModel.fetchListaEscrituracaoMarcoFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "04") {
            dispatch.xmlModel.fetchListaEscrituracaoAbrilFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "05") {
            dispatch.xmlModel.fetchListaEscrituracaoMaioFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "06") {
            dispatch.xmlModel.fetchListaEscrituracaoJunhoFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "07") {
            dispatch.xmlModel.fetchListaEscrituracaoJulhoFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "08") {
            dispatch.xmlModel.fetchListaEscrituracaoAgostoFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "09") {
            dispatch.xmlModel.fetchListaEscrituracaoSetembroFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "10") {
            dispatch.xmlModel.fetchListaEscrituracaoOutubroFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "11") {
            dispatch.xmlModel.fetchListaEscrituracaoNovembroFulfiled(
              res.data
            );
          } else if (filtroEscrituracao.mes === "12") {
            dispatch.xmlModel.fetchListaEscrituracaoDezembroFulfiled(
              res.data
            );
          }
        });
    },

    //faz a chamada com o clientRestAPI para buscar um escrituracao, por meio do id
    fetchEscrituracao(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.xmlModel.fetchEscrituracaoPending();
      return clientRestAPI.get(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.xmlModel.fetchEscrituracaoFulfiled(res.data);
      });
    },

    //faz a chamada com o client para atualizar os dados de um escrituracao
    updateEscrituracao(escrituracao) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      escrituracao.contribuinte = contribuinteSelecionado;
      const usuarioAutenticado = getUsuarioAutenticado();
      escrituracao.usuario = usuarioAutenticado;

      dispatch.xmlModel.updateEscrituracaoPending();
      return clientRestAPI
        .put(`${url}/${escrituracao.id}`, escrituracao)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.xmlModel.updateEscrituracaoFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.xmlModel.updateEscrituracaoRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para salar os dados de um escrituracao
    saveEscrituracao(escrituracao) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      escrituracao.contribuinte = contribuinteSelecionado;
      const usuarioAutenticado = getUsuarioAutenticado();
      escrituracao.usuario = usuarioAutenticado;
      dispatch.xmlModel.saveEscrituracaoPending();
      return clientRestAPI
        .post(url, escrituracao)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.xmlModel.saveEscrituracaoFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.xmlModel.saveEscrituracaoRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para apagar um escrituracao
    deleteEscrituracao(parametrosDelete) {
      const id  = parametrosDelete.idEscrituracao;
      const filtroEscrituracao = parametrosDelete.filtroEscrituracao;
       
      return clientRestAPI.delete(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.xmlModel.deleteEscrituracaoFulfiled(res.data);
        dispatch.xmlModel.fetchListaEscrituracao(filtroEscrituracao);
        
      });
    }
  })
};

export default xmlModel;
