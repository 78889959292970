//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from '../clientRestAPI/clientRestAPI';
import {toast} from "react-toastify";
import {getUsuarioAutenticado, atualizarQtdUsuariosCadastrados, getClienteAtuacao} from "../routes/auth";
import {Auth} from "aws-amplify";
import ConsoleLog from '../pages/commons/ConsoleLog';
//definindo a rota a ser acrescentada na rota base definida no client
const url = '/usuario';

const usuarioModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaUsuario: [],
    listaTipoComunicacao: [],
    usuario: {nome:"", listaUsuarioComunicacao: [], listaComunicacaoSelecionada: [],},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) =>{
      return{
        ...state,
        redirect: true
      }
    },

    //Deixa a aplicação pronta para receber um novo usuario no formulário
    newUsuario: (state) =>{
      return {
        ...state,
        usuario: {listaUsuarioComunicacao: [], listaComunicacaoSelecionada: []},
        listaTipoComunicacao: [],
      };
    },

    updateListaComunicacaoSelecionada: (state, selectedList) => {
      const usuario = state.usuario;
      usuario.listaComunicacaoSelecionada = selectedList;

      return {
        ...state,
        usuario,
      };
    },

    //atualiza a lista de usuarios  da aplicação caso os usuarios tenham sido obtidos da API com sucesso
    fetchListaUsuarioPending: (state) => {
      return {
        ...state,
        listaUsuario: [],
        usuario: {nome:"", listaUsuarioComunicacao: [], listaComunicacaoSelecionada: [],},
        loading: true,
      };
    },

    //atualiza a lista de usuarios  da aplicação caso os usuarios tenham sido obtidos da API com sucesso
    fetchListaUsuarioFulfiled: (state, payload) => {
      return {
        ...state,
        listaUsuario: payload.data || payload,
        redirect: false,
        loading: false,
      };
    },

    //atualiza a lista de usuarios  da aplicação caso o usuario tenha sido salvo com sucesso
    saveUsuarioFulfilled: (state, payload) =>{
      toast.success("Usuário criado com sucesso!");
      return {
        ...state,
        listaUsuario: [...state.listaUsuario, payload],
        errors:{},
        loading: false,
        redirect: true
      };
    },

    saveUsuarioLoginFulfilled: (state, payload) =>{
      return {
        ...state,
        listaUsuario: [...state.listaUsuario, payload],
        errors:{},
        loading: false,
        redirect: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveUsuarioPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar usuario apresente algum problema
    saveUsuarioRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error ('Erro ao gravar usuário! '+payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },


    //altera o estado da aplicação no caso de a busca de um usuario tenha dado certo
    fetchUsuarioFulfiled: (state, payload) => {
      const usuario = payload;
      usuario.listaComunicacaoSelecionada = usuario.listaUsuarioComunicacao;
      usuario.celular =  usuario.celular.replace('+55', '');
      usuario.password = "";
      usuario.passwordConfirmation = "";

      return {
        ...state,
        usuario,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateUsuarioFulfiled: (state, payload) => {
      //const usuario = payload;
      toast.success("Usuário atualizado com sucesso!");
      return {
        ...state,
        //listaUsuario: state.listaUsuario.map(item => item.id === usuario.id ? usuario : item),
        errors: {},
        loading: false,
        redirect: true
      };
    },

    updateUsuarioFulfiledPorAdmin: (state, payload) => {
      const usuario = payload;
      toast.success("Usuário atualizado com sucesso!");
      return {
        ...state,
        listaUsuario: state.listaUsuario.map(item => item.id === usuario.id ? usuario : item),
        errors: {},
        loading: false,
        redirect: true
      };
    },    

    //mostra os erros encontrados no caso de a operação de atualizar usuario apresente algum problema
    updateUsuariotRejected: (state, payload) => {
      toast.error ('Erro ao atualizar usuário! '+payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateUsuarioPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchUsuarioPending: (state) => {
      return {
        ...state,
        loading: true,
        usuario: {nome:"", listaUsuarioComunicacao: [], listaComunicacaoSelecionada: [],},
      };
    },

    //atualiza a lista de usuario da aplicação caso o usuario tenha sido deletado da API com sucesso
    deleteUsuarioFulfiled: (state, payload) => {
      const id = payload.id;
      return {
        ...state,
        listaUsuario: state.listaUsuario.filter(item => item.id !== id)
      };
    }
  },

  //definindo os effects
  effects: (dispatch) => ({

    //aciona a ação de buscar os usuarios
    fetchListaUsuario() {
      //faz a chamada com o client para buscar todos os usuarios
      const clienteAtuacao = getClienteAtuacao();
      if (clienteAtuacao.idClienteAtuacao) {

        dispatch.usuarioModel.fetchListaUsuarioPending();

        return clientRestAPI.get(`${url}/cliente/${clienteAtuacao.idClienteAtuacao}`)
          .then(res => {
            //caso tudo ocorra bem, o reducer abaixo vai ser acionado
            dispatch.usuarioModel.fetchListaUsuarioFulfiled(res.data);
          })
        }
    },

    fetchListaUsuarios() {
      dispatch.usuarioModel.fetchListaUsuarioPending();

      return clientRestAPI.get(`${url}/all/sort`)
        .then(res => dispatch.usuarioModel.fetchListaUsuarioFulfiled(res.data));
    },

    fetchListaUsuariosNaoAtivados() {
      dispatch.usuarioModel.fetchListaUsuarioPending();

      return clientRestAPI.get(`${url}/not-active`)
        .then(res => dispatch.usuarioModel.fetchListaUsuarioFulfiled(res.data));
    },

    //faz a chamada com o clientRestAPI para buscar um usuario, por meio do id
    fetchUsuario(id){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.usuarioModel.fetchUsuarioPending();
      return clientRestAPI.get(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.fetchUsuarioFulfiled(res.data);
        });
    },

    fetchListaAllUsuario() {
      return clientRestAPI.get(`${url}/all`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.fetchListaUsuarioFulfiled(res.data);
        })
    },

    //faz a chamada com o client para atualizar os dados de um usuario
    updateUsuario(usuario){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.usuarioModel.updateUsuarioPending();

      const usuarioAutenticado = getUsuarioAutenticado();
      usuario.cliente = usuarioAutenticado.cliente;

      return clientRestAPI.put(`${url}/${usuario.id}`, usuario)
        .then(res =>{
          
          
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.updateUsuarioFulfiled(res.data);


        })
        .catch(err =>{
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.updateUsuarioRejected(err.response.data);
        });
    },

    //faz a chamada com o client para salar os dados de um usuario
    saveUsuario(usuario){
      
      const clienteAtuacao = getClienteAtuacao();
      usuario.cliente = clienteAtuacao.idClienteAtuacao;
      // usuario.idPai = clienteAtuacao.id;
      usuario.ambiente = isBackendProd();
      ConsoleLog("clienteAtuacao: ", clienteAtuacao);

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.usuarioModel.saveUsuarioPending();

      console.log("Usuario a salvar", usuario);
      return clientRestAPI.post(url, usuario)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.saveUsuarioFulfilled(res.data);
        })

        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.saveUsuarioRejected(err.response.data);

        });
    },

    saveUsuarioLogin(usuario){
      usuario.ambiente = isBackendProd();

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.usuarioModel.saveUsuarioPending();
      return clientRestAPI.post(url, usuario)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.saveUsuarioLoginFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.usuarioModel.saveUsuarioRejected(err.response.data);
        });
    },

    sendVerificationCode  (usuario)  {
      Auth.forgotPassword(usuario.username)
      .then(data => {
        this.setState({
          mensagemErroLogin: 'Verifique seu email e efetue a alteração de senha',
          mensagemErro : 'Verifique seu email e efetue a alteração de senha',
        error: '',
      })})
        .catch(err => ConsoleLog(err));

    },

    resendSignUpCode  (usuario)  {
      Auth.resendSignUp(usuario.username)
      .then(data => {
        toast.info('E-mail reenviado com sucesso"');
      })
        .catch(err => {
          ConsoleLog(err);
          if (err.message === 'User is already confirmed.') {
            toast.warn('Usuário com email já confirmado, caso não tenha acesso, resetar a senha!');
          } else {
            toast.error('Erro ao reenviar email. ' + err.message);
          }
        });

    },

    resetPassword(usuario) {
  
      Auth.forgotPassword(usuario.username)
        .then(data => {
          toast.info('E-mail de reset de senha enviado ao usuário!"');
        })
        .catch(err => {
          ConsoleLog(err);
          toast.error('Erro ao resetar senha. ' + err.message);
        })
    },
  

    //faz a chamada com o client para apagar um usuario
    deleteUsuario(id){
      dispatch.usuarioModel.fetchListaUsuarioPending();

      return clientRestAPI.delete(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          this.fetchListaUsuariosNaoAtivados();
        })
    },

    forceConfirmUser(id){
      return clientRestAPI.put(`${url}/confirm/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          toast.info('Usuário confirmado com sucesso');
        })
        .catch(err => {
          ConsoleLog(err);
          toast.error('Erro confirmar usuário. ' + err);
        });
    },

    bloquearDesbloquear(usuario){
      dispatch.usuarioModel.saveUsuarioPending();

      const usuarioAutenticado = getUsuarioAutenticado();
      usuario.cliente = usuarioAutenticado.cliente;

      return clientRestAPI.put(`${url}/bloqueio`, usuario)
        .then(res =>{
          const qtd = res.data.bloqueado === "S" ? -1 : 1;
          atualizarQtdUsuariosCadastrados(qtd)  
          dispatch.usuarioModel.fetchListaUsuario();
          dispatch.usuarioModel.updateUsuarioFulfiled(res.data);
        })
        .catch(err =>{
          dispatch.usuarioModel.updateUsuariotRejected(err.response.data);
        });
    },

    bloquearDesbloquearPorAdmin(usuario){
      dispatch.usuarioModel.saveUsuarioPending();

      const usuarioAutenticado = getUsuarioAutenticado();
      usuario.cliente = usuarioAutenticado.cliente;

      return clientRestAPI.put(`${url}/bloqueio`, usuario)
        .then(res =>{
          dispatch.usuarioModel.updateUsuarioFulfiledPorAdmin(res.data);
        })
        .catch(err =>{
          dispatch.usuarioModel.updateUsuariotRejected(err.response.data);
        });
    }

  })
};

export default usuarioModel;
