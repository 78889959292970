import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUsuarioAutenticado,
  getContribuinteHomeAutenticado,
  validarPlanoInicial
} from "../../routes/auth";
import { toast } from "react-toastify";

class VerificaContribuinteSelecionado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toDashboard: false
    };

    this.handleDefault = this.handleDefault.bind(this);
  }

  handleDefault() {
    this.setState(() => ({
      toDashboard: true
    }));
  }

  render() {
    const usuarioAutenticado = getUsuarioAutenticado();
    if (
      usuarioAutenticado &&
      usuarioAutenticado.cliente &&
      usuarioAutenticado &&
      usuarioAutenticado.cliente.idCliente
    ) {

      if(validarPlanoInicial(usuarioAutenticado)) {
        // if (!toast.isActive("planoVencido")) {
        //   toast.error("Sua assinatura (" +  usuarioAutenticado.assinaturaAtual.nome + ") expirou, favor mudar o plano!", {
        //     toastId: "planoVencido"
        //   });
        // }
        return <Redirect to="/pages/minhaAssinatura" />;
      }


      if (
        !usuarioAutenticado.listaContribuinte ||
        (usuarioAutenticado.listaContribuinte &&
          usuarioAutenticado.listaContribuinte.length === 0)
      ) {
        if (!toast.isActive("contribuinteCadastrado")) {
          toast.error("Deve ser cadastrado um contribuinte", {
            toastId: "contribuinteCadastrado"
          });
        }
        return <Redirect to="/pages/contribuinte/new" />;
      } else {
        const contribuinteSelecionado = getContribuinteHomeAutenticado();

        if (contribuinteSelecionado) {
          // contribuinte esta selecionado continua o fluxo

          /* Entra no if caso clique no recarregar pagina do browser, 
          eh necessario para poder exibir contribuinte selecionado corretamente. */
          if(this.props.contribuinteHome && !this.props.contribuinteHome.id) {
            this.props.selecionarContribuinteHome(contribuinteSelecionado);
          }        

          return <div></div>;
        } else {
          if (!toast.isActive("selecionarContribuinte")) {
            toast.error("Deve ser selecionado um contribuinte", {
              toastId: "selecionarContribuinte"
            });
          }

          return <Redirect to="/pages/home" />;
        }
      }
    } else {
      if (usuarioAutenticado) {
        if (!toast.isActive("cadastrarCliente")) {
          toast.error("Deve ser cadastrado um cliente", {
            toastId: "cadastrarCliente"
          });
        }

        return <Redirect to="/pages/cliente/new" />;
      } else {
        if (!toast.isActive("selecionarContribuinte")) {
          toast.error("Selecionar um contribuinte", {
            toastId: "selecionarContribuinte"
          });
        }

        return <Redirect to="/pages/home" />;
      }
    }
  }
}

const mapStateToProps = state => ({
  clienteAutenticado: state.clienteModel.clienteAutenticado,
  contribuinteHome: state.contribuinteModel.contribuinteHome,
  errors: state.clienteModel.errors,
  redirect: state.clienteModel.redirect
});

const mapDispatchToProps = dispatch => ({
  fetchClienteAutenticado: dispatch.clienteModel.fetchClienteAutenticado,
  selecionarContribuinteHome: dispatch.contribuinteModel.selecionarContribuinteHome,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificaContribuinteSelecionado);
