import { clientRestAPI } from "../clientRestAPI/clientRestAPI";
import { toast } from "react-toastify";

const url = "/comunicacao";

const comunicacaoModel = {
  
  //definindo o estado inicial da aplicação
  state: {
    listaComunicacao: [],
    comunicacao: {
      nome: "", 
      tipoComunicacao: {},
      listaTipoComunicacao: [],
    },
    listaUsuario: [],
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {

    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },

    fetchListComunicacaoPending: state => {
      return {
        ...state,
        listaComunicacao: [],
        loading: true
      };
    },

    fetchListaComunicacaoFulfilled: (state, payload) => {
      return {
        ...state,
        listaComunicacao: payload.data || payload,
        redirect: false,
        loading: false
      };
    },    

    updateListaUsuarioSelecionado: (state, selectedList) => {
      const comunicacao = state.comunicacao;
      comunicacao.listaUsuarioSelecionados = selectedList;
      comunicacao.totalUsuarioSelecionados = selectedList.length;

      return {
        ...state,
        comunicacao,
      };
    },

    globalComunicacaoRejected: (state, payload) => {
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    fetchComunicacaoPending: state => {
      return {
        ...state,
        loading: true,
        comunicacao: { nome: "",},
      };
    },    

    fetchComunicacaoFulfilled: (state, payload) => {
      const comunicacao = payload;
      comunicacao.nome = payload ? payload.nome : "";
      const ret = (payload && payload.listaUsuario && payload.listaUsuario.length > 0);
      comunicacao.listaUsuarioSelecionados =  ret ? payload.listaUsuario : [];
      comunicacao.totalUsuarioSelecionados = comunicacao.listaUsuarioSelecionados.length;

      return {
        ...state,
        comunicacao,
        errors: {},
        loading: false,
        redirect: false
      };
    },    

    fetchComunicacaoRejected: (state, payload) => {
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false,
        redirect: true
      };
    },    

    updateComunicacaoPending: state => {
      return {
        ...state,
        loading: true
      };
    },
     
    updateComunicacaoFulfilled: (state) => {
      toast.success("Comunicação atualizada com sucesso!");
      return {
        ...state,
        errors: {},
        loading: false,
        redirect: true
      };
    },

    ativaDesativaComunicacaoPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    ativaDesativaComunicacaoFulfilled: (state, id, status) => {
      const msgStatus = status ? "ativada" : "desativada";
      toast.success(`Comunicação ${msgStatus} com sucesso!`);
      
      const listaComunicacao = state.listaComunicacao.map(item => {
         if(item.id === id) {
            item.status = status;
         }
         return item;
      });

      return {
        ...state,
        listaComunicacao,
        loading: false,
      };
    },
  },

  //definindo os effects
  effects: dispatch => ({

    fetchListaComunicacao() {
      dispatch.comunicacaoModel.fetchListComunicacaoPending();
      return clientRestAPI.get(`${url}/tipoComunicacao/all`).then(res => {
        dispatch.comunicacaoModel.fetchListaComunicacaoFulfilled(res.data);
      });
    },

    fetchComunicacao(id) {
      dispatch.comunicacaoModel.fetchComunicacaoPending();
      return clientRestAPI
        .get(`${url}/tipoComunicacao/${id}`)
        .then(res => {
          dispatch.comunicacaoModel.fetchComunicacaoFulfilled(res.data);
        })
        .catch(err => {
          dispatch.comunicacaoModel.fetchComunicacaoRejected(err.response.data);
        });
    },

    updateComunicacao(comunicacao) {
      dispatch.comunicacaoModel.updateComunicacaoPending();
      return clientRestAPI
        .put(`${url}/${comunicacao.id}`, comunicacao.listaUsuarioSelecionados)
        .then(() => {
          dispatch.comunicacaoModel.updateComunicacaoFulfilled();
        })
        .catch(err => {
          dispatch.comunicacaoModel.globalComunicacaoRejected(err.response.data );
        });
    },

    ativaDesativaComunicacao(tipoComunicacao) {
      dispatch.comunicacaoModel.ativaDesativaComunicacaoPending();

      const status = !tipoComunicacao.status;
      const urlStatus =  status ? "ativar" : "desativar";
      return clientRestAPI
        .put(`${url}/${urlStatus}/${tipoComunicacao.id}`)
        .then(res => {
          dispatch.comunicacaoModel.ativaDesativaComunicacaoFulfilled(tipoComunicacao.id, status);
        })
        .catch(err => {
          dispatch.comunicacaoModel.globalComunicacaoRejected(err.response.data );
        });
    },

  })
};

export default comunicacaoModel;