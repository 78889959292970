import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import logo from "../../assets/img/brand/01_LOGO.png";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import ConsoleLog from "./ConsoleLog";
import LoadingOverlay from "react-loading-overlay";
import {Auth} from "aws-amplify";

class ForgotPasswordPage extends Component {
  state = {
    username: "",
    password: "",
    signedIn: false,
    isSigningIn: false,
    isSigningOut: false,
    tokenId: "",
    refreshToken: "",
    mensagemErroLogin: "",
    disable: false,
    loading: false
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.sendVerificationCode = this.sendVerificationCode.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async getClienteAutenticado(username) {
    await this.props.fetchClienteAutenticado(username);
    this.props.history.push("/pages/home");
  }

  sendVerificationCode = (event) => {
    event.preventDefault();

    this.setState({
      disable: true,
    })

    Auth.forgotPassword(this.state.username)
      .then(data => {
        this.setState({
          mensagemErroLogin: 'Verifique seu email e efetue a alteração de senha',
          error: '',
          disable: false,
          loading: false
        })
      })
      .catch(err => {
        ConsoleLog(err);
        this.setState({
          disable: false,
          loading: false
        })
      })
  }

  teste() {
    this.props.sendVerificationCode(this.state.username)
      .then(() => this.setState({
        message: 'Verification code sent',
        error: '',
      }))
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          this.setState({error: 'User not found'});
        } else {
          this.setState({error: err.message})
        }
      });
  }

  handleLogout() {
    if (this.state.signedIn) {
      this.setState({isSigningOut: true});
      Auth.signOut()
        .then(data => {
          this.setState({
            signedIn: false,
            isSigningOut: false,
            tokenId: "",
            refreshToken: "",
            disable: false,
            loading: false
          });
        })
        .catch(err => {
          this.setState({
            isSigningOut: false,
            disable: false,
            loading: false
          });
        });
    }
  }

  componentDidMount() {
    this.setState({isSigningIn: true});
    Auth.currentSession()
      .then(userSession => {
        this.setState({
          signedIn: true,
          isSigningIn: false,
          tokenId: userSession.idToken.jwtToken,
          refreshToken: userSession.refreshToken.token,
          disable: false,
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          isSigningIn: false,
          disable: false,
          loading: false
        });
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>

                <LoadingOverlay
                  active={this.state.loading}
                  spinner
                  text="Carregando..."
                >

                  <Card className="p-4" style={{height: "320px"}}>
                    <Col className="text-center">
                      <img src={logo} alt=""></img>
                    </Col>
                    <CardBody>
                      <form onSubmit={this.sendVerificationCode}>
                        <h6 className="text-center">Informe seu login e receberá um email para alterar sua senha</h6>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input
                            className="form-control"
                            type="text"
                            name="username"
                            id="usernameSignInInput"
                            onChange={this.handleChange}
                          />

                          <Row>
                            <Col xs="12">
                              <div className="text-center">{this.state.mensagemErroLogin}</div>
                            </Col>
                          </Row>
                        </InputGroup>

                        <Row>
                          <FormGroup row className="my-0">
                            <Col xs="6">
                              <div className="form-group form-check">
                              </div>
                            </Col>
                            <Col xs="6">
                              <button
                                type="submit"
                                className="btn btn-primary-cup"
                                width="100px"
                                disabled={this.state.disable}
                                onClick={() => this.setState({loading: true})}
                              >
                                Recuperar
                              </button>
                            </Col>
                          </FormGroup>
                        </Row>
                      </form>
                      {" "}
                    </CardBody>
                  </Card>
                </LoadingOverlay>

              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clienteAutenticado: state.clienteModel.clienteAutenticado,
  errors: state.clienteModel.errors,
  redirect: state.clienteModel.redirect
});

const mapDispatchToProps = dispatch => ({
  fetchClienteAutenticado: dispatch.clienteModel.fetchClienteAutenticado

});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
