//importando o client pra ser utilizado
import {clientRestAPI} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/contaBancaria";

const contaBancariaModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaContaBancaria: [],
    contaBancaria: { nomeCartao: "" },
    loading: false,
    errors: {},
    redirect: false,
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) => {
      return {
        ...state,
        redirect: true,
        loading: false,
      };
    },

    //atualiza a lista de contaBancarias  da aplicação caso os contaBancarias tenham sido obtidos da API com sucesso
    fetchListaContaBancariaFulfiled: (state, payload) => {
      return {
        ...state,
        listaContaBancaria: payload.data || payload,
        redirect: false,
        loading: false,
      };
    },

    limpaListaContaBancariaFulfiled: (state, payload) => {
      return {
        ...state,
        listaContaBancaria: [],
        redirect: false,
        loading: false,
      };
    },

    fetchListaContaBancariaOptionValueFulfiled: (state, payload) => {
      return {
        ...state,
        listaContaBancariaOptionValue: payload.data
          ? payload.data.map(function (item) {
              let label = item.codigoConta + "/" + item.contaCorrente;
              return { value: item, label: label };
            })
          : payload.map(function (item) {
              let label = item.codigoConta + "/" + item.contaCorrente;
              return { value: item, label: label };
            }),
        redirect: false,
      };
    },

    //Deixa a aplicação pronta para receber um novo contaBancaria no formulário
    newContaBancaria: (state) => {
      return {
        ...state,
        contaBancaria: { nomeCartao: "" },
      };
    },

    //atualiza a lista de contaBancarias  da aplicação caso o contaBancaria tenha sido salvo com sucesso
    saveContaBancariaFulfilled: (state, payload) => {
      toast.success("Conta bancária deletada com sucesso!");
      return {
        ...state,
        listaContaBancaria: [...state.listaContaBancaria, payload],
        errors: {},
        loading: false,
        redirect: false,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveContaBancariaPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar contaBancaria apresente algum problema
    saveContaBancariaRejected: (state, payload) => {
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false,
      };
    },

    //altera o estado da aplicação no caso de a busca de um contaBancaria tenha dado certo
    fetchContaBancariaFulfiled: (state, payload) => {
      return {
        ...state,
        contaBancaria: payload,
        errors: {},
        loading: false,
        redirect: false,
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateContaBancariaFulfiled: (state, payload) => {
      toast.success("Conta bancária atualizada com sucesso!");
      const contaBancaria = payload;
      return {
        ...state,
        listaContaBancaria: state.listaContaBancaria.map((item) =>
          item.id === contaBancaria.id ? contaBancaria : item
        ),
        errors: {},
        loading: false,
        redirect: false,
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar contaBancaria apresente algum problema
    updateContaBancariatRejected: (state, payload) => {
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateContaBancariaPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchContaBancariaPending: (state) => {
      return {
        ...state,
        loading: true,
        contaBancaria: { nome: "" },
      };
    },

    //atualiza a lista de contaBancaria da aplicação caso o contaBancaria tenha sido deletado da API com sucesso
    deleteContaBancariaFulfiled: (state, payload) => {
      toast.success("Conta bancária deletada com sucesso!");
      const id = payload;
      return {
        ...state,
        listaContaBancaria: state.listaContaBancaria.filter(
          (item) => item.id !== id
        ),
        loading: false,
      };
    },

    deleteContaBancariaPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    deleteContaBancariaRejected: (state, payload) => {
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

    baixarContaBancariaFulfiled: (state, payload) => {
      toast.success('Conta Bancária Baixada com Sucesso!');
      return {
        ...state,
        listaContaBancaria: state.listaContaBancaria.map(cb =>
          (payload && cb.id === payload.id) ? payload : cb
        ),
        loading: false,
      };
    },

    baixarContaBancariaRejected: (state) => {
      toast.error('Não foi possível baixar conta bancária');
      return {
        ...state,
        loading: false,
      };
    },
  },

  //definindo os effects
  effects: (dispatch) => ({
    //aciona a ação de buscar os contaBancarias
    fetchListaContaBancaria(id) {
      //faz a chamada com o client para buscar todos os contaBancarias
      if (id) {
        return clientRestAPI.get(`${url}/all/${id}`).then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.fetchListaContaBancariaFulfiled(res.data);
        });
      } else {
        dispatch.contaBancariaModel.limpaListaContaBancariaFulfiled();
      }
    },

    fetchListaContaBancariaOptionValue(id) {
      return clientRestAPI.get(`${url}/all/${id}`).then((res) => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.contaBancariaModel.fetchListaContaBancariaOptionValueFulfiled(
          res.data
        );
      });
    },

    matchContaBancaria(state, value) {
      return (
        state.contaCorrente.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    },

    //faz a chamada com o clientRestAPI para buscar um contaBancaria, por meio do id
    fetchContaBancaria(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contaBancariaModel.fetchContaBancariaPending();
      return clientRestAPI.get(`${url}/${id}`).then((res) => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.contaBancariaModel.fetchContaBancariaFulfiled(res.data);
      });
    },

    //faz a chamada com o client para atualizar os dados de um contaBancaria
    updateContaBancaria(contaBancaria) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contaBancariaModel.updateContaBancariaPending();
      return clientRestAPI
        .put(`${url}/${contaBancaria.id}`, contaBancaria)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.updateContaBancariaFulfiled(res.data);
        })
        .catch((err) => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.updateContaBancariaRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para salar os dados de um contaBancaria
    saveContaBancaria(contaBancaria) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contaBancariaModel.saveContaBancariaPending();
      return clientRestAPI
        .post(url, contaBancaria)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.saveContaBancariaFulfilled(res.data);
        })
        .catch((err) => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.saveContaBancariaRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para apagar um contaBancaria
    deleteContaBancaria(id) {
      dispatch.contaBancariaModel.deleteContaBancariaPending();

      return clientRestAPI
        .delete(`${url}/${id}`)
        .then(() => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.deleteContaBancariaFulfiled(id);
        })
        .catch((err) => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contaBancariaModel.deleteContaBancariaRejected(err.response.data);
        });
    },

    baixarContaBancaria(id) {

      ConsoleLog("[Model] Conta Bancaria (baixarContaBancaria)");

      dispatch.contaBancariaModel.deleteContaBancariaPending();

      return clientRestAPI.put(`${url}/baixar/${id}`)
        .then(res => {
          dispatch.contaBancariaModel.baixarContaBancariaFulfiled(res.data);
        }).catch(err => {
          dispatch.contaBancariaModel.baixarContaBancariaRejected();
        });
    },
  }),
};

export default contaBancariaModel;
