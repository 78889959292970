//importando o client pra ser utilizado
import {clientRestAPI} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/inscricaoEstadualMunicipal";

const inscricaoEstadualMunicipalModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaInscricaoEstadualMunicipal: [],
    listaParticipanteOptionValue: [],
    inscricaoEstadualMunicipal: {nomeCartao: "", totalParticipacao: "0", totalParticipacaoTerceiro: "0"},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {

    //atualiza a lista de terceiros  da aplicação caso o terceiro tenha sido salvo com sucesso
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },

    //atualiza a lista de inscricaoEstadualMunicipals  da aplicação caso os inscricaoEstadualMunicipals tenham sido obtidos da API com sucesso
    fetchListaInscricaoEstadualMunicipalFulfiled: (state, payload) => {
      return {
        ...state,
        listaInscricaoEstadualMunicipal: payload.data || payload,
        redirect: false
      };
    },

    limpaListaInscricaoEstadualMunicipalFulfiled: (state, payload) => {
      return {
        ...state,
        listaInscricaoEstadualMunicipal: [],
        redirect: false
      };
    },

    fetchListaInscricaoEstadualMunicipalOptionValueFulfiled: (
      state,
      payload
    ) => {
      return {
        ...state,

        listaInscricaoEstadualMunicipalOptionValue: payload.data
          ? payload.data.map(function (item) {
            let label = item.codigoImovel + "-" + item.nomeImovel;

            return {value: item, label: label};
          })
          : payload.map(function (item) {
            let label = item.codigoImovel + "-" + item.nomeImovel;
            return {value: item, label: label};
          }),

        redirect: false
      };
    },
    fetchListaParticipanteOptionValueFulfiled: (state, payload) => {
      return {
        ...state,

        listaParticipanteOptionValue: payload.data
          ? payload.data.map(function (item) {
            let label = item.cpfCnpj + "-" + item.nome;

            return {value: item, label: label};
          })
          : payload.map(function (item) {
            let label = item.cpfCnpj + "-" + item.nome;
            return {value: item, label: label};
          }),

        redirect: false
      };
    },

    limpaListaParticipanteFulfiled: (state, payload) => {
      return {
        ...state,
        listaParticipanteOptionValue: [],
        redirect: false
      };
    },

    //Deixa a aplicação pronta para receber um novo inscricaoEstadualMunicipal no formulário
    newInscricaoEstadualMunicipal: state => {
      return {
        ...state,
        inscricaoEstadualMunicipal: {nomeCartao: "", totalParticipacao: "0", totalParticipacaoTerceiro: "0"},
      };
    },

    //atualiza a lista de inscricaoEstadualMunicipals  da aplicação caso o inscricaoEstadualMunicipal tenha sido salvo com sucesso
    saveInscricaoEstadualMunicipalFulfilled: (state, payload) => {
      toast.success('Imóvel Cadastrado com Sucesso!');
      return {
        ...state,
        listaInscricaoEstadualMunicipal: [
          ...state.listaInscricaoEstadualMunicipal,
          payload
        ],
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveInscricaoEstadualMunicipalPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar inscricaoEstadualMunicipal apresente algum problema
    saveInscricaoEstadualMunicipalRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome, email } = payload.errors;
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um inscricaoEstadualMunicipal tenha dado certo
    fetchInscricaoEstadualMunicipalFulfiled: (state, payload) => {
      const inscricaoEstadualMunicipal = {...payload};
      inscricaoEstadualMunicipal.totalParticipacao = state.inscricaoEstadualMunicipal.participacao;
      inscricaoEstadualMunicipal.totalParticipacaoTerceiro = "0";

      return {
        ...state,
        inscricaoEstadualMunicipal,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateInscricaoEstadualMunicipalFulfiled: (state, payload) => {
      const inscricaoEstadualMunicipal = payload;
      toast.success('Imóvel Atualizado com Sucesso!');
      return {
        ...state,
        listaInscricaoEstadualMunicipal: state.listaInscricaoEstadualMunicipal.map(
          item =>
            item.id === inscricaoEstadualMunicipal.id
              ? inscricaoEstadualMunicipal
              : item
        ),
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar inscricaoEstadualMunicipal apresente algum problema
    updateInscricaoEstadualMunicipalRejected: (state, payload) => {
      toast.error('Erro ao atualizar  Imóvel! ' + payload.message);
      const errors = {global: payload.message};
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateInscricaoEstadualMunicipalPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchInscricaoEstadualMunicipalPending: state => {
      return {
        ...state,
        loading: true,
        inscricaoEstadualMunicipal: {nomeCartao: "", totalParticipacao: "0", totalParticipacaoTerceiro: "0"},
      };
    },

    deleteInscricaoEstadualMunicipalPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    deleteInscricaoEstadualMunicipalRejected: state => {
      return {
        ...state,
        loading: false
      };
    },

    //atualiza a lista de inscricaoEstadualMunicipal da aplicação caso o inscricaoEstadualMunicipal tenha sido deletado da API com sucesso
    deleteInscricaoEstadualMunicipalFulfiled: (state, payload) => {
      toast.success('Imóvel Removido com Sucesso!');
      const id = payload;
      return {
        ...state,
        listaInscricaoEstadualMunicipal: state.listaInscricaoEstadualMunicipal.filter(
          item => item.id !== id
        ),
        loading: false,
      };
    },

    baixarInscricaoEstadualMunicipalFulfiled: (state, payload) => {
      toast.success('Imóvel Baixado com Sucesso!');
      return {
        ...state,
        listaInscricaoEstadualMunicipal: state.listaInscricaoEstadualMunicipal.map(inscricao =>
          (payload && inscricao.id === payload.id) ? payload : inscricao
        ),
        loading: false,
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os inscricaoEstadualMunicipals
    fetchListaInscricaoEstadualMunicipal(id) {
      //faz a chamada com o client para buscar todos os inscricaoEstadualMunicipals

      ConsoleLog("[Model] Inscricao Estaudal (fetchListaInscricaoEstadualMunicipal)");

      if (id) {
        const urlGetAll = `${url}/all/${id}`;
        return clientRestAPI.get(urlGetAll).then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.fetchListaInscricaoEstadualMunicipalFulfiled(
            res.data
          );
        });
      } else {
        dispatch.inscricaoEstadualMunicipalModel.limpaListaInscricaoEstadualMunicipalFulfiled();
      }
    },

    fetchListaParticipanteOptionValue(id) {
      //faz a chamada com o client para buscar todos os inscricaoEstadualMunicipals
      ConsoleLog("[Model] Inscricao Estaudal (fetchListaParticipanteOptionValue)");

      if (id) {
        const urlGetAll = `${url}/participantes/${id}`;
        return clientRestAPI.get(urlGetAll).then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.fetchListaParticipanteOptionValueFulfiled(
            res.data
          );
        });
      } else {
        dispatch.inscricaoEstadualMunicipalModel.limpaListaParticipanteFulfiled();
      }
    },

    fetchListaInscricaoEstadualMunicipalOptionValue(id) {
      ConsoleLog("[Model] Inscricao Estaudal (fetchListaInscricaoEstadualMunicipalOptionValue)");

      //faz a chamada com o client para buscar todos os inscricaoEstadualMunicipals
      if (id) {
        const urlGetAll = `${url}/all/${id}`;
        return clientRestAPI.get(urlGetAll).then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.fetchListaInscricaoEstadualMunicipalOptionValueFulfiled(
            res.data
          );
        });
      }
    },

    //faz a chamada com o clientRestAPI para buscar um inscricaoEstadualMunicipal, por meio do id
    fetchInscricaoEstadualMunicipal(id) {

      ConsoleLog("[Model] Inscricao Estaudal (fetchInscricaoEstadualMunicipal)");

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.inscricaoEstadualMunicipalModel.fetchInscricaoEstadualMunicipalPending();
      return clientRestAPI.get(`${url}/${id}`).then(res => {

        /* Tratando os totais para verificacao da participacao */
        const payload = res.data;
        const inscricaoEstadualMunicipal = {...payload};
        inscricaoEstadualMunicipal.totalParticipacaoTerceiro = "0";
        inscricaoEstadualMunicipal.totalParticipacao = inscricaoEstadualMunicipal.participacao;

        ConsoleLog("Inscricao Estadual (" + id + ")", res.data);

        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.inscricaoEstadualMunicipalModel.fetchInscricaoEstadualMunicipalFulfiled(
          res.data
        );
      });
    },

    //faz a chamada com o client para atualizar os dados de um inscricaoEstadualMunicipal
    updateInscricaoEstadualMunicipal(inscricaoEstadualMunicipal, objectModel, listaTerceiro) {

      ConsoleLog("[Model] Inscricao Estaudal (updateInscricaoEstadualMunicipal)");

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.inscricaoEstadualMunicipalModel.updateInscricaoEstadualMunicipalPending();

      /* Seta os negativos como null */
      inscricaoEstadualMunicipal.terceiros = listaTerceiro.map((terceiro) => {
        if (terceiro.idTerceiro < 0) {
          terceiro.idTerceiro = null;
        }
        terceiro.inscricaoEstadualMunicipal = null;
        return terceiro;
      });

      return clientRestAPI
        .put(
          `${url}/${inscricaoEstadualMunicipal.id}`,
          inscricaoEstadualMunicipal
        )
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.updateInscricaoEstadualMunicipalFulfiled(
            res.data
          );

        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.updateInscricaoEstadualMunicipalRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para salar os dados de um inscricaoEstadualMunicipal
    saveInscricaoEstadualMunicipal(inscricaoEstadualMunicipal, objectModel, listaTerceiro) {

      ConsoleLog("[Model] Inscricao Estaudal (saveInscricaoEstadualMunicipal)");

      dispatch.inscricaoEstadualMunicipalModel.saveInscricaoEstadualMunicipalPending();

      /* Seta os negativos como null */
      inscricaoEstadualMunicipal.terceiros = listaTerceiro.map((terceiro) => {
        if (terceiro.idTerceiro < 0) {
          terceiro.idTerceiro = null;
        }
        terceiro.inscricaoEstadualMunicipal = null;
        return terceiro;
      });

      ConsoleLog("Inscricao Estaudal (criar)", inscricaoEstadualMunicipal);

      return clientRestAPI
        .post(url, inscricaoEstadualMunicipal)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.saveInscricaoEstadualMunicipalFulfilled(
            res.data
          );
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.saveInscricaoEstadualMunicipalRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para apagar um inscricaoEstadualMunicipal
    deleteInscricaoEstadualMunicipal(id) {

      ConsoleLog("[Model] Inscricao Estaudal (deleteInscricaoEstadualMunicipal)");

      dispatch.inscricaoEstadualMunicipalModel.deleteInscricaoEstadualMunicipalPending();

      return clientRestAPI.delete(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.inscricaoEstadualMunicipalModel.deleteInscricaoEstadualMunicipalFulfiled(
          id
        );
      }).catch(err => {
        toast.error('Existem registros associados a este Imóvel');
        dispatch.inscricaoEstadualMunicipalModel.deleteInscricaoEstadualMunicipalRejected();
      });
    },

    baixarInscricaoEstadualMunicipal(id) {

      ConsoleLog("[Model] Inscricao Estaudal (baixarInscricaoEstadualMunicipal)");

      dispatch.inscricaoEstadualMunicipalModel.deleteInscricaoEstadualMunicipalPending();

      return clientRestAPI.put(`${url}/baixar/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.inscricaoEstadualMunicipalModel.baixarInscricaoEstadualMunicipalFulfiled(
            res.data
          );
        }).catch(err => {
          toast.error('Não foi possível baixar essa inscrição estadual');
          dispatch.inscricaoEstadualMunicipalModel.deleteInscricaoEstadualMunicipalRejected();
        });
    },
  })
};

export default inscricaoEstadualMunicipalModel;
