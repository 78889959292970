import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Auth, Cache} from "aws-amplify";
import {connect} from "react-redux";
import {logout, registraLogin} from "../../routes/auth";
import logo from "../../assets/img/brand/01_LOGO.png";
import {toast} from "react-toastify";
import {clientRestAPI} from '../../clientRestAPI/clientRestAPI';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";

class LoginPageAutentica extends Component {
  state = {
    username: "",
    password: "",
    signedIn: false,
    isSigningIn: false,
    isSigningOut: false,
    tokenId: "",
    refreshToken: "",
    mensagemErroLogin: ""
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async getClienteAutenticado(username) {
    await this.props.fetchClienteAutenticado(username);
    this.props.history.push("/pages/home");
    window.location.reload();
  }

  handleSubmit(e) {
    //debugger
    e.preventDefault();

    var  username = this.state.username.trim();
    const {password } = this.state;

    this.setState({ isSigningIn: true, isLoading: true });
    Auth.signIn({
      username,
      password
    })
      .then(cognitoUser => {
        Auth.currentSession()
          .then(userSession => {
            this.setState({
              signedIn: true,

              isSigningIn: false,
              tokenId: userSession.idToken.jwtToken,
              refreshToken: userSession.refreshToken.token
            });
            registraLogin(cognitoUser);
            this.getClienteAutenticado(cognitoUser.username);
            //   this.props.fetchClienteAutenticado(cognitoUser.username);
            // this.props.history.push("/pages/home");
          })

          .catch(err => {
            this.setState({
              isSigningIn: false,
              mensagemErroLogin: err.message
            });
            logout();
          });
      })
      .catch(err => {
        this.setState({ isSigningIn: false });
        this.setState({ isSigningIn: false, mensagemErroLogin: err.message });

        logout();
      });
    //}
  }


  changeAuthStorageConfiguration(e) {
    const shouldRememberUser = e.target.checked;
    if (shouldRememberUser) {
      const localStorageCache = Cache.createInstance({
        keyPrefix: "localStorageAuthCache",
        storage: window.localStorage
      });

      Auth.configure({
        storage: localStorageCache
      });
    } else {
      const sessionStorageCache = Cache.createInstance({
        keyPrefix: "sessionAuthCache",
        storage: window.sessionStorage
      });

      Auth.configure({
        storage: sessionStorageCache
      });
    }
  }

  handleLogout() {
    if (this.state.signedIn) {
      this.setState({ isSigningOut: true });
      Auth.signOut()
        .then(data => {
          this.setState({
            signedIn: false,
            isSigningOut: false,
            tokenId: "",
            refreshToken: ""
          });
        })
        .catch(err => {
          this.setState({ isSigningOut: false });
        });
    }
  }

  componentDidMount() {
    const code = this.props.match.params.code;
    const username = this.props.match.params.username
    const clientId = this.props.match.params.clientId

    const url = "/autenticacao/"+code+"/"+username+"/"+clientId;

    clientRestAPI
    .get(`${url}`)
    .then(res => {
      toast.success("Cliente Cadastrado com Sucesso");
      //caso tudo ocorra bem, o reducer abaixo vai ser acionado

    })
    .catch(err => {
      toast.error("Cliente Já cadastrado")
      //caso ocorra algum problema, o reducer abaixo vai ser acionado

    });
    this.setState({ isSigningIn: true });
    Auth.currentSession()
      .then(userSession => {
        this.setState({
          signedIn: true,

          isSigningIn: false,
          tokenId: userSession.idToken.jwtToken,
          refreshToken: userSession.refreshToken.token
        });
      })
      .catch(err => {
        this.setState({ isSigningIn: false });
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4" style={{ height: "320px" }}>
                  <Col className="text-center">
                    <img src={logo} alt=""></img>
                  </Col>
                  <CardBody>
                    <form onSubmit={this.handleSubmit}>
                      <h3 className="text-center">Faça login em sua conta</h3>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <input
                          className="form-control"
                          type="text"
                          name="username"
                          id="usernameSignInInput"
                          placeholder="Email/Login"
                          required onchange="username = username.trim()"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          placeholder="Senha"
                          id="passwordSignInInput"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <div>{this.state.mensagemErroLogin}</div>
                        </Col>
                      </Row>
                      <Row>
                        <FormGroup row className="my-0">
                          <Col xs="6">
                            <div className="form-group form-check">
                            </div>
                          </Col>
                          <Col xs="6">
                            <button
                              type="submit"
                              className="btn btn-primary-cup"
                              width="100px"
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Entrar
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </button>
                          </Col>
                          <Col xs="8" className="text-right">
                            <Link to="/forgot"><Button color="link" className="px-0">
                              Esqueci minha senha
                            </Button></Link>
                          </Col>
                        </FormGroup>
                      </Row>
                    </form>{" "}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clienteAutenticado: state.clienteModel.clienteAutenticado,
  errors: state.clienteModel.errors,
  redirect: state.clienteModel.redirect
});

const mapDispatchToProps = dispatch => ({
  fetchClienteAutenticado: dispatch.clienteModel.fetchClienteAutenticado
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageAutentica);
