import React, {Component, Suspense} from "react";
import * as router from "react-router-dom";
import {Redirect, Route, Switch} from "react-router-dom";
import {Container} from "reactstrap";
import {isAuth, logout} from "../../routes/auth";
import VerificaContribuinteSelecionado from "../../components/home/verifica-contribuinte-selecionado";

import {
  AppAside,
  AppBreadcrumb2 as AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import menus from "../../_nav";
// routes config
import routes from "../../routes/routes.config";
import {Auth} from "aws-amplify";

const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));
let items;

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Carregando...</div>
  );


  constructor(props) {
    super(props);

    this.state = {
      isLogin: isAuth(),
    };

    items = menus.items;
  }

  signOut(e) {
    e.preventDefault();

    this.setState({ isSigningOut: true });
    Auth.signOut()
      .then((data) => {
        this.setState({
          signedIn: false,
          isSigningOut: false,
          tokenId: "",
          refreshToken: "",
        });

        logout();

        this.setState({
          isLogin: false,
        });
        this.props.history.push("/signin");
      })

      .catch((err) => {
        this.setState({ isSigningOut: false });
        logout();
      });
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav
                navConfig={{items}}
                {...this.props}
                router={router}
              />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} router={router} />
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/pages/home" />
                  <VerificaContribuinteSelecionado />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
