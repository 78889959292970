//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from '../clientRestAPI/clientRestAPI';
import {toast} from "react-toastify";
import {getClienteAtuacao, getUsuarioAutenticado} from "../routes/auth";

//definindo a rota a ser acrescentada na rota base definida no client
const url = '/cliente';

const minhaConsultoriaModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaMinhaConsultoria: [],
    consultoriaSelecionada:{},
    loading: false,
    errors: {},
    redirect: false,
    showModalLogout: false
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) =>{
      return{
        ...state,
        redirect: true
      }
    },

    //atualiza a lista de MinhaConsultorias  da aplicação caso os MinhaConsultorias tenham sido obtidos da API com sucesso
    fetchListaMinhaConsultoriaFulfiled: (state, payload) => {

      const retorno =  {
        ...state,
        consultoriaSelecionada : payload.data? payload.data.planoAtual : payload.planoAtual,
        listaMinhaConsultoria: payload.data? payload.data.listaPlanos : payload.listaPlanos,
        redirect: false,
        loading: false
      };
      return retorno;
    },


    //atualiza a lista de usuarios  da aplicação caso o usuario tenha sido salvo com sucesso
    associarMinhaConsultoriaFulfilled: (state, payload) =>{
    //  toast.success("Consultoria Associada com sucesso!");
      return {
        ...state,
        errors:{},
        loading: true,
        redirect: true,
        showModalLogout: true

      };

    },


    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    associarMinhaConsultoriaPending: (state) => {
      return {
        ...state,
        listaMinhaConsultoria: [],
        consultoriaSelecionada:{},
        loading: true,
        showModalLogout: false
      };
    },


    //mostra os erros encontrados no caso de a operação de salvar MinhaConsultoria apresente algum problema
    associarMinhaConsultoriaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error (payload);
      const errors = { global: payload };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    cancelarMinhaConsultoriaPending: (state) => {
      return {
        ...state,
        listaMinhaConsultoria: [],
        consultoriaSelecionada:{},
        loading: true,
        showModalLogout: false
      };
    },

    cancelarMinhaConsultoriaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error (payload);
      const errors = { global: payload };
      return {
        ...state,
        errors: errors,
        loading: false,
        showModalLogout: false
      };
    },
  },


  //definindo os effects
  effects: (dispatch) => ({


    //aciona a ação de buscar os MinhaConsultorias
    fetchListaMinhaConsultoria() {
      dispatch.minhaConsultoriaModel.associarMinhaConsultoriaPending();

      //faz a chamada com o client para buscar todos os MinhaConsultorias
      const atuandoComo =  getClienteAtuacao();

      if (atuandoComo.idCliente) {
        const ambiente = isBackendProd();

        return clientRestAPI.get(`${url}/getPlanosConsultoria/${atuandoComo.idCliente}?ambiente=${ambiente}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.minhaConsultoriaModel.fetchListaMinhaConsultoriaFulfiled(res.data);
        })
      }
    },

    //faz a chamada com o client para salar os dados de um usuario
    associarMinhaConsultoria(dadosConsultoria){
      const atuandoComo =  getClienteAtuacao();

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.minhaConsultoriaModel.associarMinhaConsultoriaPending();
      const associarConsultoria = {
        idPlano : dadosConsultoria.idNovaConsultoria,
        idCliente : atuandoComo.idCliente,
        formaPagamento : dadosConsultoria.formaPagamentoSelecionada
      }

      return clientRestAPI.post(`${url}/associarMinhaConsultoria`, associarConsultoria)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.minhaConsultoriaModel.associarMinhaConsultoriaFulfilled(res.data);
        })
        .catch(err => {
          this.fetchListaMinhaConsultoria();
          dispatch.minhaConsultoriaModel.associarMinhaConsultoriaRejected(err.response.data);
        });
    },

    cancelarMinhaConsultoria(consultoriaSelecionada) {
      const usuarioAutenticado = getUsuarioAutenticado();
      const cliente = usuarioAutenticado.cliente;
      dispatch.minhaConsultoriaModel.cancelarMinhaConsultoriaPending();

      const cancelarMinhaConsultoria = {
        idPlano : consultoriaSelecionada.idPlano,
        idCliente :  cliente.idCliente
      }

      return clientRestAPI.post(`${url}/cancelarMinhaConsultoria`, cancelarMinhaConsultoria)
      .then(res =>{
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.minhaConsultoriaModel.associarMinhaConsultoriaFulfilled(res.data);
      })
      .catch(err => {
        this.fetchListaMinhaConsultoria();
        dispatch.minhaConsultoriaModel.cancelarMinhaConsultoriaRejected(err.response.data);
      });
    },


  })
};

export default minhaConsultoriaModel;
