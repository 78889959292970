//importanto o model de contato
import clienteModel from "./clienteModel";
import minhaEmpresaModel from "./minhaEmpresaModel";
import contaBancariaModel from "./contaBancariaModel";
import contadorModel from "./contadorModel";
import escrituracaoLoteModel from "./escrituracaoLoteModel";
import escrituracaoModel from "./escrituracaoModel";
import contribuinteModel from "./contribuinteModel";
import xmlModel from "./xmlModel";

import planoContaModel from "./planoContaModel";
import usuarioModel from "./usuarioModel";
import inscricaoEstadualMunicipalModel from "./inscricaoEstadualMunicipalModel";
import clienteFormaPagtoModel from "./clienteFormaPagtoModel";
import ufModel from "./ufModel";
import municipioModel from "./municipioModel";
import paisModel from "./paisModel";
import bancoModel from "./bancoModel";
import terceiroModel from "./terceiroModel";
import assinaturasModel from "./assinaturasModel"
import consultoriasModel from "./consultoriasModel"
import minhaAssinaturaModel from "./minhaAssinaturaModel"
import minhaConsultoriaModel from "./minhaConsultoriaModel"
import tipoComunicacaoModel from "./tipoComunicacaoModel"
import comunicacaoModel from "./comunicacaoModel";
import nfeModel from "./nfeModel";
import menuModel from "./menuModel"

//exportano todos os models importados (nesse caso, apenas um)
export default {

  clienteModel,
  escrituracaoLoteModel,
  escrituracaoModel,
  xmlModel,
  contribuinteModel,
  planoContaModel,
  contadorModel,
  terceiroModel,
  contaBancariaModel,
  inscricaoEstadualMunicipalModel,
  clienteFormaPagtoModel,
  usuarioModel,
  municipioModel,
  ufModel,
  paisModel,
  bancoModel,
  assinaturasModel,
  consultoriasModel,
  minhaEmpresaModel,
  minhaAssinaturaModel,
  minhaConsultoriaModel,
  tipoComunicacaoModel,
  comunicacaoModel,
  nfeModel,
  menuModel,
};
