//importando o client pra ser utilizado
import {clientRestAPI} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";
//import inscricaoEstadualMunicipalModel from "./inscricaoEstadualMunicipalModel";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/terceiro";


function calculaTotalPermitido(listaTerceiro, totalParticipacao, idTerceiro) {
  let retTotal = "0";
  let totalListaTerceiro = "0";
  if (listaTerceiro.length > 0) {
    totalListaTerceiro = listaTerceiro.reduce((acumulador, valorAtual) => {
      const ac = parseFloat(acumulador.toString().replace(",", ".")).toFixed(2);
      let va = "0";
      if (valorAtual.idTerceiro !== idTerceiro) {
        va = parseFloat(valorAtual.participacao.toString().replace(",", ".")).toFixed(2);
      }
      return parseFloat(ac) + parseFloat(va);
    }, 0);
  }
  retTotal = (100 - (parseFloat(totalListaTerceiro.toString().replace(",", ".")) + parseFloat(totalParticipacao.toString().replace(",", "."))));
  return parseFloat(isNaN(retTotal) ? "0" : retTotal).toFixed(2).toString();
};

const terceiroModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaTerceiro: [],
    terceiro: {nomeCartao: "",},
    loading: false,
    errors: {},
    redirect: false,
    totalPermitido: "0"
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) => {
      return {
        ...state,
        redirect: true,
      };
    },

    //atualiza a lista de terceiros  da aplicação caso os terceiros tenham sido obtidos da API com sucesso
    fetchListaTerceiroFulfiled: (state, payload) => {
      return {
        ...state,
        listaTerceiro: payload.data || payload,
        redirect: false,
        loading: false
      };
    },
    limpaListaTerceiroFulfiled: (state) => {
      return {
        ...state,
        listaTerceiro: [],
        redirect: false,
      };
    },

    setTotalPermitido: (state, valorAtualizar) => {
      return {
        ...state,
        totalPermitido: valorAtualizar,
      };
    },

    //Deixa a aplicação pronta para receber um novo terceiro no formulário
    newTerceiro: (state) => {
      return {
        ...state,
        terceiro: {nomeCartao: "", participacao: "0"},
      };
    },

    //atualiza a lista de terceiros  da aplicação caso o terceiro tenha sido salvo com sucesso
    saveTerceiroFulfilledTemp: (state, terceiro) => {
      if (state.listaTerceiro.length > 0 && state.listaTerceiro[state.listaTerceiro.length - 1].idTerceiro < 0) { // Negativo
        terceiro.idTerceiro = state.listaTerceiro[state.listaTerceiro.length - 1].idTerceiro - 1;
      } else {
        terceiro.idTerceiro = -1;
      }

      return {
        ...state,
        listaTerceiro: [...state.listaTerceiro, terceiro],
        errors: {},
        loading: false,
        redirect: false,
      };
    },

    saveTerceiroFulfilled: (state, payload) => {
      return {
        ...state,
        listaTerceiro: [...state.listaTerceiro, payload],
        errors: {},
        loading: false,
        redirect: false,
      };
    },
    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveTerceiroPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar terceiro apresente algum problema
    saveTerceiroRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error("Erro ao gravar terceiro! " + payload.message);
      const errors = {global: payload.message};
      return {
        ...state,
        errors: errors,
        loading: false,
      };
    },

    //altera o estado da aplicação no caso de a busca de um terceiro tenha dado certo
    fetchTerceiroFulfiled: (state, payload) => {
      const terceiro = payload;
      const totalPermitido = calculaTotalPermitido(state.listaTerceiro, payload.inscricaoEstadualMunicipal.participacao, payload.idTerceiro);
      terceiro.participacao = terceiro.participacao.toString().replace('.', ',');

      return {
        ...state,
        terceiro,
        errors: {},
        loading: false,
        redirect: false,
        totalPermitido,
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateTerceiroFulfiled: (state, terceiro) => {
      return {
        ...state,
        listaTerceiro: state.listaTerceiro.map((item) =>
          item.idTerceiro === terceiro.idTerceiro ? terceiro : item
        ),
        errors: {},
        loading: false,
        redirect: false,
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar terceiro apresente algum problema
    updateTerceirotRejected: (state, payload) => {
      toast.error("Erro ao  atualzar terceiro! " + payload.message);
      const errors = {global: payload.message};
      return {
        ...state,
        errors: errors,
        loading: false,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateTerceiroPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchTerceiroPending: (state) => {
      return {
        ...state,
        loading: true,
        terceiro: {nomeCartao: "",},
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    deleteTerceiroPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    deleteTerceiroRejected: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    //atualiza a lista de terceiro da aplicação caso o terceiro tenha sido deletado da API com sucesso
    deleteTerceiroFulfiled: (state, payload) => {
      const idTerceiro = payload;
      return {
        ...state,
        listaTerceiro: state.listaTerceiro.filter(
          (item) => item.idTerceiro !== idTerceiro
        ),
        loading: false,
      };
    },

    baixarTerceiroFulfiled: (state, payload) => {
      toast.success('Terceiro Baixado com Sucesso!');
      return {
        ...state,
        listaTerceiro: state.listaTerceiro.map(terceiro =>
          (payload && terceiro.id === payload.id) ? payload : terceiro
        ),
        loading: false,
      };
    },

  },

  //definindo os effects
  effects: (dispatch) => ({
    //aciona a ação de buscar os terceiros

    fetchListaTerceiro(id) {
      //faz a chamada com o client para buscar todos os terceiros
      dispatch.terceiroModel.limpaListaTerceiroFulfiled();

      if (id) {
        const urlGetAll = `${url}/all/${id}`;
        return clientRestAPI.get(urlGetAll).then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.terceiroModel.fetchListaTerceiroFulfiled(res.data);
        });
      } else {
        dispatch.terceiroModel.limpaListaTerceiroFulfiled();
      }
    },

    //faz a chamada com o clientRestAPI para buscar um terceiro, por meio do id
    fetchTerceiro(terceiro) {
      dispatch.terceiroModel.fetchTerceiroPending();
      return dispatch.terceiroModel.fetchTerceiroFulfiled(terceiro);
    },

    //faz a chamada com o client para atualizar os dados de um terceiro
    updateTerceiroMemoria(terceiro) {
      dispatch.terceiroModel.updateTerceiroPending();
      dispatch.terceiroModel.updateTerceiroFulfiled(terceiro);
    },

    //faz a chamada com o client para salar os dados de um terceiro
    saveTerceiroMemoria(terceiro) {
      dispatch.terceiroModel.saveTerceiroPending();
      dispatch.terceiroModel.saveTerceiroFulfilledTemp(terceiro);
    },

    //faz a chamada com o client para apagar um terceiro
    deleteTerceiro(id) {

      dispatch.terceiroModel.deleteTerceiroPending();

      if (id >= 0) {
        return clientRestAPI
          .delete(`${url}/${id}`)
          .then((res) => {
            //caso tudo ocorra bem, o reducer abaixo vai ser acionado
            dispatch.terceiroModel.deleteTerceiroFulfiled(id);
          })
          .catch((err) => {
            toast.error("Existem registros associados a este terceiro ");
            dispatch.terceiroModel.deleteTerceiroRejected();
          });
      } else {
        dispatch.terceiroModel.deleteTerceiroFulfiled(id);
      }
    },

    baixarTerceiro(id) {

      ConsoleLog("[Model] Terceiro (baixarTerceiro)");

      dispatch.terceiroModel.deleteTerceiroPending();

      return clientRestAPI.put(`${url}/baixar/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.terceiroModel.baixarTerceiroFulfiled(
            res.data
          );
        }).catch(err => {
          toast.error('Não foi possível baixar terceiro');
          dispatch.terceiroModel.deleteTerceiroRejected();
        });
    },
  }),
};

export default terceiroModel;
