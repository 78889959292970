//importando o client pra ser utilizado
import { clientRestAPI } from "../clientRestAPI/clientRestAPI";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/uf";

const ufModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaUf: [],
    listaUfOptionValue: [],
    uf: {},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //atualiza a lista de ufs  da aplicação caso os ufs tenham sido obtidos da API com sucesso
    fetchListaUfFulfiled: (state, payload) => {
      return {
        ...state,
        listaUf: payload.data || payload,
        redirect: false
      };
    },

    fetchListaUfOptionValueFulfiled: (state, payload) => {
      return {
        ...state,
        listaUfOptionValue: payload.data
          ? payload.data.map(function(item) {
              let label = item.sigla;
              return { value: item, label: label };
            })
          : payload.map(function(item) {
              let label = item.sigla;
              return { value: item, label: label };
            }),
        redirect: false
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os ufs
    fetchListaUf(id) {
      //faz a chamada com o client para buscar todos os ufs

      return clientRestAPI.get(`${url}/all/`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.ufModel.fetchListaUfFulfiled(res.data);
      });
    },

    fetchListaUfOptionValue() {
      //faz a chamada com o client para buscar todos os ufs
      return clientRestAPI.get(`${url}/all/`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.ufModel.fetchListaUfOptionValueFulfiled(res.data);
      });
    }
  })
};

export default ufModel;
