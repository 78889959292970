//importando o client pra ser utilizado
import { clientRestAPI } from "../clientRestAPI/clientRestAPI";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/municipio";
       
const municipíoModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaMunicipio: [],
    listaMunicipioOptionValue: [],
    
    municipio: {},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //atualiza a lista de municipios  da aplicação caso os municipios tenham sido obtidos da API com sucesso
    fetchListaMunicipioFulfiled: (state, payload) => {
      return {
        ...state,
        listaMunicipio: payload.data || payload,
        redirect: false
      };
    },

    fetchListaMunicipioOptionValueFulfiled: (state, payload) => {
      return {
        ...state,
        listaMunicipioOptionValue: payload.data
          ? payload.data.map(function(item) {
              let label = item.nomeMunicipio;
              return { value: item, label: label };
            })
          : payload.map(function(item) {
              let label = item.nomeMunicipio;
              return { value: item, label: label };
            }),
        redirect: false
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os municipios
    fetchListaMunicipio(id) {
      //faz a chamada com o client para buscar todos os municipios

      return clientRestAPI.get(`${url}/all/`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.municipioModel.fetchListaMunicipioFulfiled(res.data);
      });
    },

    fetchListaMunicipioOptionValue(filtro) {
      //faz a chamada com o client para buscar todos os municipios

      if ((filtro)&&(filtro!=='')){
        return clientRestAPI.get(`${url}/findByEstado/${filtro}`).then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.municipioModel.fetchListaMunicipioOptionValueFulfiled(res.data);
        });
  
      }
    }
  })
};


export default municipíoModel;
