import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import logo from "../../assets/img/brand/01_LOGO.png";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Input,
  Row
} from "reactstrap";

class LoginPage extends Component {
  state = {
    username: "",
    new_password: "",
    codigo:"",
    mensagemErroLogin: "",
    senhaAlterada:0
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.resetPasswordSubmit = this.resetPasswordSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  

  resetPasswordSubmit(e) {
    e.preventDefault();
    const { username,codigo, new_password } = this.state;
    //  if (!signedIn) {
    this.setState({ isSigningIn: true, isLoading: true });
    Auth.forgotPasswordSubmit(username, codigo, new_password)
      .then(data => {
        this.setState({   mensagemErroLogin: "Senha alterada com sucesso.  Efetue o Login com a nova senha!",senhaAlterada :1 });
      })
      .catch(err => {
        if (err.code === 'ExpiredCodeException') {
          this.setState({ mensagemErroLogin: 'Código expirado! solicite outro código de verificação' });
        }else if (err.code === 'LimitExceededException') {
            this.setState({ mensagemErroLogin: 'Limite de tentativas excedido, favor tente mais tarde' });
        }else if (err.code === 'InvalidPasswordException') {
            this.setState({ mensagemErroLogin: 'A senha deve possuir caracteres maiusculos, numeros e simbolos e mais de 6 caracteres' });


       }else if (err.code === 'InvalidParameterException') {
            this.setState({ mensagemErroLogin: 'A senha deve possuir caracteres maiusculos, numeros e simbolos e mais de 6 caracteres' });
             
        } else {
          this.setState({ mensagemErroLogin: err.message })
        }
        this.setState({ senhaAlterada: 0 })
         
      });
    //}
  }

    

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4" style={{ height: "320px" }}>
                  <Col className="text-center">
                    <img src={logo} alt=""></img>
                  </Col>
                  <CardBody>
                    <form onSubmit={this.resetPasswordSubmit}>
                    <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="login"
                          autoComplete="username"
                          name="username"
                          id="usernameSignUpInput"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-key"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Código"
                          type="text"
                          name="codigo"
                          id="codigo"
                          onChange={this.handleChange}
                        />
                      </InputGroup>

                     
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Nova Senha"
                          autoComplete="new-password"
                          name="new_password"
                          id="new_password"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                     
                      <Row>
                        <Col xs="12">
                          <div>{this.state.mensagemErroLogin}</div>
                        </Col>
                      </Row>
                      <Row>
                        <FormGroup row className="my-0">
                         
                          <Col xs="12">
                          {this.state.senhaAlterada === 0 ? (
                            <button
                              type="submit"
                              className="btn btn-primary-cup"
                              width="100px"
                            >
                              Confirmar
                            </button>)
                            :(
                     
                            <Link to="/signin"><Button color="link" className="px-0">
                              Efetuar Login
                            </Button></Link>
                          )}
                          </Col>
                         </FormGroup>
                      </Row>
                    </form>{" "}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clienteAutenticado: state.clienteModel.clienteAutenticado,
  errors: state.clienteModel.errors,
  redirect: state.clienteModel.redirect
});

const mapDispatchToProps = dispatch => ({
  fetchClienteAutenticado: dispatch.clienteModel.fetchClienteAutenticado
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
