import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {Auth, Cache} from "aws-amplify";
import {connect} from "react-redux";
import {logout, registraLogin} from "../../routes/auth";
import logo from "../../assets/img/brand/01_LOGO.png";

/* import CircularProgress from '@material-ui/core/CircularProgress'; */
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner
} from "reactstrap";
import ConsoleLog from "./ConsoleLog";
import {isMobile} from "react-device-detect";

class LoginPage extends Component {


  state = {
    username: "",
    password: "",
    signedIn: false,
    isSigningIn: false,
    isSigningOut: false,
    tokenId: "",
    refreshToken: "",
    mensagemErroLogin: "",
    loading: false,
    disable: false,
  };
  

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trim()
    });
  }

  async getClienteAutenticado(username) {

    ConsoleLog("LoginPage => getClienteAutenticado");

    await this.props.fetchClienteAutenticado(username);

    if(this.props.redirect) {
      logout();
      this.setState({
        isSigningIn: false,
        disable: false,
        loading: false,
        mensagemErroLogin: (this.props.bloqueado && this.props.bloqueado === "S") ? 'Usuário bloqueado' : 'Usuário não encontrado'
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ disable: true});

    var username = this.state.username.trim();
    const { password } = this.state;

    //  if (!signedIn) {
    this.setState({ isSigningIn: true, isLoading: true });
    Auth.signIn({
      username,
      password
    })
      .then(cognitoUser => {
        ConsoleLog("cognitoUser", cognitoUser);
        Auth.currentSession()
          .then(async (userSession) => {
            this.setState({
              signedIn: true,

              isSigningIn: false,
              tokenId: userSession.idToken.jwtToken,
              refreshToken: userSession.refreshToken.token
            });

            registraLogin(cognitoUser);

            await this.getClienteAutenticado(cognitoUser.username);
            window.location.reload(true);
          })
          .catch(err => {
            if (err.message==='Incorrect username or password.'){
              ConsoleLog("Erro Cognito", err);
              this.setState({
                isSigningIn: false,
                disable: false,
                loading: false,
                mensagemErroLogin: 'Usuário ou senha incorretos'
              });

            }else{
              ConsoleLog("Erro Cognito 2", err);
              this.setState({
                isSigningIn: false,
                disable: false,
                loading: false,
                mensagemErroLogin: err.message
              });

            }
            logout();
          });
      })
      .catch(err => {
        ConsoleLog("Erro Cognito 3", err);
        if (err.message==='Incorrect username or password.'){
          this.setState({
            isSigningIn: false,
            disable: false,
            loading: false,
            mensagemErroLogin: 'Usuário ou senha incorretos'
          });

        } else if(err.message === 'User is not confirmed.') {
          ConsoleLog("Erro Cognito 4", err);
          this.setState({
            isSigningIn: false,
            disable: false,
            loading: false,
            mensagemErroLogin: 'Usuário não confirmou o e-mail'
          });
        } else {
          ConsoleLog("Erro Cognito 5", err);
          this.setState({
            isSigningIn: false,
            disable: false,
            loading: false,
            mensagemErroLogin: err.message
          });

        }

        logout();
      });
    //}
  }

  changeAuthStorageConfiguration(e) {
    const shouldRememberUser = e.target.checked;
    if (shouldRememberUser) {
      const localStorageCache = Cache.createInstance({
        keyPrefix: "localStorageAuthCache",
        storage: window.localStorage
      });

      Auth.configure({
        storage: localStorageCache
      });
    } else {
      const sessionStorageCache = Cache.createInstance({
        keyPrefix: "sessionAuthCache",
        storage: window.sessionStorage
      });

      Auth.configure({
        storage: sessionStorageCache
      });
    }
  }

  handleLogout() {
    if (this.state.signedIn) {
      this.setState({ isSigningOut: true });
      Auth.signOut()
        .then(data => {
          this.setState({
            signedIn: false,
            isSigningOut: false,
            disable: false,
            loading: false,
            tokenId: "",
            refreshToken: ""
          });
        })
        .catch(err => {
          this.setState({ isSigningOut: false,  });
        });
    }
  }

  componentDidMount() {
    this.setState({ isSigningIn: true });
    Auth.currentSession()
      .then(userSession => {
        this.setState({
          signedIn: true,
          disable: false,
          loading: false,
          isSigningIn: false,
          tokenId: userSession.idToken.jwtToken,
          refreshToken: userSession.refreshToken.token
        });
      })
      .catch(err => {
        this.setState({ isSigningIn: false });
      });
  }

  render() {

    return (
      <div>
              <div className="app flex-row align-items-center">
                <Container>
                  <Row className="justify-content-center">
                    <Col md="8">
                      <CardGroup>
                        <Card className="p-4" style={{ height: "320px" }}>


                          <Col className="text-center">
                            <img src={logo} alt=""></img>
                          </Col>
                          <CardBody>

                            <form onSubmit={this.handleSubmit}>
                              <h3 className="text-center">Faça login em sua conta</h3>
                              <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="username"
                                  id="usernameSignInInput"
                                  placeholder="Email/Login"
                                  required
                                  onChange={this.handleChange}
                                  disabled={this.state.disable}
                                />
                              </InputGroup>
                              <InputGroup className="mb-4">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-lock"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  className="form-control"
                                  type="password"
                                  name="password"
                                  id="passwordSignInInput"
                                  placeholder="Senha"
                                  onChange={this.handleChange}
                                  disabled={this.state.disable}
                                />
                              </InputGroup>
                              <Row>
                                <Col xs="12">
                                  <div><p style={{color: 'red'}}>{this.state.mensagemErroLogin}</p></div>
                                </Col>
                              </Row>
                              <Row>
                                {/* <FormGroup row className="my-0" style={{border: "1px solid blue"}}>  */}

                                  <Col xs="5" className="text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary-cup"
                                      width="100px"
                                      onClick={() => this.setState({loading: true}) }
                                      disabled={this.state.disable}
                                    >

                                      {/* {this.state.loading ? <CircularProgress size={10}/> : '' } */}
                                      {this.state.loading ?
                                        <>
                                          <Spinner size="sm" color="light" />
                                          &nbsp;Aguarde...&nbsp;
                                        </>
                                        :
                                        <>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entrar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      }
                                    </button>

                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="7" className="text-center">
                                    <Link to="/forgot"><Button color="link" className="px-0">
                                      Esqueci minha senha
                                    </Button></Link>
                                  </Col>
                              </Row>
                              {
                                isMobile ?
                                <Fragment>
                                  <Row>
                                    <Col xs="12" className="text-center">&nbsp;</Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12" className="text-center">
                                      <small style={{color: 'red'}}>Por favor efetuar cadastro via computador ou notebook.</small>
                                    </Col>
                                  </Row>
                                </Fragment>
                                :
                                null
                              }
                            </form>{" "}

                          </CardBody>

                        </Card>

                          <Card
                            className="text-white bg-orange py-5 d-md-down-none"
                            style={{ height: "320px" }}
                          >
                            <CardBody className="text-center">
                              <div>
                                <h2>Contratar</h2>
                                <p>
                                  Contrate nossos serviços para escrituração de livro
                                  caixa rural facilitando suas declarações ou de seus
                                  clientes.
                                </p>

                                <Link to="/register">
                                  <Button
                                    className="btn btn-primary-cup"
                                    active
                                    tabIndex={-1}
                                  >
                                    Registrar agora
                                  </Button>
                                </Link>
                                <h1> </h1>
                                <h4>* Teste 7 dias grátis.</h4>
                              </div>
                            </CardBody>
                          </Card>
                      </CardGroup>
                    </Col>
                  </Row>
                </Container>
              </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clienteAutenticado: state.clienteModel.clienteAutenticado,
  errors: state.clienteModel.errors,
  redirect: state.clienteModel.redirect,
  loading: state.clienteModel.loading,
  bloqueado: state.clienteModel.bloqueado,
});

const mapDispatchToProps = dispatch => ({
  fetchClienteAutenticado: dispatch.clienteModel.fetchClienteAutenticado,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
