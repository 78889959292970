//importando o client pra ser utilizado
import {clientRestAPI} from '../clientRestAPI/clientRestAPI';
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";

//definindo a rota a ser acrescentada na rota base definida no client
const url = '/contador';

const contadorModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaContador: [],
    contador: {nomeCartao: ""},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) => {
      return {
        ...state,
        redirect: true,
        loading: false,
      }
    },

    //atualiza a lista de contadors  da aplicação caso os contadors tenham sido obtidos da API com sucesso
    fetchListaContadorFulfiled: (state, payload) => {
      return {
        ...state,
        listaContador: payload.data || payload,
        redirect: false
      };
    },

    limpaListaContadorFulfiled: (state, payload) => {
      return {
        ...state,
        listaContador: [],
        redirect: false,
        loading: false,
      };
    },

    //Deixa a aplicação pronta para receber um novo contador no formulário
    newContador: (state) => {
      return {
        ...state,
        contador: {},
        loading: false,
      };
    },

    //atualiza a lista de contadors  da aplicação caso o contador tenha sido salvo com sucesso
    saveContadorFulfilled: (state, payload) => {
      toast.success("Contador criado com sucesso!");
      return {
        ...state,
        listaContador: [...state.listaContador, payload],
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveContadorPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar contador apresente algum problema
    saveContadorRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },


    //altera o estado da aplicação no caso de a busca de um contador tenha dado certo
    fetchContadorFulfiled: (state, payload) => {
      return {
        ...state,
        contador: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateContadorFulfiled: (state, payload) => {
      toast.success("Contador atualizado com sucesso!");
      const contador = payload;
      return {
        ...state,
        listaContador: state.listaContador.map(item => item.id === contador.id ? contador : item),
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar contador apresente algum problema
    updateContadortRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateContadorPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchContadorPending: (state) => {
      return {
        ...state,
        loading: true,
        contador: {nome: ""}
      };
    },

    //atualiza a lista de contador da aplicação caso o contador tenha sido deletado da API com sucesso
    deleteContadorFulfiled: (state, payload) => {
      const id = payload.id;
      toast.success("Contador deletado com sucesso!");
      return {
        ...state,
        listaContador: state.listaContador.filter(item => item.id !== id),
        loading: false,
      };
    },

    deleteContadorPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    deleteContadorRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

    baixarContadorFulfiled: (state, payload) => {
      toast.success('Contador Baixado com Sucesso!');
      return {
        ...state,
        listaContador: state.listaContador.map(contador =>
          (payload && contador.id === payload.id) ? payload : contador
        ),
        loading: false,
      };
    },

    baixarContadorRejected: (state) => {
      toast.error('Não foi possível baixar contador');
      return {
        ...state,
        loading: false,
      };
    },
  },


  //definindo os effects
  effects: (dispatch) => ({
    //aciona a ação de buscar os contadors
    fetchListaContador(id) {
      //faz a chamada com o client para buscar todos os contadors

      if (id) {
        const urlGetAll = `${url}/all/${id}`;
        return clientRestAPI.get(urlGetAll)
          .then(res => {
            //caso tudo ocorra bem, o reducer abaixo vai ser acionado
            dispatch.contadorModel.fetchListaContadorFulfiled(res.data);
          })

      } else {
        dispatch.contadorModel.limpaListaContadorFulfiled();
      }

    },

    //faz a chamada com o clientRestAPI para buscar um contador, por meio do id
    fetchContador(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contadorModel.fetchContadorPending();
      return clientRestAPI.get(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contadorModel.fetchContadorFulfiled(res.data);
        }).catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado

        });
    },

    //faz a chamada com o client para atualizar os dados de um contador
    updateContador(contador) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contadorModel.updateContadorPending();
      return clientRestAPI.put(`${url}/${contador.id}`, contador)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contadorModel.updateContadorFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contadorModel.updateContadorRejected(err.response.data);
        });
    },

    //faz a chamada com o client para salar os dados de um contador
    saveContador(contador) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contadorModel.saveContadorPending();
      return clientRestAPI.post(url, contador)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contadorModel.saveContadorFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contadorModel.saveContadorRejected(err.response.data);
        });
    },

    //faz a chamada com o client para apagar um contador
    deleteContador(id) {
      dispatch.contadorModel.deleteContadorPending();

      return clientRestAPI.delete(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contadorModel.deleteContadorFulfiled(res.data);
          dispatch.contadorModel.fetchListaContador(id);
        })
        .catch((err) => {
          dispatch.contadorModel.deleteContadorRejected(err.response.data);
        });
    },

    baixarContador(id) {

      ConsoleLog("[Model] Contador (baixarContador)");

      dispatch.contadorModel.deleteContadorPending();

      return clientRestAPI.put(`${url}/baixar/${id}`)
        .then(res => {
          dispatch.contadorModel.baixarContadorFulfiled(res.data);
        }).catch(err => {
          dispatch.contadorModel.baixarContadorRejected();
        });
    },
  })
};

export default contadorModel;
