import React, {Component} from 'react';

// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {Provider} from 'react-redux';
import store from './store/index';
import Routes from "./routes/routes";
import Amplify from 'aws-amplify';
import amplify_config from './amplify-config';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConsoleLog from "./pages/commons/ConsoleLog";

// Pages

Amplify.configure(amplify_config);

class App extends Component {

  render() {
    ConsoleLog("REACT_APP_DEV ", process.env.REACT_APP_DEV);
    return (
      <Provider store={store}>
        <ToastContainer />
        <Routes />
      </Provider>
    );
  }
}

export default App;

