import React from "react";

const DashboardContribuinte = React.lazy(() =>
  import("../pages/DashboardContribuinte/DashboardContribuinte")
);
const Home = React.lazy(() =>
  import("../pages/home/home")
);
const planoContaListPage = React.lazy(() =>
  import("../pages/planoConta/planoConta-list-page")
);
const planoContaFormPage = React.lazy(() =>
  import("../pages/planoConta/planoConta-form-page")
);
const contribuinteListPage = React.lazy(() =>
  import("../pages/contribuinte/contribuinte-list-page")
);
const contribuinteFormPage = React.lazy(() =>
  import("../pages/contribuinte/contribuinte-form-page")
);
const assinaturasListPage = React.lazy(() =>
  import("../pages/assinaturas/assinaturas-list-page")
);
const assinaturasFormPage = React.lazy(() =>
  import("../pages/assinaturas/assinaturas-form-page")
);
const consultoriasListPage = React.lazy(() =>
  import("../pages/consultorias/consultorias-list-page")
);
const consultoriasFormPage = React.lazy(() =>
  import("../pages/consultorias/consultorias-form-page")
);
const EscrituracaoListPage = React.lazy(() =>
  import("../pages/escrituracao/escrituracao-list-page")
);
const EscrituracaoLoteListPage = React.lazy(() =>
  import("../pages/escrituracaoLote/escrituracao-lote-list-page")
);
const RelatorioEscrituracaoSimplificadoPage = React.lazy(() =>
  import("../pages/relatorios/relatorio-escrituracao-simplificado-page")
);
const RelatorioEscrituracaoCompletoPage = React.lazy(() =>
  import("../pages/relatorios/relatorio-escrituracao-completo-page")
);
const RelatorioEscrituracaoCompletoJasperPage = React.lazy(() =>
  import("../pages/relatorios/relatorio-escrituracao-completo-jasper-page")
);
const EscrituracaoImportacaoPage = React.lazy(() =>
  import("../pages/escrituracao/escrituracao-importacao-page")
);
const CadastrarTerceirosPage = React.lazy(() =>
  import("../pages/contribuinte/cadastro-terceiro-form-page")
);
const XmlImportacaoPage = React.lazy(() =>
  import("../pages/xml/xml-importacao-page")
);
const EscrituracaoFormPage = React.lazy(() =>
  import("../pages/escrituracao/escrituracao-form-page")
);
const EscrituracaoLoteFormPage = React.lazy(() =>
  import("../pages/escrituracaoLote/escrituracao-lote-form-page")
);
const UsuarioListPage = React.lazy(() =>
  import("../pages/usuario/usuario-list-page")
);
const UsuarioFormPage = React.lazy(() =>
  import("../pages/usuario/usuario-form-page")
);
const ClienteListPage = React.lazy(() =>
  import("../pages/cliente/cliente-list-page")
);
const ClienteAtuacaoListPage = React.lazy(() =>
  import("../pages/clienteAtuacao/cliente-atuacao-list-page")
);
const NovosUsuariosListPage = React.lazy(() =>
  import("../pages/novosUsuarios/novos-usuarios-list-page")
);
const ClienteFormPage = React.lazy(() =>
  import("../pages/cliente/cliente-form-page")
);
const MinhaEmpresaFormPage = React.lazy(() =>
  import("../pages/minha-empresa/minha-empresa-form-page")
);
const ComparaPlanosFormPage = React.lazy(() =>
  import("../pages/comparaAssinaturas/compara-assinaturas-list-page")
);
const MinhaAssinaturaListPage = React.lazy(() =>
  import("../pages/minha-assinatura/minha-assinatura-list-page")
);
const MinhaConsultoriaListPage = React.lazy(() =>
  import("../pages/minha-consultoria/minha-consultoria-list-page")
);
const TipoComunicacaoListPage = React.lazy(() =>
  import("../pages/tipoComunicacao/tipo-comunicacao-list-page")
);
const TipoComunicacaoFormPage = React.lazy(() =>
  import("../pages/tipoComunicacao/tipo-comunicacao-form-page")
);
const ComunicacaoListPage = React.lazy(() =>
  import("../pages/comunicacao/comunicacao-list-page")
);
const ComunicacaoFormPage = React.lazy(() =>
  import("../pages/comunicacao/comunicacao-form-page")
);
const NFeImportacaoPage = React.lazy(() =>
  import("../pages/nfe/nfe-importacao-page.js")
);
const UsuariosListPage = React.lazy(() =>
  import("../pages/usuario/usuarios-list-page")
);
const MenuListPage = React.lazy(() =>
  import("../pages/menu-lista/menu-list-page")
);

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
  },
  {
    path: "/pages/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/pages/dashboardContribuinte",
    name: "Dashboard Contribuinte",
    component: DashboardContribuinte,
  },
  {
    path: "/pages/contribuinte",
    exact: true,
    name: "Cadastro Contribuinte",
    component: contribuinteListPage,
  },
  {
    path: "/pages/contribuinte/new",
    exact: true,
    name: "Novo Contribuinte",
    component: contribuinteFormPage,
  },
  {
    path: "/pages/contribuinte/edit/:id",
    exact: true,
    name: "Editar Contribuinte",
    component: contribuinteFormPage,
  },
  {
    path: "/pages/assinaturas",
    exact: true,
    name: "Cadastro Assinaturas",
    component: assinaturasListPage,
  },
  {
    path: "/pages/assinaturas/new",
    exact: true,
    name: "Novo Plano Assinatura",
    component: assinaturasFormPage,
  },
  {
    path: "/pages/assinaturas/edit/:id",
    exact: true,
    name: "Editar Assinatura",
    component: assinaturasFormPage,
  },
  {
    path: "/pages/consultorias",
    exact: true,
    name: "Cadastro Consultorias",
    component: consultoriasListPage,
  },
  {
    path: "/pages/consultorias/new",
    exact: true,
    name: "Novo Plano Consultoria",
    component: consultoriasFormPage,
  },
  {
    path: "/pages/consultorias/edit/:id",
    exact: true,
    name: "Editar Consultoria",
    component: consultoriasFormPage,
  },
  {
    path: "/pages/escrituracaoLote",
    exact: true,
    name: "Cadastro Escrituração (Lote)",
    component: EscrituracaoLoteListPage,
  },
  {
    path: "/pages/escrituracao",
    exact: true,
    name: "Cadastro Escrituração",
    component: EscrituracaoListPage,
  },
    {
    path: "/pages/relatorios/simplificado",
    exact: true,
    name: "Relatorio Simplificado",
    component: RelatorioEscrituracaoSimplificadoPage,
  },
  {
    path: "/pages/relatorios/completo",
    exact: true,
    name: "Relatorio Completo",
    component: RelatorioEscrituracaoCompletoPage,
  },
  {
    path: "/pages/relatorios/lcpdr/pdf",
    exact: true,
    name: "Gerar LCDPR em PDF",
    component: RelatorioEscrituracaoCompletoJasperPage,
  },
  {
    path: "/pages/importar/escrituracao",
    exact: true,
    name: "Importar Escrituração",
    component: EscrituracaoImportacaoPage,
  },
  {
    path: "/pages/importar/nfe",
    exact: true,
    name: "Importar NFe",
    component: NFeImportacaoPage,
  },
  {
    path: "/pages/cadastrar/terceiro",
    exact: true,
    name: "Cadastrar Terceiros",
    component: CadastrarTerceirosPage,
  },
  {
    path: "/pages/xml/importar",
    exact: true,
    name: "Importar XML",
    component: XmlImportacaoPage,
  },
  {
    path: "/pages/escrituracao/new",
    exact: true,
    name: "Nova Escrituração",
    component: EscrituracaoFormPage,
  },
  {
    path: "/pages/escrituracao/edit/:id",
    exact: true,
    name: "Editar Escrituração",
    component: EscrituracaoFormPage,
  },
  {
    path: "/pages/escrituracaoLote/:mes",
    exact: false,
    name: "Editar Lote Escrituração",
    component: EscrituracaoLoteFormPage,
  },
  {
    path: "/pages/planoConta",
    exact: true,
    name: "Plano de Contas",
    component: planoContaListPage,
  },
  {
    path: "/pages/planoConta/new",
    exact: true,
    name: "Novo Plano de Contas",
    component: planoContaFormPage,
  },
  {
    path: "/pages/planoConta/edit/:id",
    exact: true,
    name: "Editar Plano de Contas",
    component: planoContaFormPage,
  },
  {
    path: "/pages/usuario",
    exact: true,
    name: "Cadastro Usuário",
    component: UsuarioListPage,
  },
  {
    path: "/pages/usuario/new",
    exact: true,
    name: "Novo Usuário",
    component: UsuarioFormPage,
  },
  {
    path: "/pages/usuario/edit/:id",
    exact: true,
    name: "Editar Usuário",
    component: UsuarioFormPage,
  },
  {
    path: "/pages/cliente",
    exact: true,
    name: "Cadastro Cliente",
    component: ClienteListPage,
  },
  {
    path: "/pages/clienteAtuacao",
    exact: true,
    name: "Cliente Atuação",
    component: ClienteAtuacaoListPage,
  },
  {
    path: "/pages/novosUsuarios",
    exact: true,
    name: "Novos Usuários",
    component: NovosUsuariosListPage,
  },

  {
    path: "/pages/cliente/new",
    exact: true,
    name: "Novo cliente",
    component: ClienteFormPage,
  },
  {
    path: "/pages/cliente/edit/:id",
    exact: true,
    name: "Editar Cliente",
    component: ClienteFormPage,
  },
  {
    path: "/pages/minhaEmpresa",
    exact: true,
    name: "Minha Empresa",
    component: MinhaEmpresaFormPage,
  },
  {
    path: "/pages/comparaAssinaturas",
    exact: true,
    name: "Compara Planos",
    component: ComparaPlanosFormPage,
  },
  {
    path: "/pages/minhaAssinatura",
    exact: true,
    name: "Minha Assinatura",
    component: MinhaAssinaturaListPage,
  },
  {
    path: "/pages/minhaConsultoria",
    exact: true,
    name: "Minha Consultoria",
    component: MinhaConsultoriaListPage,
  },
  {
    path: "/pages/tipoComunicacao",
    exact: true,
    name: "Cadastro Tipo Comunicação",
    component: TipoComunicacaoListPage,
  },
  {
    path: "/pages/tipoComunicacao/new",
    exact: true,
    name: "Novo Tipo Comunicação",
    component: TipoComunicacaoFormPage,
  },
  {
    path: "/pages/tipoComunicacao/edit/:id",
    exact: true,
    name: "Editar Tipo Comunicação",
    component: TipoComunicacaoFormPage,
  },
  {
    path: "/pages/comunicacao",
    exact: true,
    name: "Cadastro Comunicação",
    component: ComunicacaoListPage,
  },
  {
    path: "/pages/comunicacao/new",
    exact: true,
    name: "Novo Comunicação",
    component: ComunicacaoFormPage,
  },
  {
    path: "/pages/comunicacao/edit/:id",
    exact: true,
    name: "Editar Comunicação",
    component: ComunicacaoFormPage,
  },
  {
    path: "/pages/usuarios",
    exact: true,
    name: "Usuários",
    component: UsuariosListPage,
  },
  {
    path: "/pages/menu",
    exact: true,
    name: "Gerenciar Menu",
    component: MenuListPage,
  },
];

export default routes;
