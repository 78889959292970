//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from "../clientRestAPI/clientRestAPI";
import {
  getContribuinteHomeAutenticado,
  getUsuarioAutenticado,
  registraContribuinteSelecionado,
  registraUsuarioLimpaContribuinte
} from "../routes/auth";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/contribuinte";

const contribuinteModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaContribuinte: [],
    contribuinte: { nome: "" },
    listaContribuinteHome: [],
    contribuinteHome: { nome: "" },

    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },

    //atualiza a lista de contribuinteHomes  da aplicação caso os contribuinteHomes tenham sido obtidos da API com sucesso
    fetchListaContribuinteHomeFulfiled: (state, payload) => {

      ConsoleLog("constribuinteModel => fetchListaContribuinteHomeFulfiled");

      return {
        ...state,
        listaContribuinteHome: payload.data || payload,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um contribuinteHome tenha dado certo
    fetchContribuinteHomeFulfiled: (state, contribuinteHomeSelecionado) => {
      registraContribuinteSelecionado(contribuinteHomeSelecionado);

      return {
        ...state,
        contribuinteHome: contribuinteHomeSelecionado,
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //atualiza a lista de contribuintes  da aplicação caso os contribuintes tenham sido obtidos da API com sucesso
    fetchListaContribuinteFulfiled: (state, payload) => {
      return {
        ...state,
        listaContribuinte: payload.data || payload,
        redirect: false,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchContribuinteHomePending: state => {
      return {
        ...state,
        loading: true,
        contribuinteHome: { nome: "" }
      };
    },

    //Deixa a aplicação pronta para receber um novo contribuinte no formulário
    newContribuinte: state => {
      return {
        ...state,
        contribuinte: { nome: "" }
      };
    },

    //atualiza a lista de contribuintes da aplicação caso o contribuinte tenha sido salvo com sucesso
    saveContribuinteFulfilled: (state, payload) => {
      const listaContribuinteAtualizada = [...state.listaContribuinte, payload];
      registraContribuinteSelecionado(payload);

      const usuario = getUsuarioAutenticado();
      if (
        !usuario.listaContribuinte ||
        (usuario.listaContribuinte &&
          usuario.listaContribuinte.length === 0)) {
        usuario.listaContribuinte = listaContribuinteAtualizada;
        registraUsuarioLimpaContribuinte(usuario);
      }
      toast.success("Contribuinte criado com sucesso!");
      return {
        ...state,
        contribuinteHome: payload,
        listaContribuinte: listaContribuinteAtualizada,
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveContribuintePending: state => {
      return {
        ...state,
        loading: true
      };
    },
    deleteContribuinteRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload.message };
      if (errors.global.indexOf('Constraint') > 0) {
        toast.error("Erro ao excluir contribuinte. Devem ser excluidos todos os relacionamentos  ");
      } else {
        toast.error("Erro ao excluir contribuinte. " + errors.global);
      }

      return {
        ...state,
        errors: errors,
        loading: false
      };
    },


    //mostra os erros encontrados no caso de a operação de salvar contribuinte apresente algum problema
    saveContribuinteRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload };

      toast.error(errors.global);

      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    fetchContribuinteRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao consultar contribuinte " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um contribuinte tenha dado certo
    fetchContribuinteFulfiled: (state, payload) => {
      return {
        ...state,
        contribuinte: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateContribuinteFulfiled: (state, payload) => {
      const contribuinte = payload;
      toast.success("Contribuinte atualizado com sucesso!");

      return {
        ...state,
        listaContribuinte: state.listaContribuinte.map(item =>
          item.id === contribuinte.id ? contribuinte : item
        ),
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar contribuinte apresente algum problema
    updateContribuintetRejected: (state, payload) => {
      //      const { nome  , email } = payload.errors;
      const errors = { global: payload.message };

      toast.error("Erro ao atualizar contribuinte " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateContribuintePending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchContribuintePending: state => {
      return {
        ...state,
        loading: true,
        contribuinte: { nome: "" }
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchContribuinteAllPending: state => {
          return {
            ...state,
            loading: true,
            listaContribuinte: []
          };
        },

    //atualiza a lista de contribuinte da aplicação caso o contribuinte tenha sido deletado da API com sucesso
    deleteContribuinteFulfiled: (state, payload, idContribuinte) => {

      toast.success("Contribuinte deletado com sucesso!");
      // const id = state.contribuinteHome.id;
      return {
        ...state,
        listaContribuinte: state.listaContribuinte.filter(
          item => item.id !== idContribuinte
        ),
        listaContribuinteHome: state.listaContribuinteHome.filter(item => item.id !== idContribuinte),
        loading: false,

      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //faz a chamada com o clientRestAPI para buscar um contribuinteHome, por meio do id
    selecionarContribuinteHome(contribuinteHome) {
        dispatch.contribuinteModel.fetchContribuinteHomeFulfiled(contribuinteHome);
    },

    fetchListaContribuinteHome(state) {

      ConsoleLog("constribuinteModel => fetchListaContribuinteHome")

      //faz a chamada com o client para buscar todos os contribuinteHomes
      const usuarioAutenticado = getUsuarioAutenticado();

      let lista = [];
      if (usuarioAutenticado && usuarioAutenticado.listaContribuinte) {
        lista = usuarioAutenticado.listaContribuinte;
      }

      return {
        ...state,
        listaContribuinteHome: lista,

        redirect: false
      };
    },

    //faz a chamada com o clientRestAPI para buscar um contribuinteHome, por meio do id
    fetchContribuinteHome(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contribuinteModel.fetchContribuinteHomePending();
      return clientRestAPI.get(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.contribuinteModel.fetchContribuinteHomeFulfiled(res.data);
      });
    },

    //aciona a ação de buscar os contribuintes
    fetchListaContribuinte() {
      const usuario = getUsuarioAutenticado();
      if (usuario && usuario.cliente) {
        dispatch.contribuinteModel.fetchContribuinteAllPending();
        const idCliente = usuario.cliente.idClienteAtuacao;
        //faz a chamada com o client para buscar todos os contribuintes
        return clientRestAPI.get(`${url}/all/${idCliente}`).then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.fetchListaContribuinteFulfiled(res.data);
        });
      }
    },



    //faz a chamada com o clientRestAPI para buscar um contribuinte, por meio do id
    fetchContribuinte(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contribuinteModel.fetchContribuintePending();
      return clientRestAPI
        .get(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.fetchContribuinteFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.fetchContribuinteRejected(
            err.response
          );
        });
    },

    //faz a chamada com o client para atualizar os dados de um contribuinte
    updateContribuinte(contribuinte) {
      const usuario = getUsuarioAutenticado();
      contribuinte.cliente = usuario.clienteSelecionado;
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contribuinteModel.updateContribuintePending();
      return clientRestAPI
        .put(`${url}/${contribuinte.id}`, contribuinte)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.updateContribuinteFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.updateContribuinteRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para salar os dados de um contribuinte
    saveContribuinte(contribuinte) {
      const usuario = getUsuarioAutenticado();
      contribuinte.cliente = usuario.clienteSelecionado;

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.contribuinteModel.saveContribuintePending();

      const ambiente = isBackendProd();
      return clientRestAPI
        .post(`${url}?ambiente=${ambiente}`, contribuinte)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado

          dispatch.minhaAssinaturaModel.fetchListaMinhaAssinatura();

          //
          dispatch.contribuinteModel.saveContribuinteFulfilled(res.data);
          const usuario = getUsuarioAutenticado();
          dispatch.clienteModel.selecionarClienteAtuacao(usuario.clienteSelecionado);

        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.saveContribuinteRejected(
            err.response.data
          );
        });
    },

    deleteContribuinte(idContribuinte) {
      dispatch.contribuinteModel.saveContribuintePending();

      return clientRestAPI.put(`${url}/delete/${idContribuinte}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.deleteContribuinteFulfiled(res.data, idContribuinte);

          const contribuinteHome = getContribuinteHomeAutenticado();
          if(contribuinteHome && (contribuinteHome.id ===  idContribuinte)) {
            const usuario = getUsuarioAutenticado();
            dispatch.clienteModel.selecionarClienteAtuacao(usuario.clienteSelecionado);
          }

        }).catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.contribuinteModel.deleteContribuinteRejected(
            err.response.data);
        });
    },
  })
};

export default contribuinteModel;
