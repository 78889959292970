import { toast } from "react-toastify";
import { clientRestAPI } from "../clientRestAPI/clientRestAPI";

const url = "/menu";

const menu = {
  id: undefined,
  nome: "",
  admin: false,
  ativo: true,
};

const menuModel = {
  state: {
    listaMenu: [],
    menu,
    loading: false,
    errors: {},
    redirect: false,
  },

  reducers: {
    menuPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    fetchListaMenuFulfilled: (state, payload) => {
      console.log("[RGB] fetchListaMenuFulfilled payload", payload);

      return {
        ...state,
        listaMenu: payload,
        redirect: false,
        loading: false,
      };
    },

    fetchListaMenuRejected: (state) => {
      toast.error("Erro ao buscar lista de menus");

      return {
        ...state,
        loading: false,
      };
    },

    fetchUpdateMenuFulfilled: (state, payload) => {
      toast.success("Menu atualizado com sucesso");
      toast.warn("É necessário fazer login novamente para ver as alterações");

      const listaMenu = state.listaMenu.map((menu) => {
        if (menu.id === payload.id) {
          return payload;
        }
        return menu;
      });

      return {
        ...state,
        listaMenu,
        redirect: false,
        loading: false,
      };
    },

    fetchUpdateMenuRejected: (state) => {
      toast.error("Erro ao atualizar menu");

      return {
        ...state,
        loading: false,
      };
    },
  },

  effects: (dispatch) => ({
    fetchListaMenu() {
      console.log("[RGB] fetchListaMenu");
      dispatch.menuModel.menuPending();

      return clientRestAPI
        .get(url)
        .then((res) => {
          console.log("[RGB] fetchListaMenu res.data", res.data);
          dispatch.menuModel.fetchListaMenuFulfilled(res.data);
        })
        .catch((err) => {
          dispatch.menuModel.fetchListaMenuRejected(err);
        });
    },

    fetchUpdateMenu(menu) {
      console.log("[RGB] fetchUpdateMenu menu", menu);
      dispatch.menuModel.menuPending();

      return clientRestAPI
        .put(url, menu)
        .then(() => {
          dispatch.menuModel.fetchUpdateMenuFulfilled(menu);
        })
        .catch(() => {
          dispatch.menuModel.fetchUpdateMenuRejected();
        });
    },
  }),
};

export default menuModel;
