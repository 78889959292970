//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";
import {getClienteAtuacao, getUsuarioAutenticado, registraUsuario} from "../routes/auth";
import ConsoleLog from "../pages/commons/ConsoleLog";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/cliente";

const minhaAssinaturaModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaMinhaAssinatura: [],
    loading: false,
    errors: {},
    redirect: false,
    showModalLogout: false,
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) => {
      return {
        ...state,
        redirect: true,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    pending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    //atualiza a lista de MinhaAssinaturas  da aplicação caso os MinhaAssinaturas tenham sido obtidos da API com sucesso
    fetchListaMinhaAssinaturaFulfiled: (state, payload) => {
      const assinaturaSelecionada = payload.data
        ? payload.data.planoAtual
        : payload.planoAtual;

      const usuario = getUsuarioAutenticado();
      usuario.assinaturaAtual = assinaturaSelecionada;
      registraUsuario(usuario);

      ConsoleLog("fetchListaMinhaAssinaturaFulfiled => assinaturaSelecionada => ", assinaturaSelecionada);

      return {
        ...state,
        assinaturaSelecionada: assinaturaSelecionada,
        listaMinhaAssinatura: payload.data
          ? payload.data.listaPlanos
          : payload.listaPlanos,
        redirect: false,
        loading: false,
      };
    },

    //atualiza a lista de usuarios  da aplicação caso o usuario tenha sido salvo com sucesso
    associarMinhaAssinaturaFulfilled: (state, payload) => {
      toast.success("Assinatura Associada com sucesso!");
      return {
        ...state,
        errors: {},
        loading: false,
        redirect: true,
        showModalLogout: true,
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    associarMinhaAssinaturaPending: (state) => {
      return {
        ...state,
        listaMinhaAssinatura: [],
        assinaturaSelecionada: {},
        loading: true,
        showModalLogout: false,
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar MinhaAssinatura apresente algum problema
    associarMinhaAssinaturaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error(payload);
      const errors = { global: payload };
      return {
        ...state,
        errors: errors,
        loading: false,
        showModalLogout: false,
      };
    },
  },

  //definindo os effects
  effects: (dispatch) => ({
    //aciona a ação de buscar os MinhaAssinaturas
    fetchListaMinhaAssinatura() {
      dispatch.minhaAssinaturaModel.associarMinhaAssinaturaPending();

      //faz a chamada com o client para buscar todos os MinhaAssinaturas
      const atuandoComo =  getClienteAtuacao();

      if (atuandoComo.idCliente) {
        const ambiente = isBackendProd();

        return clientRestAPI
          .get(
            `${url}/getPlanosAssinatura/${atuandoComo.idCliente}?ambiente=${ambiente}`
          )
          .then((res) => {
            ConsoleLog("RETORNO ASSINATURA ============================>", res.data);
            //caso tudo ocorra bem, o reducer abaixo vai ser acionado
            dispatch.minhaAssinaturaModel.fetchListaMinhaAssinaturaFulfiled(
              res.data
            );
          });
      }
    },

    //faz a chamada com o client para salar os dados de um usuario
    associarMinhaAssinatura(dadosAssinatura) {

      const atuandoComo =  getClienteAtuacao();

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.minhaAssinaturaModel.associarMinhaAssinaturaPending();
      const associarAssinatura = {
        idPlano: dadosAssinatura.idNovaAssinatura,
        idCliente: atuandoComo.idCliente,
        formaPagamento: dadosAssinatura.formaPagamentoSelecionada,
      };

      return clientRestAPI
        .post(`${url}/associarMinhaAssinatura`, associarAssinatura)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.minhaAssinaturaModel.associarMinhaAssinaturaFulfilled(
            res.data
          );
        })
        .catch((err) => {
          this.fetchListaMinhaAssinatura();
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.minhaAssinaturaModel.associarMinhaAssinaturaRejected(
            err.response.data
          );
        });
    },
  }),
};

export default minhaAssinaturaModel;
