//importando o client pra ser utilizado
import { clientRestAPI } from "../clientRestAPI/clientRestAPI";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/pais";

const paisModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaPais: [],
    listaPaisOptionValue: [],
    pais: {},
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {
    //atualiza a lista de paiss  da aplicação caso os paiss tenham sido obtidos da API com sucesso
    fetchListaPaisFulfiled: (state, payload) => {
      return {
        ...state,
        listaPais: payload.data || payload,
        redirect: false
      };
    },

    fetchListaPaisOptionValueFulfiled: (state, payload) => {
      return {
        ...state,
        listaPaisOptionValue: payload.data
          ? payload.data.map(function(item) {
              let label = item.nomePais;
              return { value: item, label: label };
            })
          : payload.map(function(item) {
              let label = item.nomePais;
              return { value: item, label: label };
            }),
        redirect: false
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os paiss
    fetchListaPais(id) {
      //faz a chamada com o client para buscar todos os paiss

      return clientRestAPI.get(`${url}/all/`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.paisModel.fetchListaPaisFulfiled(res.data);
      });
    },

    fetchListaPaisOptionValue() {
      //faz a chamada com o client para buscar todos os paiss
      return clientRestAPI.get(`${url}/all/`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.paisModel.fetchListaPaisOptionValueFulfiled(res.data);
      });
    }
  })
};

export default paisModel;
