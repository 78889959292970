import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { isAuth } from "./auth";
// Containers
import DefaultLayout from "../containers/DefaultLayout/DefaultLayout";

// Pages
import LoginPage from "../pages/commons/LoginPage";
import LoginPageAutentica from "../pages/commons/LoginPageAutentica";
import Register from "../pages/commons/Register";
import ForgotPasswordPage from "../pages/commons/ForgotPasswordPage";
import PasswordResetPage from "../pages/commons/PasswordResetPage";
// Pages
//import LoadPage from "../pages/commons/LoadPage";

import PrivateRoute from "./PrivateRoute";

import PublicRoute from "./PublicRoute";

//const loading = () => <LoadPage />;

class Routes extends Component {
  state = {
    isLogged: false
    
  };

  _handler = () => {
    this.props.signOut();
  };
  componentDidMount() {
    const auth = isAuth();
    this.setState({ isLogged: auth });
  }

  render() {
    return (
      
        <HashRouter>
          <Switch>
            <PublicRoute
              restricted={true}
              component={LoginPage}
              path="/signin"
              exact
            />
          

               <PublicRoute
              restricted={true}
              component={ForgotPasswordPage}
              path="/forgot"
              exact
            />

          <PublicRoute
              restricted={true}
              component={LoginPageAutentica}
              path="/authentication/:code/:username/:clientId"
              exact
            />

          <PublicRoute
              restricted={true}
              component={PasswordResetPage}
              path="/reset"
              exact
            />

            <PublicRoute
              restricted={true}
              component={Register}
              path="/register"
              exact
            />
            <PrivateRoute component={DefaultLayout} path="/" exact />
            <PrivateRoute component={DefaultLayout} path="/pages" />
          </Switch>
        </HashRouter>
      
    );
  }
}


const mapStateToProps = state => ({
  
  
});

const mapDispatchToProps = dispatch => ({
  
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
