import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {isAuth} from './auth';

const PrivateRoute = ({component: Component, ...rest}) => {

    const location = useLocation();

    const verificaIds = () => {
        let ids = []
        if(location.state && location.state.ids) {
            ids = location.state.ids;
        }

        return ids;
    }

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isAuth() ?
            <>
                {props.ids = verificaIds()}
                <Component {...props} />
            </>
            :
            <Redirect to="/signin" />
        )} />
    );
};

export default PrivateRoute;
