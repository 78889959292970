import axios from 'axios';


//criando url base para requisiÃ§Ãµes 
//export const urlServidor = 'http://localhost:7700';
export const urlServidor = 'https://backend.lastrosistemas.com.br';
//export const urlServidor = 'http://18.231.54.193:7700';


 


//configurando o typo de requisiÃ§Ã£o e rota base
//configurando o typo de requisição e rota base
export const clientRestAPICep = axios.create({
  //baseURL: urlServidor,
  headers: {
    'Content-Type': 'application/json'
  }
});


