import { clientRestAPI } from "../clientRestAPI/clientRestAPI";
import { toast } from "react-toastify";

const url = "/tipoComunicacao";

const tipoComunicacao = {
  id: "",
  nome: "",
  descricao: "",
  status: "",
  dataCadastro: "",
};

const tipoComunicacaoModel = {
  
  //definindo o estado inicial da aplicação
  state: {
    listaTipoComunicacao: [],
    tipoComunicacao,
    loading: false,
    errors: {},
    redirect: false
  },

  //definindo todos os reducers
  reducers: {

    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },

    globalTipoComunicacaoRejected: (state, payload) => {
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    fetchListTipoComunicacaoPending: state => {
      return {
        ...state,
        listaTipoComunicacao: [],
        loading: true
      };
    },

    fetchListaTipoComunicacaoFulfilled: (state, payload) => {
      return {
        ...state,
        listaTipoComunicacao: payload.data || payload,
        redirect: false,
        loading: false
      };
    },

    fetchTipoComunicacaoPending: state => {
      return {
        ...state,
        loading: true,
        tipoComunicacao,
      };
    },    

    fetchTipoComunicacaoFulfilled: (state, payload) => {

      return {
        ...state,
        tipoComunicacao: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },    

    updateTipoComunicacaoPending: state => {
      return {
        ...state,
        loading: true
      };
    },
     
    updateTipoComunicacaoFulfilled: (state, payload) => {
      const tipoComunicacao = payload;
      toast.success("Tipo comunicação atualizado com sucesso!");
      return {
        ...state,
        listaTipoComunicacao: state.listaTipoComunicacao.map(item =>
          item.id === tipoComunicacao.id ? tipoComunicacao : item
        ),
        errors: {},
        loading: false,
        redirect: true
      };
    },
  },

  //definindo os effects
  effects: dispatch => ({
    
    fetchListaTipoComunicacao() {
      dispatch.tipoComunicacaoModel.fetchListTipoComunicacaoPending();
      return clientRestAPI.get(`${url}/all`).then(res => {
        dispatch.tipoComunicacaoModel.fetchListaTipoComunicacaoFulfilled(res.data);
      });
    },

    fetchTipoComunicacao(id) {
      dispatch.tipoComunicacaoModel.fetchTipoComunicacaoPending();
      return clientRestAPI
        .get(`${url}/${id}`)
        .then(res => {
          dispatch.tipoComunicacaoModel.fetchTipoComunicacaoFulfilled(res.data);
        })
        .catch(err => {
          dispatch.tipoComunicacaoModel.globalTipoComunicacaoRejected(err.response);
        });
    },

    updateTipoComunicacao(tipoComunicacao) {
      dispatch.tipoComunicacaoModel.updateTipoComunicacaoPending();
      return clientRestAPI
        .put(`${url}/${tipoComunicacao.id}`, tipoComunicacao)
        .then(res => {
          dispatch.tipoComunicacaoModel.updateTipoComunicacaoFulfilled(res.data);
        })
        .catch(err => {
          dispatch.tipoComunicacaoModel.globalTipoComunicacaoRejected(err.response.data );
        });
    },
  })
};

export default tipoComunicacaoModel;