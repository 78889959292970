//importando o client pra ser utilizado 
import { clientRestAPI } from '../clientRestAPI/clientRestAPI';
import { getUsuarioAutenticado, getContribuinteHomeAutenticado} from "../routes/auth";
import { toast } from "react-toastify";

//definindo a rota a ser acrescentada na rota base definida no client
const url = '/planoConta';

const planoContaModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaPlanoConta: [],
    planoConta: {nome:""},
    loading: false,
    errors: {},
    redirect: false,
    loadingDelayed: false
  },
  
  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) =>{
      return{
        ...state,
        redirect: true
      }
    },
    
    //atualiza a lista de planoContas  da aplicação caso os planoContas tenham sido obtidos da API com sucesso
    fetchListaPlanoContaFulfiled: (state, payload) => {
      return { 
        ...state,
        listaPlanoConta: payload.data || payload,
        nodes: payload.data || payload,
        redirect: false,
        loading: false,
        loadingDelayed: false
      };
    },
       //atualiza a lista de planoContas  da aplicação caso os planoContas tenham sido obtidos da API com sucesso
       fetchListaPlanoContaTemplateFulfiled: (state, payload) => {
        return { 
          ...state,
          listaPlanoConta: payload.data || payload,
          nodes: payload.data || payload,
          redirect: false
        };
      },

    


    //Deixa a aplicação pronta para receber um novo planoConta no formulário
    newPlanoConta: (state,contaPai) =>{
      let novoPlanoConta ={nome:""};
      if (contaPai){
        novoPlanoConta ={nome:"",idContaPai: contaPai.id,codigoContaPai: contaPai.codigo,        
        nomeContaPai: contaPai.nome};
      }
      return {
        ...state,
        planoConta: novoPlanoConta
      };
    },

    //atualiza a lista de planoContas  da aplicação caso o planoConta tenha sido salvo com sucesso
    savePlanoContaFulfilled: (state, payload) =>{
      return {
        ...state,
        listaPlanoConta: [...state.listaPlanoConta, payload],
        errors:{},
        loading: false,
        redirect: true,
        loadingDelayed: false
      };
    },

        //atualiza a lista de planoContas  da aplicação caso o planoConta tenha sido salvo com sucesso
        savePlanoContaTemplateFulfilled: (state, payload) =>{
          return {
            ...state,
            listaPlanoConta: [...state.listaPlanoConta, payload],
            errors:{},
            loading: false,
            redirect: false,
            loadingDelayed: false
          };
        },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    savePlanoContaPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },

        //altera o estado da aplicação para informar que tem uma operação sendo executada.
        savePlanoContaTemplatePending: (state) => {
          return {
            ...state,
            loading: true,
            loadingDelayed: true
          };
        },

    //mostra os erros encontrados no caso de a operação de salvar planoConta apresente algum problema
    savePlanoContaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error ('Erro ao gravar plano de contas! '+payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false,
        loadingDelayed: false
      };
    },
    
    savePlanoTemplateContaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error ('Erro ao gravar plano de contas! '+payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false,
        loadingDelayed: false
      };
    },

    //mostra os erros encontrados no caso de a operação de alterar planoConta apresente algum problema
    updatePlanoContaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error ('Erro ao alterar plano de contas! ' + payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false,
        loadingDelayed: false
      };
    },
    
    //altera o estado da aplicação no caso de a busca de um planoConta tenha dado certo
    fetchPlanoContaFulfiled: (state, payload) => {
      return {
        ...state,
        planoConta: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updatePlanoContaFulfiled: (state, payload) => {
      const planoConta = payload;
      return {
        ...state,
        listaPlanoConta: state.listaPlanoConta.map(item => item.id === planoConta.id ? planoConta : item),
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar planoConta apresente algum problema
    updatePlanoContatRejected: (state, payload) => {
      toast.error ('Erro ao atualizar plano de contas! '+payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updatePlanoContaPending: (state) => {
      return {
        ...state,
        loading: true,
        loadingDelayed: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchPlanoContaInicialPending: (state) => {
  
      return {
        ...state,
        loading: true,
        loadingDelayed: false,
        planoConta: {nome:""},
        listaPlanoConta: []
      };
    },
    
    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchPlanoContaPending: (state) => {
      
      return {
        ...state,
        loading: true,
        loadingDelayed: false,
        planoConta: {nome:""},
      };
    },

      //altera o estado da aplicação para informar que tem uma operação finalizou.
      fetchPlanoContaFree: (state) => {
        return {
          ...state,
          loading: false,
          planoConta: {nome:""},
        };
      },
    
    //atualiza a lista de planoConta da aplicação caso o planoConta tenha sido deletado da API com sucesso
    deletePlanoContaFulfiled: (state, payload) => {
      const id = payload.id;
      return {
        ...state,
        listaPlanoConta: state.listaPlanoConta.filter(item => item.id !== id)
      };
    },






  },


  //definindo os effects
  effects: (dispatch) => ({



    //aciona a ação de buscar os planoContas
    // afterCrud indica se o fetch foi chamado apos um save, update ou delete
    fetchListaPlanoConta(afterCrud = false) {
      if(!afterCrud) {
        dispatch.planoContaModel.fetchPlanoContaInicialPending();
      }

      //const usuario = getUsuarioAutenticado();
      //const idCliente = usuario.cliente.idCliente; 
      const contribuinte = getContribuinteHomeAutenticado();

      
      if(contribuinte != null)  {
      //faz a chamada com o contribuinte para buscar todos os planoContas
      return clientRestAPI.get(`${url}/all/${contribuinte.id}`)
      //return clientRestAPI.get(`${url}/all/${idCliente}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.planoContaModel.fetchListaPlanoContaFulfiled(res.data);
        })
        .catch(() => dispatch.planoContaModel.fetchPlanoContaFree())
      } 

      return;

    },

   
    //faz a chamada com o clientRestAPI para buscar um planoConta, por meio do id
    fetchPlanoConta(id){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.planoContaModel.fetchPlanoContaPending();
      return clientRestAPI.get(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.planoContaModel.fetchPlanoContaFulfiled(res.data);
        });
    },

    //faz a chamada com o client para atualizar os dados de um planoConta
    updatePlanoConta(planoConta){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      const usuario = getUsuarioAutenticado();
      planoConta.cliente = usuario.cliente; 
      planoConta.contribuinte = getContribuinteHomeAutenticado();
      dispatch.planoContaModel.updatePlanoContaPending();

      return clientRestAPI.put(`${url}/${planoConta.id}`, planoConta)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          //dispatch.planoContaModel.updatePlanoContaFulfiled(res.data);
          dispatch.planoContaModel.fetchListaPlanoConta();
          toast.success("Plano do Conta atualizado com sucesso! ");
        })
        .catch(err =>{
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.planoContaModel.updatePlanoContaRejected(err.response.data);
        });
    },

    //faz a chamada com o client para salar os dados de um planoConta
    savePlanoConta(planoConta){
      dispatch.planoContaModel.savePlanoContaPending();
      
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      const usuario = getUsuarioAutenticado();
      
      planoConta.cliente = usuario.cliente; 
      planoConta.contribuinte = getContribuinteHomeAutenticado();
      
      return clientRestAPI.post(url, planoConta)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          //dispatch.planoContaModel.savePlanoContaFulfilled(res.data);
          dispatch.planoContaModel.fetchListaPlanoConta(true);
          toast.success("Plano do Conta criado com sucesso! ");
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.planoContaModel.savePlanoContaRejected(err.response.data);
        });
    },

    saveContaTemplate(){
      dispatch.planoContaModel.savePlanoContaTemplatePending();

      const usuario = getUsuarioAutenticado();
      const idCliente = usuario.cliente.idCliente; 
      const contribuinte = getContribuinteHomeAutenticado();
      return clientRestAPI.post(`${url}/createContaTemplate/${idCliente}/${contribuinte.id}`)
      .then(res =>{
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        //dispatch.planoContaModel.savePlanoContaTemplateFulfilled(res.data);
        dispatch.planoContaModel.fetchListaPlanoConta(true)
        .then(() => toast.success("Primeiro Plano do Conta criado com sucesso! "))
      })
      .catch(err => {
        //caso ocorra algum problema, o reducer abaixo vai ser acionado
        dispatch.planoContaModel.savePlanoTemplateContaRejected(err.response.data);
      });


    },

    //faz a chamada com o client para apagar um planoConta
    deletePlanoConta(id){
      return clientRestAPI.delete(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.planoContaModel.deletePlanoContaFulfiled(res.data);
        })
    }
  })
};

export default planoContaModel;
