import pt_BR from "date-fns/locale/pt-BR";
import moment from "moment";

export const formataMoeda = (value) => {
  const valorFormatado = new Intl.NumberFormat("pt", {
    style: "currency",
    currency: "BRL",
  }).format(value);
  return valorFormatado;
};

export const formataNumero = (value) => {
  const valorFormatado = new Intl.NumberFormat("pt", {
    style: "decimal",
    maximumFractionDigits: "2",
    minimumFractionDigits: "2",
  }).format(value);
  return valorFormatado;
};

export const formataData = (data) => {
  let dataRetorno = "";
  if (data) {
    dataRetorno = new Date(data).toLocaleDateString(pt_BR);
  }
  return dataRetorno;
};

export const dataAtual =() => {
  return moment().format();
}

export const formataDataMomentLista = (data, format = 'YYYY-MM-DD')  => {
  return moment(data).format(format);
}

export const formataDataMomentListaUtc = (data, format = 'YYYY-MM-DD')  => {
  return moment.utc(data).format(format);
}

export const formataDataMoment = (data, format = 'DD/MM/YYYY')  => {
  return moment.utc(data).format(format);
}

export const formataTelefone = (ddd, telefone) => {
  let textoAjustado = "";
  let textoDDDAjustado = "";

  const numberPattern = /\D/g;
  const d = ddd ? ddd.replace(numberPattern, "") : null;
  const tel = telefone ? telefone.replace(numberPattern, "") : null;

  if (tel) {
    const isCelular = tel.length === 9;

    if (isCelular) {
      const parte1 = tel.slice(0, 5);
      const parte2 = tel.slice(5, 9);
      textoAjustado = `${parte1}-${parte2}`;
    } else {
      const parte1 = tel.slice(0, 4);
      const parte2 = tel.slice(4, 8);
      textoAjustado = `${parte1}-${parte2}`;
    }

    if (d) {
      let textoDdd = d;
      if (d.length >= 3) {
        textoDdd = d.slice(1, 3);
      }
      textoDDDAjustado = `(${textoDdd}) `;
    }
  }

  return `${textoDDDAjustado}${textoAjustado}`;
};

//44.545.454/5454-54
//337.548.548-96
export const formataCpfCnpj = (cpfCnpj) => {
  let textoAjustado = "";

  const numberPattern = /\D/g;
  const doc = cpfCnpj ? cpfCnpj.replace(numberPattern, "") : null;

  if (doc) {
    if (doc.length === 11) {
      // cpf
      textoAjustado = doc.replace(
        /^(\d{3})(\d{3})?(\d{3})?(\d{2})?/,
        "$1.$2.$3-$4"
      );
    } else if (doc.length === 14) {
      // cnpj
      textoAjustado = doc.replace(
        /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
        "$1.$2.$3/$4-$5"
      );
    } else {
      // invalido retorna sem validacao
      textoAjustado = doc;
    }
  }

  return textoAjustado;
};


export const converterFloatBR = (numeroFloat) => {
  return numeroFloat ? parseFloat(numeroFloat.toString().replace(',', '.')).toFixed(2).toString().replace('.', ',') : numeroFloat;
}

export const gerarkeyTag = () => {
  return moment().format("YYYYMMDDHHmmssSSS") + Math.floor(Math.random() * 9999);
}
