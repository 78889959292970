//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from "../clientRestAPI/clientRestAPI";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";
import {atualizarQtdUsuarios} from "../routes/auth";


//definindo a rota a ser acrescentada na rota base definida no client
const url = "/assinatura";

const onlyCallExistePlano = async () => {
  const ambiente = isBackendProd();
  const res = await clientRestAPI.get(`${url}/inicial?ambiente=${ambiente}`);
  return res.data;
};

const assinaturasModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaAssinaturas: [],
    idAssinaturaSelecionada: '',
    assinaturas: { idAssinatura: "", nome: "", planoInicial: "N" },
    loading: false,
    errors: {},
    redirect: false,
    iconePlanoInicial: { existe: false, nome: "" },
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },



    //atualiza a lista de assinaturass  da aplicação caso os assinaturass tenham sido obtidos da API com sucesso
    fetchListaAssinaturasFulfiled: (state, payload) => {
      return {
        ...state,
        listaAssinaturas: payload.data || payload,
        loading: false,
        redirect: false
      };
    },


    newAssinaturaPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //Deixa a aplicação pronta para receber um novo assinaturas no formulário
    newAssinatura: (state, payload) => {
      return {
        ...state,
        loading: false,
        assinatura: { idAssinatura: "", nome: "", planoInicial: "N" },
        iconePlanoInicial: payload
      };
    },

    //atualiza a lista de assinaturass  da aplicação caso o assinaturas tenha sido salvo com sucesso
    saveAssinaturaFulfilled: (state, payload) => {
      const listaAssinaturasAtualizada = [...state.listaAssinaturas, payload];
      toast.success("Assinatura criada com sucesso!");
      return {
        ...state,
        listaAssinaturas: listaAssinaturasAtualizada,
        errors: {},
        loading: false,
        redirect: true
      };
    },

    listAssinaturaPending: state => {
      return {
        ...state,
        listaAssinaturas: [],
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveAssinaturaPending: state => {
      return {
        ...state,
        loading: true,
      };
    },
    deleteAssinaturaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload };
/*       if (errors.global.indexOf('Constraint') > 0) {
        toast.error("Não foi possível excluir assinatura. Devem ser excluidos todos os relacionamentos.");
      } else { */
        toast.error(errors.global);
      /* } */

      return {
        ...state,
        errors: errors,
        loading: false
      };
    },


    //mostra os erros encontrados no caso de a operação de salvar assinatura apresente algum problema
    saveAssinaturaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload };

      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    fetchAssinaturaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //const { nome,  email } = payload.errors;
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um assinatura tenha dado certo
    fetchAssinaturaFulfiled: (state, payload, iconePlanoInicial) => {
      return {
        ...state,
        assinatura: payload,
        errors: {},
        redirect: false,
        iconePlanoInicial: iconePlanoInicial,
        loading: false,
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateAssinaturaFulfiled: (state, payload) => {
      const assinatura = payload;
      toast.success("Assinatura atualizada com sucesso!");

      return {
        ...state,
        listaAssinaturas: state.listaAssinaturas.map(item =>
          item.id === assinatura.id ? assinatura : item
        ),
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar assinaturas apresente algum problema
    updateAssinaturaRejected: (state, payload) => {
      //      const { nome  , email } = payload.errors;
      const errors = { global: payload };

      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateAssinaturaPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchAssinaturaPending: state => {
      return {
        ...state,
        loading: true,
        assinatura: { nome: "" }
      };
    },

    //atualiza a lista de assinatura da aplicação caso o assinatura tenha sido deletado da API com sucesso
    deleteAssinaturaFulfiled: (state, payload, idF) => {
      toast.success("Assinatura deletada com sucesso!");
      const listatemporaria = state.listaAssinaturas.filter(
        (item) => item.idAssinatura !== idF
      );
      return {
        ...state,
        listaAssinaturas: listatemporaria,
        loading: false
      };
    },

    //atualiza a lista de assinatura da aplicação caso o assinatura tenha sido deletado da API com sucesso
    ativarInativarAssinaturaFulfiled: (state, id, ativo) => {
      let msg = ativo === 'S' ? "Ativada" : "Inativada";

      toast.success(`Assinatura ${msg} com sucesso!`);
      const listatemporaria = state.listaAssinaturas.map((item) => {
        if(item.idAssinatura === id) {
          item.ativo = ativo;
        }
        return item;
      })

      return {
        ...state,
        listaAssinaturas: listatemporaria,
        loading: false
      };
    },


    atualizarQtdUsuariosFulfiled: (state) => {
      toast.success("Atualizado com sucesso!");

      return {
        ...state,
        loading: false,
      };
    },

    atualizarQtdUsuariosRejected: (state, payload) => {
      toast.error(payload);
      return {
        ...state,
        loading: false,
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({

    fetchExistePlanoInicial() {
      dispatch.assinaturasModel.newAssinaturaPending();

      onlyCallExistePlano()
        .then(res => {
          let iconePlanoInicial = { existe: false, nome: "" };
          if (res) {
            iconePlanoInicial = {
              existe: true,
              nome: res
            }
          }

          dispatch.assinaturasModel.newAssinatura(iconePlanoInicial);
        });

    },

    //aciona a ação de buscar os assinaturas
    fetchListaAssinaturas() {
      dispatch.assinaturasModel.listAssinaturaPending();

      const ambiente = isBackendProd();
      //faz a chamada com o client para buscar todos os assinaturass
      return clientRestAPI.get(`${url}?ambiente=${ambiente}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.assinaturasModel.fetchListaAssinaturasFulfiled(res.data);
      });

    },



    //faz a chamada com o clientRestAPI para buscar um assinatura, por meio do id
    fetchAssinatura(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.assinaturasModel.fetchAssinaturaPending();

      let iconePlanoInicial = { existe: false, nome: "" };
      return onlyCallExistePlano()
        .then(res => {
          if (res) {
            iconePlanoInicial = {
              existe: true,
              nome: res
            }
          }

          clientRestAPI
            .get(`${url}/${id}`)
            .then(res => {

              if (iconePlanoInicial.existe === true && iconePlanoInicial.nome === res.data.nome) {
                iconePlanoInicial = { existe: false, nome: "" };
              }

              //caso tudo ocorra bem, o reducer abaixo vai ser acionado
              dispatch.assinaturasModel.fetchAssinaturaFulfiled(res.data, iconePlanoInicial);
            })
            .catch(err => {
              //caso ocorra algum problema, o reducer abaixo vai ser acionado
              dispatch.assinaturasModel.fetchAssinaturaRejected(
                err.response
              );
            });
        });

    },

    //faz a chamada com o client para atualizar os dados de um assinatura
    updateAssinatura(assinatura) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.assinaturasModel.updateAssinaturaPending();
      assinatura.ambiente = isBackendProd();

      return clientRestAPI
        .put(`${url}/${assinatura.id}`, assinatura)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.assinaturasModel.updateAssinaturaFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.assinaturasModel.updateAssinaturaRejected(

            err.response.data
          );
        });
    },

    //faz a chamada com o client para salar os dados de um assinatura
    saveAssinatura(assinatura) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.assinaturasModel.saveAssinaturaPending();

      assinatura.ambiente = isBackendProd();
      return clientRestAPI
        .post(url, assinatura)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.assinaturasModel.saveAssinaturaFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.assinaturasModel.saveAssinaturaRejected(
            err.response.data
          );
        });
    },

    //faz a chamada com o client para apagar um assinatura
    deleteAssinatura(id) {
      dispatch.assinaturasModel.saveAssinaturaPending();
      return clientRestAPI.delete(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.assinaturasModel.deleteAssinaturaFulfiled(res.data, id);
      }).catch(err => {
        //caso ocorra algum problema, o reducer abaixo vai ser acionado
        dispatch.assinaturasModel.deleteAssinaturaRejected(
          err.response.data);
      });
    },

    ativarInativarAssinatura(dado) {

      let ativo = dado.ativo === 'S' ? 'N' : 'S';
      dispatch.assinaturasModel.saveAssinaturaPending();
      return clientRestAPI.put(`${url}/${dado.id}/ativo/${ativo}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.assinaturasModel.ativarInativarAssinaturaFulfiled(dado.id, ativo);
      }).catch(err => {
        //caso ocorra algum problema, o reducer abaixo vai ser acionado
        dispatch.assinaturasModel.deleteAssinaturaRejected(
          err.response.data);
      });
    },

    //faz a chamada com o client para apagar um assinatura
    // atualizarQtdUsuarios(id, qtdUsuarios) {
    atualizarQtdUsuarios(val) {
      ConsoleLog("atualizarQtdUsuarios => val = ", val, " | id = ", val.id, " | qtdCliente = ", val.qtdCliente);
      // /* Remover */
      // dispatch.assinaturasModel.saveAssinaturaPending();
      dispatch.minhaAssinaturaModel.pending();

      return clientRestAPI.put(`${url}/${val.id}/${val.qtdCliente}`).then(res => {
        if(res.data.length > 0) {
          dispatch.assinaturasModel.atualizarQtdUsuariosRejected(res.data);
        } else {
          atualizarQtdUsuarios(val);
          dispatch.assinaturasModel.atualizarQtdUsuariosFulfiled();
        }
        dispatch.minhaAssinaturaModel.fetchListaMinhaAssinatura();
      }).catch(err => {
        dispatch.assinaturasModel.atualizarQtdUsuariosRejected("Não foi possível atualizar o usuário!");
      });
    }


  })
};

export default assinaturasModel;
