//importando o client pra ser utilizado 
import { clientRestAPI } from '../clientRestAPI/clientRestAPI';
import { toast } from "react-toastify";

//definindo a rota a ser acrescentada na rota base definida no client
const url = '/clienteFormaPagto';

const clienteFormaPagtoModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaClienteFormaPagto: [],
    clienteFormaPagto: {nomeCartao:""},
    loading: false,
    errors: {},
    redirect: false
  },
  
  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: (state) =>{
      return{
        ...state,
        redirect: true
      }
    },
    
    //atualiza a lista de clienteFormaPagtos  da aplicação caso os clienteFormaPagtos tenham sido obtidos da API com sucesso
    fetchListaClienteFormaPagtoFulfiled: (state, payload) => {
      return { 
        ...state,
        listaClienteFormaPagto: payload.data || payload,
        redirect: false
      };
    },

    //Deixa a aplicação pronta para receber um novo clienteFormaPagto no formulário
    newClienteFormaPagto: (state) =>{
      return {
        ...state,
        clienteFormaPagto: {nomeCartao:"nomecartaoxlnovo"}
      };
    },

    //atualiza a lista de clienteFormaPagtos  da aplicação caso o clienteFormaPagto tenha sido salvo com sucesso
    saveClienteFormaPagtoFulfilled: (state, payload) =>{
      return {
        ...state,
        listaClienteFormaPagto: [...state.listaClienteFormaPagto, payload],
        errors:{},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveClienteFormaPagtoPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar clienteFormaPagto apresente algum problema
    saveClienteFormaPagtoRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      toast.error ('Erro ao gravar Forma de pagamento! '+payload.message);
      const errors = { global: payload.message };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },
    
    
    //altera o estado da aplicação no caso de a busca de um clienteFormaPagto tenha dado certo
    fetchClienteFormaPagtoFulfiled: (state, payload) => {
      return {
        ...state,
        clienteFormaPagto: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateClienteFormaPagtoFulfiled: (state, payload) => {
      const clienteFormaPagto = payload;
      return {
        ...state,
        listaClienteFormaPagto: state.listaClienteFormaPagto.map(item => item.id === clienteFormaPagto.id ? clienteFormaPagto : item),
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //mostra os erros encontrados no caso de a operação de atualizar clienteFormaPagto apresente algum problema
    updateClienteFormaPagtotRejected: (state, payload) => {
      toast.error ('Erro ao atualizar forma de pagamento! '+payload.message);
      const errors = { global: payload.message  };
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateClienteFormaPagtoPending: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    
    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchClienteFormaPagtoPending: (state) => {
      return {
        ...state,
        loading: true,
        clienteFormaPagto: {nome:""}
      };
    },
    
    //atualiza a lista de clienteFormaPagto da aplicação caso o clienteFormaPagto tenha sido deletado da API com sucesso
    deleteClienteFormaPagtoFulfiled: (state, payload) => {
      const id = payload.id;
      return {
        ...state,
        listaClienteFormaPagto: state.listaClienteFormaPagto.filter(item => item.id !== id)
      };
    }




  },


  //definindo os effects
  effects: (dispatch) => ({
    //aciona a ação de buscar os clienteFormaPagtos
    fetchListaClienteFormaPagto() {
      //faz a chamada com o client para buscar todos os clienteFormaPagtos
      return clientRestAPI.get(url)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.fetchListaClienteFormaPagtoFulfiled(res.data);
        })

    },

    //faz a chamada com o clientRestAPI para buscar um clienteFormaPagto, por meio do id
    fetchClienteFormaPagto(id){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.clienteFormaPagtoModel.fetchClienteFormaPagtoPending();
      return clientRestAPI.get(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.fetchClienteFormaPagtoFulfiled(res.data);
        });
    },

    //faz a chamada com o client para atualizar os dados de um clienteFormaPagto
    updateClienteFormaPagto(clienteFormaPagto){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.clienteFormaPagtoModel.updateClienteFormaPagtoPending();
      return clientRestAPI.put(`${url}/${clienteFormaPagto.id}`, clienteFormaPagto)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.updateClienteFormaPagtoFulfiled(res.data);
        })
        .catch(err =>{
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.updateClienteFormaPagtoRejected(err.response.data);
        });
    },

    //faz a chamada com o client para salar os dados de um clienteFormaPagto
    saveClienteFormaPagto(clienteFormaPagto){
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.clienteFormaPagtoModel.saveClienteFormaPagtoPending();
      return clientRestAPI.post(url, clienteFormaPagto)
        .then(res =>{
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.saveClienteFormaPagtoFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.saveClienteFormaPagtoRejected(err.response.data);
        });
    },

    //faz a chamada com o client para apagar um clienteFormaPagto
    deleteClienteFormaPagto(id){
      return clientRestAPI.delete(`${url}/${id}`)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteFormaPagtoModel.deleteClienteFormaPagtoFulfiled(res.data);
        })
    }
  })
};

export default clienteFormaPagtoModel;
