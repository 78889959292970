import {existeCliente, getMenu, isAdministrador, isUsuarioPai} from "./routes/auth";

const items = [
  {
    name: "Home",
    url: "/pages/home",
    icon: "fa fa-home",
    badge: {
      variant: "info",
    },
  },
];

getMenu().forEach((item) => {
  items.push({
    name: item.nome,
    url: item.url,
    icon: `fa ${item.icone}`,
  });
});

if (isAdministrador()) {
  items.push({
    name: "Gerenciar Menu",
    url: "/pages/menu",
    icon: "fa fa-bars",
  });
}


// Menus do Default Header (Menu suspenso da direita
// Adicionado aqui somente para liberar no default layout (isMenu), validando somente a url
// Quando colocar no banco remover daqui
const itemsDefaultHeader = [{url: "/pages/minhaEmpresa"}, {url: "/pages/cliente"}];
if(isAdministrador()) {
  itemsDefaultHeader.push({url: "/pages/clienteAtuacao"})
  itemsDefaultHeader.push({url: "/pages/novosUsuarios"})
}
if(existeCliente()) {
  itemsDefaultHeader.push({url: "/pages/usuario"})
  if(isUsuarioPai() && isAdministrador()) {
    itemsDefaultHeader.push({url: "/pages/minhaAssinatura"})
    itemsDefaultHeader.push({url: "/pages/minhaConsultoria"})
  }
}

export default {
  items,
  itemsDefaultHeader,
};
