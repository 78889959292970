const amplifyConfig = {
    "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
};

export default amplifyConfig;



 