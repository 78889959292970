//importando o client pra ser utilizado
import {clientRestAPI, isBackendProd} from "../clientRestAPI/clientRestAPI";
import {clientRestAPICep} from "../clientRestAPI/clientRestAPICep";
import {getUsuarioAutenticado, registraUsuarioLimpaContribuinte, setClienteSelecionadoListContribuinteHome} from "../routes/auth";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConsoleLog from "../pages/commons/ConsoleLog";
//definindo a rota a ser acrescentada na rota base definida no client
const url = "/cliente";
const urlClienteUsuario = "/usuario";

const urlCep = "https://viacep.com.br/ws/";
const clienteModel = {
  //definindo o estado inicial da aplicação
  state: {
    listaCliente: [],
    listaContribuinteHome: [],
    cliente: { id:"", nome: "",bairro:"" , celular: "", cep: "",
    cnpj:""
    ,complemente:""
    ,cpf:""
    ,dataAlteracao:""
    ,dataCadastro:""
    ,dddCelular:""
    ,dddTelefone:""
    ,email:""
    ,tipo:""
    ,loogradouro: ""
    ,municipioLabelValue:{}
    ,munipioLabelValue:{ value: {
                                  codMunicipio : ""
                                  ,dataFim: null
                                  ,dataInicio:"01012009"
                                  , nomeMunicipio:""
                                }
                                  ,label: " "
                      }
    ,numeroLogradouro: ""
    ,razaoSocial: ""
    ,telefone:""
    ,ufLabelValue: {value:{codigoIbge:"",nome:null,sigla:""}
                    ,label:""}
    ,usuario_alteracao:""
              },
    clienteAutenticado: { nome: "" },
    loading: false,
    errors: {},
    redirect: false,
    listaCep: [],
    ufLabelValue :{ufLabelValue : ""},
    cep: {
      cep: "",
      logradouro: "",
      complemento: "",
      bairro: "",
      localidade: "",
      uf: "",
      unidade: "",
      ibge: "",
      gia: ""
    },
    bloqueado: "N"
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        redirect: true
      };
    },

    /* Quando seleciona atuar como o redirect ficava como true */
    setRedirectFalse: state => {
      return {
        ...state,
        redirect: false
      };
    },

    //atualiza a lista de clientes  da aplicação caso os clientes tenham sido obtidos da API com sucesso
    fetchListaClienteFulfiled: (state, payload) => {
      return {
        ...state,
        listaCliente: payload.data || payload,
        redirect: false,
        loading: false
      };
    },

    //Deixa a aplicação pronta para receber um novo cliente no formulário
    newCliente: state => {
      return {
        ...state,
        cliente: { nome: "" }
      };
    },

    //atualiza a lista de clientes  da aplicação caso o cliente tenha sido salvo com sucesso
    saveClienteFulfilled: (state, payload) => {
      const usuario = getUsuarioAutenticado();
      if (!usuario.cliente) {
        usuario.cliente = payload;
        usuario.clienteSelecionado = payload;
        registraUsuarioLimpaContribuinte(usuario);
      }
      toast.success("Cliente criado com sucesso!");
      return {
        ...state,
        listaCliente: [state.cliente.listaCliente ? [...state.cliente.listaCliente] : [] , payload],
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveClientePending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar cliente apresente algum problema
    saveClienteRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //   const { nome, email } = payload.errors;
      const errors = { global: payload };
      toast.error(payload);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um cliente tenha dado certo
    fetchClienteFulfiled: (state, payload) => {
      return {
        ...state,
        cliente: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },


    selecionarClienteAtuacaoPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação no caso de a busca de um cliente tenha dado certo
    selecionarClienteAtuacaoFulfiled: (state, payload) => {
      setClienteSelecionadoListContribuinteHome (payload);
      return {
        ...state,
        cliente: payload,
        errors: {},
        loading: false,
        redirect: true
      };
    },
    //altera o estado da aplicação no caso de a busca de um cliente tenha dado certo
    fetchClienteAutenticadoFulfiled: (state, payload) => {
      registraUsuarioLimpaContribuinte(payload);
      return {
        ...state,
        clienteAutenticado: payload,
        listaContribuinteHome: payload.listaContribuinte,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um cliente tenha dado certo
    fetchClienteAutenticadoNaoEncontrado: (state, bloqueado) => {

      return {
        ...state,
        bloqueado,
        clienteAutenticado: { nome: "" },
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateClienteFulfiled: (state, payload) => {
      const cliente = payload;
      toast.success("Cliente atualizado com sucesso!");
      return {
        ...state,
        listaCliente: state.listaCliente.map(item =>
          item.idCliente === cliente.idCliente ? cliente : item
        ),
        errors: {},
        loading: false,
        redirect: true
      };
    },



    fetchClienteRejected: (state, payload) => {
      //const { nome, email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao buscar cliente! " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },
    //mostra os erros encontrados no caso de a operação de atualizar cliente apresente algum problema
    updateClienteRejected: (state, payload) => {
      //const { nome, email } = payload.errors;
      const errors = { global: payload };
      toast.error(errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };


    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateClientePending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchClientePending: state => {
      return {
        ...state,
        loading: true,
        cliente: { nome: "" }
      };
    },

        //altera o estado da aplicação para informar que tem uma operação sendo executada.
        fetchListaClientePending: state => {
          return {
            ...state,
            loading: true,
            listaCliente: [],
          };
        },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchClienteAutenticadoPending: state => {
      return {
        ...state,
        loading: true,
        clienteAutenticado: { nome: "" }
      };
    },

    //atualiza a lista de cliente da aplicação caso o cliente tenha sido deletado da API com sucesso
    deleteClienteFulfiled: (state, payload) => {
      const id = payload.id;
      return {
        ...state,
        listaCliente: state.listaCliente.filter(item => item.id !== id),
        loading: false,
      };
    },

    fetchCepError: ( state) => {
      //altera o estado da aplicação no caso de a busca de um cliente tenha dado certo


      toast.error("Cep inválido" );

      return {
      ...state,
      errors: {},
      loading: false,
      redirect: false
      };

      },

    //atualiza a lista de ceps  da aplicação caso os ceps tenham sido obtidos da API com sucesso
    fetchListaCepFulfiled: (state, payload) => {
      return {
        ...state,
        listaCep: payload.data || payload,
        redirect: false,
      };
    },

    //altera o estado da aplicação no caso de a busca de um cep tenha dado certo
    fetchCepFulfiled: (setFieldValue, state, payload) => {
      var nome = document.getElementById('nome').value
      var select = document.getElementById('tipo');
      var celular = document.getElementById('celular').value;
      var dddCelular  = document.getElementById('dddCelular').value;
      var dddTelefone  = document.getElementById('dddTelefone').value;
      var telefone = document.getElementById('telefone').value;
      var email = document.getElementById('email').value;
      var razaoSocial = document.getElementById('razaoSocial').value;
      var numeroLogradouro = document.getElementById('numeroLogradouro').value;
      var complemento = document.getElementById('complemento').value;


      var valueTipo = select.options[select.selectedIndex].value;
     // var tipo = document.getElementById('tipo').value
      const clienteCep = payload.clienteModel;


      clienteCep.cliente.bairro = state.bairro
      if(celular  !== ""){
        clienteCep.cliente.celular = celular
        clienteCep.cliente.dddCelular=dddCelular
      }else{
        clienteCep.cliente.celular = ""
        clienteCep.cliente.dddCelular=""
      }

      if(telefone !== ""){
        clienteCep.cliente.dddTelefone = dddTelefone
        clienteCep.cliente.telefone =telefone
      }else {
        clienteCep.cliente.dddTelefone = ""
        clienteCep.cliente.telefone =""
      }

      clienteCep.cliente.cep = state.cep;

      if(valueTipo === 'J'){
      clienteCep.cliente.tipo = 'J'
        var cnpj = document.getElementById('cnpj').value;
      clienteCep.cliente.cnpj = cnpj
      clienteCep.cliente.cpf = ""
    }else{
      clienteCep.cliente.tipo = 'F'
      var cpf = document.getElementById('cpf').value;
      clienteCep.cliente.cpf =cpf
      clienteCep.cliente.cnpj = ""

    }
      clienteCep.cliente.complemento  = complemento
      clienteCep.cliente.dataAlteracao = ""


      clienteCep.cliente.email = email
      clienteCep.cliente.id = ""
      clienteCep.cliente.logradouro = state.logradouro
      clienteCep.cliente.municipioLabelValue = {}


      clienteCep.cliente.munipioLabelValue = {"value" :{
        "codMunicipio" : state.ibge
        ,"dataFim": null
        ,"dataInicio":"01012009"
        ,"nomeMunicipio":state.localidade
      },"label" : state.localidade }

      clienteCep.cliente.nome = nome



      clienteCep.cliente.numeroLogradouro =numeroLogradouro
      clienteCep.cliente.razaoSocial = razaoSocial


      clienteCep.cliente.ufLabelValue = {"value":{
                                        "codigoIbge": state.ibge.substring(0,2)
                                        ,"nome": null
                                        ,"sigla" : state.uf
        }

      ,"label":state.uf }


      clienteCep.cliente.usuarioAlteracao = ""


      clienteCep.cep = state.cep;
      clienteCep.munipioLabelValue = state.localidade;
      clienteCep.ufLabelValue = state.uf;
      clienteCep.logradouro = state.logradouro;
      clienteCep.bairro = state.bairro;
      clienteCep.tipo = valueTipo;
      if(nome !==""){
      clienteCep.nome = nome;
    }else{
      clienteCep.nome =" "
    }





      return {
        ...state,
        cliente: clienteCep,
        errors: {}
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchCepPending: state => {
      return {
        ...state,
        loading: true
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os clientes
    fetchListaCliente() {
      dispatch.clienteModel.fetchListaClientePending();

      //faz a chamada com o client para buscar todos os clientes
      return clientRestAPI.get(url).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.clienteModel.fetchListaClienteFulfiled(res.data);
      });
    },

    selecionarClienteAtuacao(cliente, model) {
      dispatch.clienteModel.selecionarClienteAtuacaoPending();

      return clientRestAPI.get(`${url}/listaContribuinteHome/${cliente.idCliente}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        const payload = {cliente : cliente,listaContribuinteHome :res.data };

        model.contribuinteModel.contribuinteHome = null;
        dispatch.clienteModel.selecionarClienteAtuacaoFulfiled(payload);
      });

    },

    //faz a chamada com o clientRestAPI para buscar um cliente, por meio do id
    fetchCliente(id) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.clienteModel.fetchClientePending();
      return clientRestAPI.get(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.clienteModel.fetchClienteFulfiled(res.data);
      }).catch(err => {
        //caso ocorra algum problema, o reducer abaixo vai ser acionado
        dispatch.clienteModel.fetchClienteRejected(err);
      });
    },

    //faz a chamada com o clientRestAPI para buscar um cliente, por meio do usuario logado
    fetchClienteLoading(username) {

      ConsoleLog("clienteModel => fetchClienteLoading => (username):", username);

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.clienteModel.fetchClienteAutenticadoPending();

      return clientRestAPI
        .get(`${urlClienteUsuario}/login/${username}`)
        .then(res => {

          ConsoleLog("clienteModel => fetchClienteLoading => sucesso chamar fetchClienteAutenticadoFulfiled(res.data)", res.data);

          if(res.data.bloqueado && res.data.bloqueado === "S") {
            dispatch.clienteModel.fetchClienteAutenticadoNaoEncontrado(res.data.bloqueado);
          }

          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteModel.fetchClienteAutenticadoFulfiled(res.data);
        })
        .catch(err => {

          ConsoleLog("clienteModel => fetchClienteLoading => erro chamar fetchClienteAutenticadoNaoEncontrado()", err);

          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteModel.fetchClienteAutenticadoNaoEncontrado("N");
        });
    },

    //faz a chamada com o clientRestAPI para buscar um cliente, por meio do usuario logado
    fetchClienteAutenticado(username) {

      ConsoleLog("clienteModel => fetchClienteAutenticado => (username):", username);

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.clienteModel.fetchClienteAutenticadoPending();

      return clientRestAPI
        .get(`${urlClienteUsuario}/login/${username}`)
        .then(res => {

          ConsoleLog("Resposta da Requisicao: ", res);

          ConsoleLog("clienteModel => fetchClienteAutenticado => sucesso chamar fetchClienteAutenticadoFulfiled()", res.data);

          if(res.data.bloqueado && res.data.bloqueado === "S") {
            dispatch.clienteModel.fetchClienteAutenticadoNaoEncontrado(res.data.bloqueado);
          } else {
            //caso tudo ocorra bem, o reducer abaixo vai ser acionado
            dispatch.clienteModel.fetchClienteAutenticadoFulfiled(res.data);
          }

        })
        .catch((err) => {

          ConsoleLog("clienteModel => fetchClienteAutenticado => Error ", err.response);

          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteModel.fetchClienteAutenticadoNaoEncontrado("N");
        });
    },

    //faz a chamada com o client para atualizar os dados de um cliente
    updateCliente(cliente) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada

      const usuario = getUsuarioAutenticado();
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      cliente.usuarioAlteracao = usuario.username;
      cliente.ambiente = isBackendProd();

      dispatch.clienteModel.updateClientePending();
      return clientRestAPI
        .put(`${url}/${cliente.idCliente}`, cliente)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.clienteModel.updateClienteFulfiled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteModel.updateClienteRejected(err.response.data);
        });
    },

    //faz a chamada com o client para salvar os dados de um cliente
    saveCliente(cliente) {
      const usuario = getUsuarioAutenticado();
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      cliente.usuarioAlteracao = usuario.username;
      cliente.ambiente = isBackendProd();

      dispatch.clienteModel.saveClientePending();
      return clientRestAPI
        .post(url, cliente)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado

          dispatch.clienteModel.saveClienteFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.clienteModel.saveClienteRejected(err.response.data);
        });
    },

    //faz a chamada com o client para apagar um cliente
    deleteCliente(id) {
      return clientRestAPI.delete(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.clienteModel.deleteClienteFulfiled(res.data);
      });
    },
    //aciona a ação de buscar os ceps
    fetchListaCep() {
      //faz a chamada com o client para buscar todos os ceps
      return clientRestAPICep.get(urlCep).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.clienteModel.fetchListaCepFulfiled(res.data);
      });
    },


  //faz a chamada com o clientRestAPI para buscar um cep, por meio do id
  fetchCep(id, setFieldValue) {

    //faz a chamada desse reducer pra informar que tem uma operação sendo executada
    dispatch.clienteModel.fetchCepPending();

    return clientRestAPICep.get(`${urlCep}/${id}/json`)
    .then(res => {
      //caso tudo ocorra bem, o reducer abaixo vai ser acionado
      if (!res.data.erro){
        dispatch.clienteModel.fetchCepFulfiled(res.data, setFieldValue);
      }else{
        dispatch.clienteModel.fetchCepError();
      }

    }).catch(error => {
      dispatch.clienteModel.fetchCepError();

    });;
  }
})


};

export default clienteModel;
