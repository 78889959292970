import axios from "axios";
import ConsoleLog from "../pages/commons/ConsoleLog";
import {logout} from "../routes/auth";


//criando url base para requisiÃ§Ãµes 
//export const urlServidor = 'http://localhost:7700';
export const urlServidor = 'https://backend.lastrosistemas.com.br';
//export const urlServidor = 'http://18.231.54.193:7700';
//export const urlServidor = urlLocal;

// Caso altere o hostname do backend de producao, aqui devera ser alterado
// caso isso não ocorra a aplicacao irá realizar os cadastros na Vindi somente
// como homologacao (cadastro producao conforme hostname especificado aqui).
export const isBackendProd = () => {
  let url = new URL(urlServidor);
  return url.hostname === "backend.lastrosistemas.com.br" ? "P" : "H";
};

//configurando o typo de requisicao e rota base
export const clientRestAPI = axios.create({
  baseURL: urlServidor,

  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
    "Access-Control-Allow-Headers": "X-PINGOTHER, Content-Type",
    "Access-Control-Max-Age": "86400",
  },
});

clientRestAPI.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("accesToken");

  if (token) {
    //ConsoleLog("TOKEN=", token);

    config.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
      Authorization: `Bearer ${token}`,
    };
  }

  //ConsoleLog("CONFIG HEATE CLIENTE RESTT API", config.headers);
  return config;
});


clientRestAPI.interceptors.response.use((response) => {
    if (response.config.parse) {
        //perform the manipulation here and change the response object
    }
    return response;
}, (error) => {
    ConsoleLog("==== clientRestAPI => axios.interceptors.response => error =>", error);
    if(error && error.response && error.response.status) {
      /* Falha no request, fazer logout e levar para tela de login */
      if(error.response.status === 401) {
        ConsoleLog("==== clientRestAPI => axios.interceptors.response => this.props =>", this);
        logout();
        return window.location.href = '/signin';
      }
    }

    return Promise.reject(error);
});
