import moment from "moment";
import {toast} from "react-toastify";
import ConsoleLog from "../pages/commons/ConsoleLog";
import {clientRestAPI} from "../clientRestAPI/clientRestAPI";
import {getContribuinteHomeAutenticado, getUsuarioAutenticado,} from "../routes/auth";

//definindo a rota a ser acrescentada na rota base definida no client
const url = "/escrituracao";

function tratarDataLancamento(dataLancamento) {
  ConsoleLog("tratarDataLancamento entrada", dataLancamento);

  if (dataLancamento) {
    let day = moment(dataLancamento).date();
    let month = moment(dataLancamento).month();
    const year = moment(dataLancamento).year();
    day = day.toString().padStart(2, '0');
    month = (month + 1).toString().padStart(2, '0');
    ConsoleLog("tratarDataLancamento day=", day, "month=", month, "year=", year);
    dataLancamento = `${year}-${month}-${day}T12:00:00.000+0000`;
  }
  ConsoleLog("tratarDataLancamento saida", dataLancamento);

  return dataLancamento;
}

function gerarArraydeAnosLocal() {
  const anoAtual = new Date().getFullYear();
  const anoFinal = anoAtual - 6; // 6 anos para tras
  const anos = []

  for (let ano = anoAtual; ano >= anoFinal; ano--) {
    anos.push({value: ano, label: ano});
  }

  return anos;
}

const escrituracaoModel = {
  //definindo o estado inicial da aplicação
  state: {
    // listaEscrituracao: [],
    resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
    escrituracao: {dataLancamento: ""},
    loading: false,
    errors: {},
    redirect: false,
    tabMesSelecionado: undefined,
    listaAnoReferencia: gerarArraydeAnosLocal()
  },

  //definindo todos os reducers
  reducers: {
    cancelForm: (state) => {
      return {
        ...state,
        redirect: true,
      };
    },

    fetchEscrituracaoPending: (state) => {

      state.resumoEscrituracao = {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []};

      return {
        ...state,
        loading: true,
        escrituracao: {dataLancamento: ""},
        //resumoEscrituracao: ,
        // checkState: { all: false },
        // checkAll: "all",
      };
    },

    fetchListaEscrituracaoFulfiled: (state, payload) => {
      const resumo = payload.data || payload;
      // const checkState = checkedEscrituracao(
      //   state.mapEscrituracoes,
      //   resumo.listaEscrituracao
      // );

      return {
        ...state,
        resumoEscrituracao: resumo,
        // checkState,
        // checkAll: "all",
        loading: false,
        redirect: false,
      };
    },

    deleteEscrituracaoPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    deleteEscrituracaoFulfiled: (state, payload) => {
      toast.success("Escrituração deletada com sucesso!");

      //   const listaEscrituracao =  state.resumoEscrituracao.listaEscrituracao.filter(
      //                               (item) => item.id !== payload.id
      //                             );
      // state.resumoEscrituracao.listaEscrituracao = listaEscrituracao;

      return {
        ...state,
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []}
      };
    },

    deleteEscrituracaoRejected: (state, payload) => {
      const errors = {global: payload};
      toast.error(errors.global);
      return {
        ...state,
        loading: false,
      };
    },

    fetchEscrituracaoFulfiled: (state, payload) => {

      ConsoleLog("fetchEscrituracaoFulfiled", payload)

      return {
        ...state,
        escrituracao: payload,
        errors: {},
        loading: false,
        redirect: false,
      };
    },

    updateEscrituracaoPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    updateEscrituracaoFulfiled: (state, payload) => {
      //  const escrituracao = payload;
      toast.success("Escrituração atualizada com sucesso!");
      return {
        ...state,
        //listaEscrituracao: [...state.listaEscrituracao, payload],
        resumoEscrituracao: {totalEntrada: 0, totalSaida: 0, totalSaldo: 0, listaEscrituracao: []},
        escrituracao: {dataLancamento: ""},
        loading: false,
        redirect: true,
      };
    },

    updateEscrituracaoRejected: (state, payload) => {
      // const { nome, email } = payload.errors;
      toast.error("Erro ao atualizar Escricuração! " + payload.message);
      const errors = {global: payload.message};
      return {
        ...state,
        errors: errors,
        loading: false,
      };
    },

    newEscrituracao: (state) => {
      return {
        ...state,
        listaEscrituracao: [],
        escrituracao: {
          dataLancamento: "",
          historico: "",
          numeroDocumento: "",
          tipoDocumento: "",
          tipoLancamento: "",
          inscricoesEstaduaisMunicipai: "",
          conta: {codigo: "xx", nome: "NewEscrituracao"},
          valor: "",
        },
      };
    },

    saveEscrituracaoPending: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    saveEscrituracaoFulfilled: (state, payload) => {
      toast.success("Escrituração criada com sucesso!");
      return {
        ...state,
        listaEscrituracao: [...state.listaEscrituracao, payload],
        escrituracao: {
          id: "13",
          dataLancamento: "",
        },
        errors: undefined,
        loading: false,
        redirect: false,
      };
    },

    saveEscrituracaoRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //  const { nome, email } = payload.errors;
      toast.error("Erro ao gravar Escrituração! " + payload.message);
      const errors = {global: payload.message};
      return {
        ...state,
        containsError: true,
        errors: errors,
        loading: false,
      };
    },

    buscarAnosReferenciaFulfilled: (state, payload) => {

      const listaAnoReferencia = payload.map(function (item) {
        return {value: item, label: item};
      });

      return {
        ...state,
        listaAnoReferencia
      };
    },

    buscarAnosReferenciaRejected: (state) => {
      return {
        ...state,
        listaAnoReferencia: gerarArraydeAnosLocal()
      };
    },

  },

  //definindo os effects
  effects: (dispatch) => ({
    //aciona a ação de buscar os escrituracaos
    fetchListaEscrituracao(filtroEscrituracao) {

      ConsoleLog("Chamou: fetchListaEscrituracao (Filtro) => ", filtroEscrituracao);

      //faz a chamada com o client para buscar todos os escrituracaos
      dispatch.escrituracaoModel.fetchEscrituracaoPending();

      return clientRestAPI
        .post(`${url}/escricutacaoMes`, filtroEscrituracao)
        .then((res) => {
          ConsoleLog("fetchListaEscrituracao", res);
          dispatch.escrituracaoModel.fetchListaEscrituracaoFulfiled(res.data);
        })
        .catch((error) => {
          ConsoleLog("Error: fetchListaEscrituracao =>", error);
        });
    },

    deleteEscrituracao(parametrosDelete) {
      dispatch.escrituracaoModel.deleteEscrituracaoPending();

      const id = parametrosDelete.idEscrituracao;
      const filtroEscrituracao = parametrosDelete.filtroEscrituracao;

      return clientRestAPI
        .delete(`${url}/${id}`)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.escrituracaoModel.deleteEscrituracaoFulfiled(res.data);
          dispatch.escrituracaoModel.fetchListaEscrituracao(filtroEscrituracao);
        })
        .catch((err) => {
          dispatch.escrituracaoModel.deleteEscrituracaoRejected(
            err.response.data
          );
        });
    },

    fetchEscrituracao(id) {

      ConsoleLog("chamou: fetchEscrituracao");

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.escrituracaoModel.fetchEscrituracaoPending();
      return clientRestAPI.get(`${url}/${id}`).then((res) => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.escrituracaoModel.fetchEscrituracaoFulfiled(res.data);
      });
    },

    updateEscrituracao(escrituracao) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      escrituracao.contribuinte = contribuinteSelecionado;
      const usuarioAutenticado = getUsuarioAutenticado();
      escrituracao.usuario = usuarioAutenticado;
      escrituracao.dataLancamento = tratarDataLancamento(escrituracao.dataLancamento);

      dispatch.escrituracaoModel.updateEscrituracaoPending();
      return clientRestAPI
        .put(`${url}/${escrituracao.id}`, escrituracao)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.escrituracaoModel.updateEscrituracaoFulfiled(res.data);
        })
        .catch((err) => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.escrituracaoModel.updateEscrituracaoRejected(
            err.response.data
          );
        });
    },

    saveEscrituracao(escrituracao) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      const contribuinteSelecionado = getContribuinteHomeAutenticado();
      escrituracao.contribuinte = contribuinteSelecionado;
      escrituracao.usuario = getUsuarioAutenticado();
      escrituracao.dataLancamento = tratarDataLancamento(escrituracao.dataLancamento);

      dispatch.escrituracaoModel.saveEscrituracaoPending();
      return clientRestAPI
        .post(url, escrituracao)
        .then((res) => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
          dispatch.escrituracaoModel.saveEscrituracaoFulfilled(res.data);
        })
        .catch((err) => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.escrituracaoModel.saveEscrituracaoRejected(
            err.response.data
          );
        });
    },

    buscarAnosReferencia() {
      const contribuinteSelecionado = getContribuinteHomeAutenticado();

      if (contribuinteSelecionado) {

        ConsoleLog("chamou: listarAnoReferencia - ID:", contribuinteSelecionado.id);


        return clientRestAPI.get(`${url}/ano-referencia/${contribuinteSelecionado.id}`)
          .then((res) => {
            dispatch.escrituracaoModel.buscarAnosReferenciaFulfilled(res.data);
          })
          .catch(() => {
            dispatch.escrituracaoModel.buscarAnosReferenciaRejected();
          });
      }
    },

  }),
};

export default escrituracaoModel;
