//importando o client pra ser utilizado
import { clientRestAPI, isBackendProd } from "../clientRestAPI/clientRestAPI";
import { clientRestAPICep }from "../clientRestAPI/clientRestAPICep";
import { getUsuarioAutenticado } from "../routes/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConsoleLog from "../pages/commons/ConsoleLog";
//definindo a rota a ser acrescentada na rota base definida no client
const url = "/cliente";

const urlCep = "https://viacep.com.br/ws";

function configurarMinhaEmpresaCep(respCep, minhaEmpresa) {
  const minhaEmpresaCep = minhaEmpresa;

  let nome = document.getElementById('nome').value
  let select = document.getElementById('tipo');
  let celular = document.getElementById('celular').value;
  let dddCelular = document.getElementById('dddCelular').value;
  let dddTelefone = document.getElementById('dddTelefone').value;
  let telefone = document.getElementById('telefone').value;
  let email = document.getElementById('email').value;
  let razaoSocial = document.getElementById('razaoSocial').value;
  let numeroLogradouro = document.getElementById('numeroLogradouro').value;
  let complemento = document.getElementById('complemento').value;

  let valueTipo = select.options[select.selectedIndex].value;

  minhaEmpresaCep.bairro = respCep.bairro
  if (celular !== "") {
    minhaEmpresaCep.celular = celular
    minhaEmpresaCep.dddCelular = dddCelular
  } else {
    minhaEmpresaCep.celular = ""
    minhaEmpresaCep.dddCelular = ""
  }

  if (telefone !== "") {
    minhaEmpresaCep.dddTelefone = dddTelefone
    minhaEmpresaCep.telefone = telefone
  } else {
    minhaEmpresaCep.dddTelefone = ""
    minhaEmpresaCep.telefone = ""
  }

  minhaEmpresaCep.cep = respCep.cep;
  ConsoleLog("configurarMinhaEmpresaCep => minhaEmpresaCep.idCliente: ", minhaEmpresaCep.idCliente);

  if (valueTipo === 'J') {
    minhaEmpresaCep.tipo = 'J'
    var cnpj = document.getElementById('cnpj').value;
    minhaEmpresaCep.cnpj = cnpj
    minhaEmpresaCep.cpf = ""
  } else {
    minhaEmpresaCep.tipo = 'F'
    var cpf = document.getElementById('cpf').value;
    minhaEmpresaCep.cpf = cpf
    minhaEmpresaCep.cnpj = ""

  }
  minhaEmpresaCep.complemento = complemento

  minhaEmpresaCep.dataAlteracao = ""


  minhaEmpresaCep.email = email
  minhaEmpresaCep.id = ""
  minhaEmpresaCep.logradouro = respCep.logradouro
  
  minhaEmpresaCep.municipio = {
    "codMunicipio": respCep.ibge
    , "dataFim": null
    , "dataInicio": "01012009"
    , "nomeMunicipio": respCep.localidade
  }
  minhaEmpresaCep.municipioLabelValue = {}
  minhaEmpresaCep.municipioLabelValue = {
    "value": { ...minhaEmpresaCep.municipio },
    "label": respCep.localidade
  }


  minhaEmpresaCep.uf = {
    "codigoIbge": respCep.ibge.substring(0, 2)
    , "nome": null
    , "sigla": respCep.uf
  }

  minhaEmpresaCep.ufLabelValue = {
    "value": {...minhaEmpresaCep.uf }, 
    "label": respCep.uf
  }

  minhaEmpresaCep.nome = nome
  minhaEmpresaCep.numeroLogradouro = numeroLogradouro
  minhaEmpresaCep.razaoSocial = razaoSocial
  minhaEmpresaCep.usuarioAlteracao = ""
  minhaEmpresaCep.cep = respCep.cep;
  //minhaEmpresaCep.ufLabelValue = respCep.uf;
  minhaEmpresaCep.logradouro = respCep.logradouro;
  minhaEmpresaCep.bairro = respCep.bairro;
  minhaEmpresaCep.tipo = valueTipo;
  minhaEmpresaCep.nome = (nome !== "") ? nome : " ";
  
  return minhaEmpresaCep;
}

const minhaEmpresaModel = {
  //definindo o estado inicial da aplicação
  state: {
    minhaEmpresa: {
      nome: ""
      , bairro: ""
      , celular: ""
      , cep: ""
      , cnpj: ""
      , contas: ""
      , contribuitens: ""
      , dadosPagamentosClientes: []
      , dataAlteracao: ""
      , complemento: ""
      , cpf: ""
      , dataCadastro: ""
      , idCliente: ""
      , dddCelular: ""
      , dddTelefone: ""
      , email: ""
      , tipo: ""
      , loogradouro: ""
      , municipio: {
        codMunicipio: ""
        , dataFim: null
        , dataInicio: "01012009"
        , nomeMunicipio: ""
      }
      , municipioLabelValue: {
        value: {
          codMunicipio: ""
          , dataFim: null
          , dataInicio: "01012009"
          , nomeMunicipio: ""
        }
        , label: ""
      }
      , planosClientes: []
      , numeroLogradouro: ""
      , razaoSocial: ""
      , telefone: ""
      , uf: { codigoIbge: "", nome: null, sigla: "" }
      , ufLabelValue: { 
        value: { codigoIbge: "", nome: null, sigla: "" }
        , label: ""
      }
      , usuarioAlteracao: ""
      , usuarios: ""
    },
    loading: false,
    errors: {},
    redirect: false,
    listaCep: [],
    cep: {
      cep: "",
      logradouro: "",
      complemento: "",
      bairro: "",
      localidade: "",
      uf: "",
      unidade: "",
      ibge: "",
      gia: ""
    }
  },

  //definindo todos os reducers
  reducers: {
    //cancela o formulário e limpa os dados do formulário
    cancelForm: state => {
      return {
        ...state,
        redirect: false
      };
    },

    //atualiza a lista de minhaEmpresas  da aplicação caso o minhaEmpresa tenha sido salvo com sucesso
    saveMinhaEmpresaFulfilled: (state, payload) => {
      toast.success("Minha empresa criada com sucesso!");
      return {
        ...state,
        errors: {},
        loading: false,
        redirect: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    saveMinhaEmpresaPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //mostra os erros encontrados no caso de a operação de salvar minhaEmpresa apresente algum problema
    saveMinhaEmpresaRejected: (state, payload) => {
      // converte os errors do feathers para um formato a ser mostrado no Front
      //   const { nome, email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao incluir o MinhaEmpresa! " + errors);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação no caso de a busca de um minhaEmpresa tenha dado certo
    fetchMinhaEmpresaFulfiled: (state, payload) => {
      return {
        ...state,
        minhaEmpresa: payload,
        errors: {},
        loading: false,
        redirect: false
      };
    },



    //altera o estado da aplicação no caso de a alteração de um clçiente tenha dado certo
    updateMinhaEmpresaFulfiled: (state, payload) => {
      toast.success("Minha empresa atualizada com sucesso!");
      return {
        ...state,
        errors: {},
        loading: false,
        redirect: false
      };
    },



    fetchMinhaEmpresaRejected: (state, payload) => {
      //const { nome, email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao buscar minhaEmpresa! " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },
    //mostra os erros encontrados no caso de a operação de atualizar minhaEmpresa apresente algum problema
    updateMinhaEmpresaRejected: (state, payload) => {
      //const { nome, email } = payload.errors;
      const errors = { global: payload.message };
      toast.error("Erro ao atualizar minhaEmpresa! " + errors.global);
      return {
        ...state,
        errors: errors,
        loading: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    updateMinhaEmpresaPending: state => {
      return {
        ...state,
        loading: true
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchMinhaEmpresaPending: state => {
      return {
        ...state,
        loading: true,
        minhaEmpresa: { nome: "" }
      };
    },

    //atualiza a lista de ceps  da aplicação caso os ceps tenham sido obtidos da API com sucesso
    fetchListaCepFulfiled: (state, payload) => {
      return {
        ...state,
        listaCep: payload.data || payload,
        redirect: false
      };
    },
    fetchCepError: ( state) => {
                //altera o estado da aplicação no caso de a busca de um cliente tenha dado certo
      toast.error("Cep inválido" );

      return {
        ...state,
        errors: {},
        loading: false,
        redirect: false
      };

    },

    //altera o estado da aplicação no caso de a busca de um cep tenha dado certo
    fetchCepFulfiled: (_, payload, state) => {

      ConsoleLog("===================================");
      ConsoleLog("fetchCepFulfiled => state: ", state);
      ConsoleLog("fetchCepFulfiled => payload: ", payload);
      ConsoleLog("===================================");
      
      const minhaEmpresaCep = configurarMinhaEmpresaCep(payload, state.minhaEmpresaModel.minhaEmpresa);
      ConsoleLog("configurarMinhaEmpresaCep => minhaEmpresaCep: ", minhaEmpresaCep);

      state.minhaEmpresaModel.minhaEmpresa = minhaEmpresaCep;

      return {
        ...state,
        minhaEmpresa: minhaEmpresaCep,
        errors: {},
        loading: false,
        redirect: false
      };
    },

    //altera o estado da aplicação para informar que tem uma operação sendo executada.
    fetchCepPending: state => {
      return {
        ...state,
        loading: true
      };
    }
  },

  //definindo os effects
  effects: dispatch => ({
    //aciona a ação de buscar os minhaEmpresas
    fetchListaMinhaEmpresa() {
      //faz a chamada com o client para buscar todos os minhaEmpresas
      return clientRestAPI.get(url).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.minhaEmpresaModel.fetchListaMinhaEmpresaFulfiled(res.data);
      });
    },


    //faz a chamada com o clientRestAPI para buscar um minhaEmpresa, por meio do id
    fetchMinhaEmpresa(id, state) {
      ConsoleLog("fetchMinhaEmpresa => id: ", id);
      ConsoleLog("fetchMinhaEmpresa => state: ", state);

      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      dispatch.minhaEmpresaModel.fetchMinhaEmpresaPending();
      return clientRestAPI.get(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        ConsoleLog("fetchMinhaEmpresa => res.data: ", res.data);

        dispatch.minhaEmpresaModel.fetchMinhaEmpresaFulfiled(res.data);
        //dispatch.minhaEmpresaModel.fetchCepBuscaInicio(res.data);
      }).catch(err => {
        //caso ocorra algum problema, o reducer abaixo vai ser acionado
        dispatch.minhaEmpresaModel.fetchMinhaEmpresaRejected(err);
      });
    },


    //faz a chamada com o client para atualizar os dados de um minhaEmpresa
    updateMinhaEmpresa(minhaEmpresa) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada

      const usuario = getUsuarioAutenticado();
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      minhaEmpresa.usuarioAlteracao = usuario.username;
      minhaEmpresa.ambiente = isBackendProd();

      dispatch.minhaEmpresaModel.updateMinhaEmpresaPending();
      return clientRestAPI
        .put(`${url}/${minhaEmpresa.idMinhaEmpresa}`, minhaEmpresa)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado
         
          dispatch.minhaEmpresaModel.updateMinhaEmpresaFulfiled(res.data);
        })
        .catch(err => {
        
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.minhaEmpresaModel.updateMinhaEmpresaRejected(err.response.data);
        });
    },



    //faz a chamada com o client para apagar um minhaEmpresa
    deleteMinhaEmpresa(id) {
      return clientRestAPI.delete(`${url}/${id}`).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.minhaEmpresaModel.deleteMinhaEmpresaFulfiled(res.data);
      });
    },

    //faz a chamada com o client para salar os dados de um minhaEmpresa
    saveMinhaEmpresa(minhaEmpresa) {
     
      const usuario = getUsuarioAutenticado();
     
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
      minhaEmpresa.usuarioAlteracao = usuario.username;
      minhaEmpresa.ambiente = isBackendProd();
      dispatch.minhaEmpresaModel.saveMinhaEmpresaPending();
      return clientRestAPI
        .post(url, minhaEmpresa)
        .then(res => {
          //caso tudo ocorra bem, o reducer abaixo vai ser acionado

          dispatch.minhaEmpresaModel.saveMinhaEmpresaFulfilled(res.data);
        })
        .catch(err => {
          //caso ocorra algum problema, o reducer abaixo vai ser acionado
          dispatch.minhaEmpresaModel.saveMinhaEmpresaRejected(err.response.data);
        });
    },
    //aciona a ação de buscar os ceps
    fetchListaCep() {
      //faz a chamada com o client para buscar todos os ceps
      return clientRestAPICep.get(urlCep).then(res => {
        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        dispatch.minhaEmpresaModel.fetchListaCepFulfiled(res.data);
      });
    },

    // fetchCepBuscaInicio(payload, state) {

    //   ConsoleLog("fetchCepBuscaInicio => payload: ", payload);
    //   ConsoleLog("fetchCepBuscaInicio => state: ", state);

    //   //faz a chamada desse reducer pra informar que tem uma operação sendo executada
    // //  dispatch.minhaEmpresaModel.fetchCepPending();

    //   return clientRestAPICep.get(`${urlCep}/${payload.cep}/json`).then(res => {
    //     //caso tudo ocorra bem, o reducer abaixo vai ser acionado
    //     dispatch.minhaEmpresaModel.fetchCepBuscaInicioFulfiled(res.data, state);
    //   });
    // },

    //faz a chamada com o clientRestAPI para buscar um cep, por meio do id
    fetchCep(id, state) {
      //faz a chamada desse reducer pra informar que tem uma operação sendo executada
     // dispatch.minhaEmpresaModel.fetchCepPending();

     dispatch.minhaEmpresaModel.fetchCepPending();


      ConsoleLog("fetchCep => id: ", id);
      //ConsoleLog("fetchCep => teste1: ", teste1);
      // ConsoleLog("fetchCep => state: ", state);
      // ConsoleLog("fetchCep => values: ", values);
      

      return clientRestAPICep.get(`${urlCep}/${id}/json`).then(res => {

        ConsoleLog("fetchCep => clientRestAPICep => res: ", res);
        ConsoleLog("fetchCep => clientRestAPICep => res.data: ", res.data);

        // esperar(5000);

        //caso tudo ocorra bem, o reducer abaixo vai ser acionado
        if (!res.data.erro){
          dispatch.minhaEmpresaModel.fetchCepFulfiled(res.data, state);
        }else{
          dispatch.minhaEmpresaModel.fetchCepError();  
        }
        
      }).catch(error => {
        dispatch.minhaEmpresaModel.fetchCepError();
      });;
    }
  })


};




export default minhaEmpresaModel;
